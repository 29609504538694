import React from 'react'
import TextField from 'mz-ui-kit/form/TextField/field'
import PassengersNumberField from 'mz-ui-kit/form/PassengersNumberField/field'
import Row from 'mz-ui-kit/core/Row'
import PlaceField from 'mz-ui-kit/form/PlaceField/field'
import TimeField from 'mz-ui-kit/form/TimeField/field'
import DateField from 'mz-ui-kit/form/DateField/field'
import AirportField from 'mz-ui-kit/form/AirportField/field'
import CheckboxField from 'mz-ui-kit/form/CheckboxField/field'
import {
  FormTitle,
  Content,
  FormSubTitle,
  FlightHeader,
  Label,
  FlightDirectionRadios,
} from './elements'
import EmailField from 'mz-ui-kit/form/EmailField/field'
import {
  normalizeNames,
  normalizeReservation,
  normalizeChars,
  normalizeFlightNumber,
} from 'app/utils/normalize'
import PhoneField from 'mz-ui-kit/form/PhoneField/field'
import RadioField from 'mz-ui-kit/form/RadioField/field'

export default function SingleCreate({ nest, value }) {
  return (
    <Content>
      <FormTitle>New Trip</FormTitle>
      <PlaceField
        {...nest('start_location')}
        label="From"
        disableFloatingLabel
        placeholder="Start Location"
        required
      />

      <PlaceField
        {...nest('end_location')}
        label="To"
        disableFloatingLabel
        placeholder="End Location"
        required
      />

      <Row>
        <DateField
          {...nest('departure_date')}
          label="Pickup date"
          placeholder="Pickup date"
          disableFloatingLabel
          required
        />

        <TimeField
          {...nest('departure_time')}
          label="Pickup time"
          disableFloatingLabel
          placeholder="Pickup time"
          required
        />
      </Row>

      <Row>
        <TextField
          {...nest('first_name')}
          required
          label="First Name"
          placeholder="First Name"
          normalize={normalizeNames}
        />
        <TextField
          {...nest('last_name')}
          required
          label="Last Name"
          placeholder="Last Name"
          normalize={normalizeNames}
        />
      </Row>
      <PhoneField {...nest('phone_number')} required label="Phone Number" />

      <EmailField {...nest('email')} label="Email" required />

      <PassengersNumberField
        {...nest('num_passengers')}
        label="Number of Passengers"
      />

      <TextField
        {...nest('external_reservation_id')}
        label="Reservation Id"
        placeholder="Reservation Id"
        required
        normalize={normalizeReservation}
      />
      <br />
      <CheckboxField
        {...nest('has_flight_info')}
        label="Add Flight Information"
      />

      {value.has_flight_info && (
        <div>
          <FlightHeader>
            <FormSubTitle>Flight</FormSubTitle>
          </FlightHeader>
          <div>
            <Label>Flight Direction</Label>
            <FlightDirectionRadios>
              <RadioField
                {...nest('flight_direction')}
                activeValue="arrival"
                label="Arrival"
                color="text"
              />

              <RadioField
                {...nest('flight_direction')}
                activeValue="departure"
                label="Departure"
                color="text"
              />
            </FlightDirectionRadios>
          </div>
          <div>
            <Row>
              <TextField
                {...nest('flight_number')}
                label="Flight Number"
                placeholder="1234"
                normalize={normalizeFlightNumber}
                required
              />
              <TextField
                {...nest('airline_code')}
                label="Airline Code"
                placeholder="AA"
                normalize={normalizeChars(3)}
                required
              />
              <AirportField
                {...nest('iata_code')}
                label="Airport IATA Code"
                placeholder="CDG"
              />
            </Row>
            <Row>
              <DateField
                {...nest('flight_date')}
                label="Flight Date"
                placeholder="Flight Date"
                disableFloatingLabel
                required
              />

              <TimeField
                {...nest('flight_time')}
                label="Flight Time"
                disableFloatingLabel
                placeholder="Flight Time"
                required
              />
            </Row>
          </div>
        </div>
      )}
    </Content>
  )
}
