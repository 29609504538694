import React, { useEffect } from 'react'
import DashboardRoute from './DashboardRoute'
import Trips from 'app/components/trips/TripsPage'
import Vehicles from 'app/components/vehicles/VehiclesPage'
import DriversPage from 'app/components/drivers/DriversPage'
import { useHistory } from 'react-router-dom'

const ProvidersV2 = ({ location }) => {
  const history = useHistory()

  useEffect(() => {
    const listenMessage = ({ data }) => {
      if (data.type === 'provider/navigateToTripDetail') {
        history.push(`/trips/${data.payload}`)
      }
    }
    window.addEventListener('message', listenMessage)
    return () => {
      window.removeEventListener('message', listenMessage)
    }
  }, [history])

  const token = localStorage.getItem('mozio-auth-token')
  const baseUrl =
    process.env.CONFIG_ENV === 'production'
      ? 'https://providers-v2.mozio.com'
      : 'https://providers-v2-staging.mozio.com'
  const url = `${baseUrl}${location.pathname}?token=${token}`
  return (
    <iframe src={url} width="100%" height="100%" style={{ border: 'none' }} />
  )
}

export const routes = [
  {
    path: '/trips/:hash?',
    pathTo: '/trips',
    name: 'Trips',
    icon: 'swap_calls',
    component: Trips,
  },
  {
    path: '/drivers',
    pathTo: '/drivers',
    name: 'Drivers',
    icon: 'group',
    component: DriversPage,
  },
  {
    path: '/vehicles',
    pathTo: '/vehicles',
    name: 'Vehicles',
    icon: 'directions_car',
    component: Vehicles,
  },
  {
    path: '/analytics',
    pathTo: '/analytics',
    name: 'Analytics',
    icon: 'pie_chart',
    component: ProvidersV2,
  },
]

const DashboardRoutes = () => {
  return routes.map((route, index) => (
    <DashboardRoute key={index} exact {...route} />
  ))
}

export default DashboardRoutes
