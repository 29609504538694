import { styled } from 'mz-ui-kit'

export const Form = styled('form', {
  display: 'flex',
  flexFlow: 'column nowrap',
  marginBottom: '20px',
})

export const Buttons = styled('div', {
  marginTop: '10px',
  display: 'flex',
  flexFlow: 'row nowrap',
})

export const ButtonWrapper = styled('div', {
  ':not(:last-child)': {
    marginRight: '10px',
  },
})
