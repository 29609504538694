import { createReducer } from 'app/utils/redux'
import {
  ADD_DRIVERS,
  ADD_DRIVER,
  REMOVE_DRIVER,
  SET_DRIVERS_ERROR,
  SET_UPDATED_DRIVER,
  ADD_BULK_DRIVERS,
  ADD_TEMP_DRIVERS,
  REMOVE_TEMP_DRIVERS,
  SET_UPLOAD_ERROR,
} from 'app/constants/actions'

const initialState = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  error: null,
  bulkCreate: {
    error: [],
    drivers: [],
  },
}

const actionHandlers = {
  [ADD_DRIVERS]: (state, action) => {
    return { ...state, ...action.payload }
  },
  [ADD_DRIVER]: (state, action) => {
    return {
      ...state,
      results: [action.payload, ...state.results],
    }
  },
  [ADD_BULK_DRIVERS]: (state, action) => {
    return {
      ...state,
      results: state.results.concat(action.payload),
    }
  },
  [REMOVE_DRIVER]: (state, action) => {
    const id = action.payload
    return {
      ...state,
      results: state.results.filter((result) => result.id !== id),
    }
  },
  [SET_UPDATED_DRIVER]: (state, action) => {
    const driver = action.payload
    return {
      ...state,
      results: state.results.map((result) =>
        result.id !== driver.id ? result : driver
      ),
    }
  },
  [SET_DRIVERS_ERROR]: (state, action) => {
    return { ...state, error: action.payload }
  },
  [ADD_TEMP_DRIVERS]: (state, action) => {
    return {
      ...state,
      bulkCreate: {
        ...state.bulkCreate,
        drivers: action.payload.entries,
      },
    }
  },
  [REMOVE_TEMP_DRIVERS]: (state) => {
    return {
      ...state,
      bulkCreate: {
        ...state.bulkCreate,
        drivers: [],
      },
    }
  },
  [SET_UPLOAD_ERROR]: (state, action) => {
    return {
      ...state,
      bulkCreate: {
        ...state.bulkCreate,
        error: action.payload,
      },
    }
  },
}

export default createReducer(initialState, actionHandlers)
