export const colors = [
  'Aluminum',
  'Beige',
  'Black',
  'Blue',
  'Brown',
  'Bronze',
  'Claret',
  'Copper',
  'Cream',
  'Gold',
  'Gray',
  'Green',
  'Maroon',
  'Metallic',
  'Navy',
  'Orange',
  'Pink',
  'Purple',
  'Red',
  'Rose',
  'Rust',
  'Silver',
  'Tan',
  'Turquoise',
  'White',
  'Yellow',
]

export const makesAndModels = {
  Acura: [
    'CL',
    'ILX',
    'ILX Hybrid',
    'Integra',
    'Legend',
    'MDX',
    'MDX Sport Hybrid',
    'NSX',
    'RDX',
    'RL',
    'RLX',
    'RLX Sport Hybrid',
    'RSX',
    'SLX',
    'TL',
    'TLX',
    'TSX',
    'Vigor',
    'ZDX',
  ],
  'Alfa Romeo': [
    '164',
    '4C',
    '4C Spider',
    '8c Competizione',
    '8c Spider',
    'Giulia',
    'Spider',
    'Stelvio',
  ],
  'Am General': ['Hummer'],
  'Aston Martin': [
    '100',
    '200',
    '80',
    '90',
    'A3',
    'A3 e-tron',
    'A4',
    'A4 allroad',
    'A5',
    'A6',
    'A7',
    'A8',
    'allroad',
    'Cabriolet',
    'Q3',
    'Q5',
    'Q5 hybrid',
    'Q7',
    'quattro',
    'R8',
    'RS 3',
    'RS 4',
    'RS 5',
    'RS 7',
    'RS6',
    'S3',
    'S4',
    'S5',
    'S6',
    'S7',
    'S8',
    'SQ5',
    'TT',
    'TT RS',
    'TTS',
  ],
  Audi: ['Avanti'],
  'Avanti Motors': [
    'Arnage',
    'Azure',
    'Bentayga',
    'Brooklands',
    'Continental',
    'Continental Flying Spur',
    'Continental GT',
    'Continental GTC',
    'Continental Supersports',
    'Flying Spur',
    'Mulsanne',
    'R-Type',
    'Turbo R',
    'Turbo RL',
    'Turbo RT',
    'Turbo S',
  ],
  Bentley: [
    '1-Series',
    '- 1 Series M',
    '- 128',
    '- 135',
    '2-Series',
    ' - 228',
    ' - 230',
    ' - M235',
    ' - M240',
    '3-Series',
    ' - 318',
    ' - 320',
    ' - 323',
    ' - 325',
    ' - 328',
    ' - 328 Gran Turismo',
    ' - 328d',
    ' - 330',
    ' - 330 Gran Turismo',
    ' - 330e',
    ' - 335',
    ' - 335 Gran Turismo',
    ' - 340',
    ' - 340 Gran Turismo',
    ' - ActiveHybrid 3',
    ' - M3',
    '4-Series',
    ' - 428',
    ' - 428 Gran Coupe',
    ' - 430',
    ' - 430 Gran Coupe',
    ' - 435',
    ' - 435 Gran Coupe',
    ' - 440',
    ' - 440 Gran Coupe',
    ' - M4',
    '5-Series',
    ' - 525',
    ' - 528',
    ' - 530',
    ' - 530e',
    ' - 535',
    ' - 535 Gran Turismo',
    ' - 535d',
    ' - 540',
    ' - 540d',
    ' - 545',
    ' - 550',
    ' - 550 Gran Turismo',
    ' - ActiveHybrid 5',
    ' - M5',
    '6-Series',
    ' - 640',
    ' - 640 Gran Coupe',
    ' - 640 Gran Turismo',
    ' - 645',
    ' - 650',
    ' - 650 Gran Coupe',
    ' - ALPINA B6 Gran Coupe',
    ' - M6',
    ' - M6 Gran Coupe',
    '7-Series',
    ' - 735',
    ' - 740',
    ' - 740e',
    ' - 745',
    ' - 750',
    ' - 760',
    ' - ActiveHybrid 7',
    ' - ActiveHybrid 740',
    ' - ActiveHybrid 750',
    ' - ALPINA B7',
    '840',
    '850',
    'i3',
    'i8',
    'M-Series',
    ' - 1 Series M',
    ' - M',
    ' - M2',
    ' - M235',
    ' - M240',
    ' - M3',
    ' - M4',
    ' - M5',
    ' - M550',
    ' - M6',
    ' - M6 Gran Coupe',
    ' - M760',
    ' - X5 M',
    ' - X6 M',
    ' - Z4 M',
    'X-Series',
    ' - ActiveHybrid X6',
    ' - X1',
    ' - X2',
    ' - X3',
    ' - X4',
    ' - X5',
    ' - X5 eDrive',
    ' - X5 M',
    ' - X6',
    ' - X6 M',
    'Z3',
    'Z4',
    'Z8',
  ],
  BMW: ['Veyron', 'Veyron 16.4'],
  Bugatti: [
    'Cascada',
    'Century',
    'Enclave',
    'Encore',
    'Envision',
    'LaCrosse',
    'LeSabre',
    'Lucerne',
    'Park Avenue',
    'Rainier',
    'Reatta',
    'Regal',
    'Regal Sportback',
    'Regal TourX',
    'Rendezvous',
    'Riviera',
    'Roadmaster',
    'Skylark',
    'Terraza',
    'Verano',
  ],
  Buick: [
    'Allante',
    'ATS',
    'ATS-V',
    'Brougham',
    'Catera',
    'CT6',
    'CT6 PLUG-IN',
    'CTS',
    'CTS-V',
    'DeVille',
    'DTS',
    'Eldorado',
    'ELR',
    'Escalade',
    'Escalade ESV',
    'Escalade EXT',
    'Escalade Hybrid',
    'Fleetwood',
    'Seville',
    'Sixty Special',
    'SRX',
    'STS',
    'XLR',
    'XT5',
    'XTS',
  ],
  Cadillac: [
    '1500',
    '2500',
    '3500',
    'Astro',
    'Avalanche',
    'Aveo',
    'Beretta',
    'Blazer',
    'Bolt EV',
    'Camaro',
    'Caprice',
    'Caprice Classic',
    'Captiva Sport',
    'Cavalier',
    'City Express',
    'Classic',
    'Cobalt',
    'Colorado',
    'Corsica',
    'Corvette',
    'Cruze',
    'Cruze Limited',
    'Equinox',
    'Express Vans',
    ' - Express 1500',
    ' - Express 2500',
    ' - Express 3500',
    ' - Van',
    'HHR',
    'Impala',
    'Impala Limited',
    'Lumina',
    'Lumina APV',
    'Malibu',
    'Malibu Classic',
    'Malibu Hybrid',
    'Malibu Limited',
    'Malibu Maxx',
    'Metro',
    'Monte Carlo',
    'Pickup',
    'Prizm',
    'S-10',
    'S-10 Blazer',
    'Silverado Trucks',
    ' - Silverado 1500',
    ' - Silverado 1500 Hybrid',
    ' - Silverado 2500',
    ' - Silverado 3500',
    'Sonic',
    'Spark',
    'Spark EV',
    'Sportvan',
    'SS',
    'SSR',
    'Suburban',
    'Tahoe',
    'Tahoe Hybrid',
    'Tracker',
    'TrailBlazer',
    'TrailBlazer EXT',
    'Traverse',
    'Trax',
    'Uplander',
    'Venture',
    'Volt',
  ],
  Chevrolet: [
    '200',
    '300',
    '300C',
    '300M',
    'Aspen',
    'Aspen Hybrid',
    'Cirrus',
    'Concorde',
    'Crossfire',
    'Grand Voyager',
    'Imperial',
    'LeBaron',
    'LHS',
    'New Yorker',
    'Pacifica',
    'Pacifica Hybrid',
    'Prowler',
    'PT Cruiser',
    'Sebring',
    'TC by Maserati',
    'Town &amp; Country',
    'Voyager',
  ],
  Chrysler: ['Lanos', 'Leganza', 'Nubira'],
  Daewoo: ['Charade', 'Rocky'],
  Daihatsu: [
    'Avenger',
    'Caliber',
    'Caravan',
    'Challenger',
    'Charger',
    'Colt',
    'D150',
    'D250',
    'D350',
    'Dakota',
    'Dart',
    'Daytona',
    'Durango',
    'Durango Hybrid',
    'Dynasty',
    'Grand Caravan',
    'Intrepid',
    'Journey',
    'Magnum',
    'Monaco',
    'Neon',
    'Nitro',
    'Pickup',
    'Ram 50',
    'Ram Trucks',
    ' - Pickup',
    ' - Ram 1500',
    ' - Ram 2500',
    ' - Ram 3500',
    'Ram Van',
    'Ram Wagon',
    'Ramcharger',
    'Shadow',
    'Spirit',
    'Sprinter',
    'SRT Viper',
    'Stealth',
    'Stratus',
    'Van',
    'Viper',
    'W150',
    'W250',
    'W350',
  ],
  Dodge: ['Premier', 'Summit', 'Talon', 'Vision'],
  Eagle: [
    '348',
    '360 Modena',
    '360 Spider',
    '456 GT',
    '456 M',
    '458 Italia',
    '458 Speciale',
    '458 Spider',
    '488 GTB',
    '488 Spider',
    '512 M',
    '512 TR',
    '550 Barchetta',
    '550 Maranello',
    '575 M',
    '599 GTB Fiorano',
    '599 GTO',
    '612 Scaglietti',
    'California',
    'Challenge Stradale',
    'Enzo',
    'F12berlinetta',
    'F355',
    'F40',
    'F430',
    'F50',
    'FF',
    'GTC4Lusso',
    'Mondial',
    'Mondial t',
    'Superamerica',
    'Testarossa',
  ],
  Ferrari: ['124 Spider', '500', '500C', '500e', '500L', '500X'],
  FIAT: ['Karma'],
  Fisker: [],
  Ford: [
    'Aerostar',
    'Aspire',
    'Bronco',
    'C-Max Energi',
    'C-Max Hybrid',
    'Club Wagon',
    'Contour',
    'Crown Victoria',
    'Econoline Vans',
    ' - E150',
    ' - E250',
    ' - E350',
    ' - E350 Super Duty',
    ' - Van',
    'EcoSport',
    'Edge',
    'Escape',
    'Escape Hybrid',
    'Escort',
    'Excursion',
    'Expedition (all)',
    ' - Expedition',
    ' - Expedition EL',
    ' - Expedition Max',
    'Explorer',
    'Explorer Sport',
    'Explorer Sport Trac',
    'F-Series Trucks',
    ' - F-150',
    ' - F-250',
    ' - F-350',
    ' - F-450',
    ' - Pickup',
    'Festiva',
    'Fiesta',
    'Five Hundred',
    'Flex',
    'Focus',
    'Focus Electric',
    'Focus RS',
    'Focus ST',
    'Freestar',
    'Freestyle',
    'Fusion',
    'Fusion Energi',
    'Fusion Hybrid',
    'GT',
    'LTD',
    'Mustang',
    'Pickup',
    'Probe',
    'Ranger',
    'Sedan Police Interceptor',
    'Shelby GT350',
    'Shelby GT500',
    'Taurus',
    'Taurus X',
    'Tempo',
    'Thunderbird',
    'Transit Connect',
    'Transit-150',
    'Transit-250',
    'Transit-350',
    'Utility Police Interceptor',
    'Windstar',
    'ZX2',
  ],
  Genesis: ['G80', 'G90'],
  Geo: ['Metro', 'Prizm', 'Storm', 'Tracker'],
  GMC: [
    '1500',
    '2500',
    '3500',
    'Acadia',
    'Acadia Limited',
    'Canyon',
    'Envoy',
    'Envoy XL',
    'Envoy XUV',
    'Jimmy',
    'Pickup',
    'Rally',
    'S-15 Jimmy',
    'Safari',
    'Savana Vans',
    ' - Savana 1500',
    ' - Savana 2500',
    ' - Savana 3500',
    ' - Van',
    ' - Vandura',
    'Sierra Trucks',
    ' - Pickup',
    ' - Sierra 1500',
    ' - Sierra 1500 Hybrid',
    ' - Sierra 2500',
    ' - Sierra 3500',
    'Sonoma',
    'Suburban',
    'Terrain',
    'Van',
    'Yukon',
    'Yukon Hybrid',
    'Yukon XL',
  ],
  Honda: [
    'Accord',
    'Accord Crosstour',
    'Accord Hybrid',
    'Accord Plug-In Hybrid',
    'Civic',
    'Civic Hybrid',
    'Civic Type R',
    'Clarity Fuel Cell',
    'Clarity Plug-In Hybrid',
    'CR-V',
    'CR-Z',
    'Crosstour',
    'CRX',
    'del Sol',
    'Element',
    'Fit',
    'Fit EV',
    'HR-V',
    'Insight',
    'Odyssey',
    'Passport',
    'Pilot',
    'Prelude',
    'Ridgeline',
    'S2000',
  ],
  Hummer: ['H1', 'H1 Alpha', 'H2', 'H3', 'H3T'],
  Hyundai: [
    'Accent',
    'Azera',
    'Elantra',
    'Elantra GT',
    'Elantra Touring',
    'Entourage',
    'Equus',
    'Excel',
    'Genesis',
    'Genesis Coupe',
    'Ioniq EV',
    'Ioniq Hybrid',
    'Ioniq Plug-In Hybrid',
    'Kona',
    'Santa Fe',
    'Santa Fe Sport',
    'Scoupe',
    'Sonata',
    'Sonata Hybrid',
    'Sonata Plug-In Hybrid',
    'Tiburon',
    'Tucson',
    'Veloster',
    'Veracruz',
    'XG300',
    'XG350',
  ],
  INFINITI: [
    'EX35',
    'EX37',
    'FX (all)',
    ' - FX35',
    ' - FX37',
    ' - FX45',
    ' - FX50',
    'G (all)',
    ' - G20',
    ' - G25',
    ' - G35',
    ' - G37',
    'I (all)',
    ' - I30',
    ' - I35',
    'IPL G',
    'J30',
    'JX35',
    'M (all)',
    ' - M35',
    ' - M35h',
    ' - M37',
    ' - M45',
    ' - M56',
    'M30',
    'Q40',
    'Q45',
    'Q50',
    'Q50 Hybrid',
    'Q60',
    'Q60 IPL',
    'Q70',
    'Q70h',
    'Q70L',
    'QX (all)',
    ' - QX30',
    ' - QX4',
    ' - QX50',
    ' - QX56',
    ' - QX60',
    ' - QX60 Hybrid',
    ' - QX70',
    ' - QX80',
  ],
  International: ['CXT', 'MXT', 'RXT'],
  Isuzu: [
    'Amigo',
    'Ascender',
    'Axiom',
    'Impulse',
    'Isuzu Trucks',
    ' - Hombre',
    ' - i-280',
    ' - i-290',
    ' - i-350',
    ' - i-370',
    ' - Pickup',
    'Oasis',
    'Rodeo',
    'Rodeo Sport',
    'Stylus',
    'Trooper',
    'VehiCROSS',
  ],
  Jaguar: [
    'E-PACE',
    'F-PACE',
    'F-TYPE',
    'S-Type',
    'Super V8',
    'Super V8 Portfolio',
    'Vanden Plas',
    'X-Type',
    'XE',
    'XF',
    'XJ',
    'XJ12',
    'XJ6',
    'XJ8',
    'XJR',
    'XJR-S',
    'XJS',
    'XK',
    'XK8',
    'XKR',
  ],
  Jeep: [
    'Cherokee',
    'Comanche',
    'Commander',
    'Compass',
    'Grand Cherokee',
    'Grand Wagoneer',
    'Liberty',
    'New Compass',
    'Patriot',
    'Renegade',
    'Wrangler',
    'Wrangler JK',
    'Wrangler JK Unlimited',
    'Wrangler Unlimited',
  ],
  Karma: ['Revero'],
  Kia: [
    'Amanti',
    'Borrego',
    'Cadenza',
    'Forte',
    'Forte Koup',
    'K900',
    'Niro',
    'Niro Plug-In Hybrid',
    'Optima',
    'Optima Hybrid',
    'Optima Plug-In Hybrid',
    'Rio',
    'Rio5',
    'Rondo',
    'Sedona',
    'Sephia',
    'Sorento',
    'Soul',
    'Soul EV',
    'Spectra',
    'Spectra5',
    'Sportage',
    'Stinger',
  ],
  Koenigsegg: [],
  Lamborghini: [
    'Aventador',
    'Aventador S',
    'Diablo,Gallardo',
    'Huracan',
    'Murcielago',
  ],
  'Land Rover': [
    'Defender',
    'Discovery',
    'Discovery Sport',
    'Freelander',
    'LR2',
    'LR3',
    'LR4',
    'Range Rover',
    'Range Rover Evoque',
    'Range Rover Sport',
    'Range Rover Velar',
  ],
  Lexus: [
    'CT 200h',
    'ES (all)',
    'GS (all)',
    'GX 460',
    'GX 470',
    'HS 250h',
    'IS (all)',
    'LC 500',
    'LC 500h',
    'LFA',
    'LS (all)',
    'LS 500h',
    'LX (all)',
    'NX 200t',
    'NX 300',
    'NX 300h',
    'RC 200t',
    'RC 300',
    'RC 350',
    'RC F',
    'RX (all)',
    'SC (all)',
  ],
  Lincoln: [
    'Aviator',
    'Blackwood',
    'Continental',
    'LS',
    'Mark LT',
    'Mark VII',
    'Mark VIII',
    'MKC',
    'MKS',
    'MKT',
    'MKX',
    'MKZ',
    'MKZ Hybrid',
    'Navigator',
    'Navigator L',
    'Town Car',
    'Zephyr',
  ],
  Lotus: [
    'Elan',
    'Elise',
    'Esprit',
    'Esprit V8',
    'Evora',
    'Evora 400',
    'Exige',
    'Exige S',
  ],
  Maserati: [
    'Coupe',
    'Ghibli',
    'GranSport',
    'GranSport Spyder',
    'GranTurismo',
    'Levante',
    'Quattroporte',
    'Spyder',
  ],
  Maybach: ['Landaulet', 'Type 57', 'Type 62'],
  Mazda: [
    '323',
    '626',
    '929',
    'B-Series Trucks',
    'CX-3',
    'CX-5',
    'CX-7',
    'CX-9',
    'Mazda2',
    'Mazda3',
    'Mazda5',
    'Mazda6',
    'MazdaSpeed Miata MX-5',
    'MazdaSpeed Protege',
    'MazdaSpeed3',
    'MazdaSpeed6',
    'Millenia',
    'MPV',
    'MX-3',
    'MX-5 Miata',
    'MX-5 Miata RF',
    'MX-6',
    'Navajo',
    'Protege',
    'Protege5',
    'RX-7',
    'RX-8',
    'Tribute',
    'Tribute Hybrid',
  ],
  McLaren: ['570GT', '570S', '650S', '675LT', '720S', 'MP4-12C'],
  Mercedes: [
    'enz',
    '"190',
    'AMG GT',
    'B-Class',
    'B-Class Electric Drive',
    'C (all)',
    'CL-Class',
    'CLA (all)',
    'CLK-Class',
    'CLS (all)',
    'E (all)',
    'G (all)',
    'GL (all)',
    'GLA (all)',
    'GLC (all)',
    'GLE (all)',
    'GLK-Class',
    'GLS (all)',
    'M-Class',
    'Maybach S',
    'Maybach S 550',
    'Maybach S 560',
    'Maybach S 600',
    'Maybach S 650',
    'Metris',
    'R-Class',
    'S (all)',
    'SL (all)',
    'SLC (all)',
    'SLK (all)',
    'SLR McLaren',
    'SLS AMG',
    'SLS AMG Black Series',
    'Sprinter',
    'Sprinter 2500',
    'Sprinter 3500',
    'Sprinter 3500XD',
    ' V Class',
    ' Viano',
    ' Vito',
    ' X Cla',
  ],
  Mercury: [
    'Capri',
    'Colony Park',
    'Cougar',
    'Grand Marquis',
    'Marauder',
    'Mariner',
    'Mariner Hybrid',
    'Milan',
    'Milan Hybrid',
    'Montego',
    'Monterey',
    'Mountaineer',
    'Mystique',
    'Sable',
    'Topaz',
    'Tracer',
    'Villager',
  ],
  MINI: [
    'Clubman',
    'Convertible',
    'Cooper',
    'Cooper Clubman',
    'Cooper Countryman',
    'Cooper S',
    'Cooper S Clubman',
    'Cooper S Countryman',
    'Countryman',
    'Coupe',
    'E Countryman',
    'Hardtop',
    'Paceman',
    'Roadster',
  ],
  Mitsubishi: [
    '3000GT',
    'Diamante',
    'Eclipse',
    'Eclipse Cross',
    'Endeavor',
    'Expo',
    'Galant',
    'i-MiEV',
    'Lancer',
    'Lancer Evolution',
    'Lancer Sportback',
    'Mirage',
    'Mirage G4',
    'Montero',
    'Montero Sport',
    'Outlander',
    'Outlander PHEV',
    'Outlander Sport',
    'Pickup',
    'Precis',
    'Raider',
  ],
  Morgan: ['Aero 8', 'Plus 8', 'Roadster'],
  Nissan: [
    '200SX',
    '240SX',
    '300ZX',
    '350Z',
    '370Z',
    'Altima',
    'Altima Hybrid',
    'Armada',
    'Cube',
    'Frontier',
    'GT-R',
    'Juke',
    'Leaf',
    'Maxima',
    'Murano',
    'Murano CrossCabriolet',
    'Murano Hybrid',
    'NV Vans',
    'NV200',
    'NX',
    'Pathfinder',
    'Pathfinder Hybrid',
    'Pickup',
    'Quest',
    'Rogue',
    'Rogue Hybrid',
    'Rogue Select',
    'Rogue Sport',
    'Sentra',
    'Stanza',
    'Titan',
    'Titan XD',
    'Versa',
    'Versa Note',
    'Xterra',
  ],
  Oldsmobile: [
    'Achieva',
    'Alero',
    'Aurora',
    'Bravada',
    'Custom Cruiser',
    'Cutlass',
    'Cutlass Calais',
    'Cutlass Ciera',
    'Cutlass Cruiser',
    'Cutlass Supreme',
    'Eighty-Eight',
    'Intrigue',
    'LSS',
    'Ninety-Eight',
    'Regency',
    'Silhouette',
    'Toronado',
  ],
  Panoz: ['AIV Roadster', 'Esperante', 'Roadster'],
  Peugeot: ['505'],
  Plymouth: [
    'Acclaim',
    'Breeze',
    'Colt',
    'Grand Voyager',
    'Laser',
    'Neon',
    'Prowler',
    'Sundance',
    'Voyager',
  ],
  Pontiac: [
    '6000',
    'Aztek',
    'Bonneville',
    'Firebird',
    'G3',
    'G5',
    'G6',
    'G8',
    'Grand Am',
    'Grand Prix',
    'GTO',
    'LeMans',
    'Montana',
    'Montana SV6',
    'Solstice',
    'Sunbird',
    'Sunfire',
    'T1000',
    'Torrent',
    'Trans Sport',
    'Vibe',
  ],
  Porsche: [
    '718 Boxster',
    '718 Cayman',
    '911',
    '918 Spyder',
    '928',
    '944',
    '968',
    'Boxster',
    'Carrera GT',
    'Cayenne',
    'Cayenne E-Hybrid',
    'Cayenne Hybrid',
    'Cayman',
    'Macan',
    'Panamera',
    'Panamera e-Hybrid',
    'Panamera e-Hybrid Sport Turismo',
    'Panamera Hybrid',
    'Panamera Sport Turismo',
  ],
  Qvale: ['Mangusta'],
  RAM: [
    '1500',
    '2500',
    '3500',
    'Cargo',
    'ProMaster 1500',
    'ProMaster 2500',
    'ProMaster 2500 Window Van',
    'ProMaster 3500',
    'ProMaster 3500 Window Van',
    'ProMaster City',
  ],
  'Rolls-Royce': [
    '40/50 HP Silver Ghost',
    'Corniche',
    'Dawn',
    'Ghost',
    'Park Ward',
    'Phantom',
    'Phantom Coupe',
    'Phantom Drophead Coupe',
    'Phantom VI',
    'Silver Dawn',
    'Silver Seraph',
    'Silver Spur',
    'Silver Spur II',
    'Wrait',
  ],
  Saab: ['9-2X', '9-3', '9-3X', '9-4X', '9-5', '9-7X', '900', '9000'],
  Saleen: ['S7'],
  Saturn: [
    'Astra',
    'Aura',
    'Aura Hybrid (all)',
    'Ion',
    'L',
    'LS',
    'LW',
    'Outlook',
    'Relay',
    'SC',
    'Sky',
    'SL',
    'SW',
    'Vue',
    'Vue Hybrid (all)',
  ],
  Scion: ['FR-S', 'iA', 'iM', 'iQ', 'tC', 'xA', 'xB', 'xD'],
  smart: ['ForTwo', 'ForTwo Electric Drive'],
  Spyker: ['C8', 'C8 Aileron', 'C8 Laviolette'],
  Sterling: ['827'],
  Subaru: [
    'B9 Tribeca',
    'Baja',
    'BRZ',
    'Crosstrek',
    'Crosstrek Hybrid',
    'Forester',
    'Impreza',
    'Impreza Outback Sport',
    'Impreza WRX',
    'Impreza WRX STi',
    'Justy',
    'Legacy',
    'Loyale',
    'Outback',
    'SVX',
    'Tribeca',
    'WRX',
    'WRX STI',
    'XT',
    'XV Crosstrek',
    'XV Crosstrek Hybrid',
  ],
  Suzuki: [
    'Aerio',
    'Equator',
    'Esteem',
    'Forenza',
    'Grand Vitara',
    'Kizashi',
    'Reno',
    'Samurai',
    'Sidekick',
    'Swift',
    'SX4',
    'Verona',
    'Vitara',
    'X-90',
    'XL7',
  ],
  Tesla: ['Model 3', 'Model S', 'Model X', 'Roadster'],
  Toyota: [
    '4Runner',
    '86',
    'Avalon',
    'Avalon Hybrid',
    'C-HR',
    'Camry',
    'Camry Hybrid',
    'Camry Solara',
    'Celica',
    'Corolla',
    'Corolla iM',
    'Cressida',
    'ECHO',
    'FJ Cruiser',
    'Highlander',
    'Highlander Hybrid',
    'Land Cruiser',
    'Matrix',
    'Mirai',
    'MR2',
    'Paseo',
    'Pickup',
    'Previa',
    'Prius',
    'Prius c',
    'Prius Plug-in',
    'Prius Prime',
    'Prius v',
    'RAV4',
    'RAV4 EV',
    'RAV4 Hybrid',
    'Sequoia',
    'Sienna',
    'Supra',
    'T100',
    'Tacoma',
    'Tercel',
    'Tundra',
    'Venza',
    'Yaris',
    'Yaris iA',
  ],
  Volkswagen: [
    'Atlas',
    'Beetle',
    'Cabrio',
    'Cabriolet',
    'CC',
    'Corrado',
    'e-Golf',
    'Eos',
    'Eurovan',
    'Fox',
    'GLI',
    'Golf',
    'Golf Alltrack',
    'Golf GTI',
    'Golf R',
    'Golf SportWagen',
    'GTI',
    'Jetta',
    'Jetta Hybrid',
    'Jetta SportWagen',
    'New Beetle',
    'New Cabrio',
    'Passat',
    'Phaeton',
    'R32',
    'Rabbit',
    'Routan',
    'Tiguan',
    'Tiguan Limited',
    'Touareg',
    'Touareg 2',
    'Touareg Hybrid',
    'Van',
  ],
  Volvo: [
    '240',
    '740',
    '850',
    '940',
    '960',
    'C30',
    'C70',
    'Coupe',
    'S40',
    'S60',
    'S60 Cross Country',
    'S60 Inscription',
    'S70',
    'S80',
    'S90',
    'S90 Hybrid',
    'V40',
    'V50',
    'V60',
    'V60 Cross Country',
    'V70',
    'V90',
    'V90 Cross Country',
    'XC40',
    'XC60',
    'XC60 Hybrid',
    'XC70',
    'XC90',
    'XC90 Hybrid',
  ],
  Yugo: ['Cabrio'],
}

const getYears = () => {
  const currentYear = new Date().getFullYear()
  const years = []

  for (let year = currentYear; year >= 1995; year--) {
    years.push(year.toString())
  }

  return years
}

export const years = getYears()
