import React from 'react'
import { connect } from 'react-redux'
import { doLogin } from 'app/actions/session'
import { Redirect } from 'react-router-dom'
import { Wrapper, Container, LogoWrapper, Title, FormWrapper } from './elements'
import MozioLogo from 'mz-icons/mozio'
import { getAuthorizationToken } from 'mz-sdk/client'
import SignupForm from 'app/components/verification/SignupForm'

const SignupPage = ({ authenticated, ...props }) => {
  if (authenticated) {
    return <Redirect to="/trips" />
  }

  return (
    <Wrapper>
      <Container>
        <LogoWrapper>
          <MozioLogo />
        </LogoWrapper>
        <Title>Provider Dashboard</Title>
        <FormWrapper>
          <SignupForm {...props} />
        </FormWrapper>
      </Container>
    </Wrapper>
  )
}

const mapStateToProps = (state) => ({
  authenticated: getAuthorizationToken(),
  errorMessage: state.session.error,
})

const mapDispatchToProps = { login: doLogin }

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage)
