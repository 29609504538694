// @flow
import React, { useReducer } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { LIGHTBOX_MODES, STATUS } from 'app/constants/values'
import * as ACTIONS from 'app/constants/actions'

import * as selectors from 'app/selectors'

import VehiclesForm from 'app/components/vehicles/VehiclesForm'
import { CSVTable, Progress } from 'app/components/commons'
import { useImportCSV } from 'app/components/commons/Worker'

import { mapCSVVehiclesToTable } from 'app/utils/vehicles'
import jsonToCSV from 'app/utils/jsonToCSV'

import * as vehiclesActions from 'app/actions/vehicles'
import { reducer, initialState } from './reducer'

const VehiclesModals = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const onSubmitCSV = async () => {
    dispatch({ type: ACTIONS.TOGGLE_CSVPREVIEW }) // close preview
    dispatch({ type: ACTIONS.TOGGLE_PROGRESS })
    dispatch({
      type: ACTIONS.PROGRESS_MESSAGE,
      payload: {
        message: ['Uploading CSV file'],
        status: STATUS.LOADING,
      },
    })

    const blob = new Blob([jsonToCSV(props.bulkCreateVehicles.vehicles)], {
      type: 'text/csv',
    })

    try {
      const {
        newVehicles: { payload: newPayload },
        updatedVehicles: { payload: updatedPayload },
      } = await props.uploadVehiclesCSV(blob)

      dispatch({
        type: ACTIONS.PROGRESS_MESSAGE,
        payload: {
          message: [
            newPayload.length > 0
              ? `${newPayload.length} Vehicles created sucessfully`
              : null,
            updatedPayload.length > 0
              ? `${updatedPayload.length} Vehicles updated`
              : null,
          ].filter(Boolean),
          status: STATUS.DONE,
        },
      })
    } catch (err) {
      dispatch({
        type: ACTIONS.PROGRESS_MESSAGE,
        payload: {
          message: ['Error on upload, please contact support'],
          status: STATUS.ERROR,
        },
      })
    }
  }

  const onImportCSV = async ({ csv_file }) => {
    dispatch({ type: ACTIONS.TOGGLE_SUBMITTING_BULK })
    const { data } = await useImportCSV(csv_file)
    dispatch({ type: ACTIONS.TOGGLE_SUBMITTING_BULK })

    if (data.error) {
      props.setVehiclesError(data.error)
      return
    }

    if (mapCSVVehiclesToTable(data.entries).entries.length) {
      props.setVehiclesFromFile(data)
      props.closeLightbox()
      dispatch({ type: ACTIONS.TOGGLE_CSVPREVIEW }) // Open CSV preview
    } else {
      props.setVehiclesError(
        'Invalid CSV entries, please check template for more details'
      )
    }
  }

  /**
   * Generic function for vehicles create and update
   * @param {Function} action
   * @param {Object} values
   */
  const onSingleVehicle =
    (action: (any) => Promise<Object>) => async (values) => {
      dispatch({ type: ACTIONS.TOGGLE_SUBMITTING_SINGLE })

      const result = await action(values)

      dispatch({ type: ACTIONS.TOGGLE_SUBMITTING_SINGLE })

      // is no result, keep lightbox open to show error
      if (result) props.onCloseLightbox()
    }

  const onSubmit = (values) => {
    const modes = {
      update: onSingleVehicle(props.updateVehicle),
      create: onSingleVehicle(props.createVehicle),
      bulk: onImportCSV,
    }

    return modes[props.mode](values)
  }

  /**
   * Track user intent when switching tabs
   * switching to SINGLE (create or update)
   * or Bulk for CSV upload
   * @param {String} mode
   */
  const onTabChange = (mode) => {
    if (state.driverId && mode === LIGHTBOX_MODES.SINGLE) {
      props.setMode(LIGHTBOX_MODES.UPDATE)
    } else if (mode === LIGHTBOX_MODES.BULK) {
      props.setMode(mode)
    } else {
      props.setMode(LIGHTBOX_MODES.CREATE)
    }

    props.setVehiclesError(null) // remove error message when switching tabs
  }

  const onCloseLightbox = () => {
    props.setVehiclesError(null)
    props.onCloseLightbox()
  }

  return (
    <>
      <VehiclesForm
        vehicle={props.vehicle}
        mode={props.mode}
        isSubmitting={state.submitting}
        isShowed={props.isLightboxFormOpen}
        onCreateVehicle={props.createVehicle}
        onUpdateVehicle={props.updateVehicle}
        onHandleSubmit={props.handleSubmit}
        onCloseLightbox={onCloseLightbox}
        onImportCSV={onImportCSV}
        onTabChange={onTabChange}
        onSubmit={onSubmit}
        errorMessage={props.errorMessage}
      />

      <CSVTable
        isShowed={state.isPreviewOpen}
        csvData={mapCSVVehiclesToTable(props.bulkCreateVehicles.vehicles)}
        onCSVClose={() => dispatch({ type: ACTIONS.TOGGLE_CSVPREVIEW })}
        onSubmitCSV={onSubmitCSV}
      />

      <Progress
        showed={state.isProgressOpen}
        onClose={() => {
          dispatch({ type: ACTIONS.TOGGLE_PROGRESS })
        }}
        message={state.progressMessage}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(vehiclesActions, dispatch),
})

const mapStateToProps = (state) => {
  return {
    bulkCreateVehicles: selectors.getBulkVehicles(state),
    uploadError: state.vehicles.bulkCreate.error,
    errorMessage: state.vehicles.error,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesModals)
