import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { doLogin } from 'app/actions/session'
import { reduxForm } from 'redux-form'
import { Redirect } from 'react-router-dom'
import { Wrapper, Container, LogoWrapper, Title } from './elements'
import { getAuthorizationToken } from 'mz-sdk/client'
import MozioLogo from 'mz-icons/mozio'
import LoginForm from 'app/components/login/LoginForm'

const LoginPage = ({ authenticated, ...props }) => {
  if (authenticated) {
    return <Redirect to="/trips" />
  }

  return (
    <Wrapper>
      <Container>
        <LogoWrapper>
          <MozioLogo />
        </LogoWrapper>
        <Title>Provider Dashboard</Title>
        <LoginForm {...props} />
      </Container>
    </Wrapper>
  )
}

const mapStateToProps = (state) => ({
  authenticated: getAuthorizationToken(),
  errorMessage: state.session.error,
})

const mapDispatchToProps = { login: doLogin }

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'login' })
)(LoginPage)
