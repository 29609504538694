import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { reducer as formReducer } from 'redux-form'
import reducers from './reducers'

const rootReducer = combineReducers({ ...reducers, form: formReducer })

const storeWithDevToolsConfig = composeWithDevTools(applyMiddleware(thunk))(
  createStore
)

const storeConfig = storeWithDevToolsConfig(rootReducer)

export default storeConfig
