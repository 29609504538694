import { styled } from 'mz-ui-kit'
import { NAVBAR_HEIGHT } from 'app/constants/sizes'

export const Container = styled('header', ({ $palette }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: `${NAVBAR_HEIGHT}`,
  padding: '0 20px',
  fontWeight: 'normal',
  borderBottom: `1px solid ${$palette('GRAY')}`,
  background: `${$palette('WHITE')}`,
}))

export const Branding = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

export const Logo = styled('img', () => ({
  width: 'auto',
  height: '40px',
}))

export const Title = styled('h1', ({ $palette }) => ({
  fontSize: '16px',
  marginLeft: '10px',
  fontWeight: '100',
  color: `${$palette('TEXT')}`,
}))

export const Beta = styled('span', ({ $palette }) => ({
  backgroundColor: `${$palette('YELLOW')}`,
  color: `${$palette('WHITE')}`,
  padding: '3px 5px',
  marginLeft: '10px',
}))
