import { createAction } from 'app/utils/redux'
import { change } from 'redux-form'
import Client from 'mz-sdk/client'
import { VEHICLES_ENDPOINT } from 'app/constants/endpoints'
import {
  ADD_VEHICLES,
  ADD_VEHICLE,
  REMOVE_VEHICLE,
  SET_VEHICLES_ERROR,
  SET_UPDATED_VEHICLE,
  SET_BULK_CREATE_VEHICLES,
  REMOVE_TEMP_VEHICLES,
  ADD_BULK_VEHICLES,
  SET_UPLOAD_ERROR,
  UPDATE_BULK_VEHICLES,
} from 'app/constants/actions'

export const setVehicles = createAction(ADD_VEHICLES)

export const addVehicle = createAction(ADD_VEHICLE)

export const removeVehicle = createAction(REMOVE_VEHICLE)

export const setVehiclesError = createAction(SET_VEHICLES_ERROR)

export const setUpdatedVehicle = createAction(SET_UPDATED_VEHICLE)

export const setVehiclesFromFile = createAction(SET_BULK_CREATE_VEHICLES)

export const removeTempVehicles = createAction(REMOVE_TEMP_VEHICLES)

export const addBulkVehicles = createAction(ADD_BULK_VEHICLES)

export const updateBulkVehicles = createAction(UPDATE_BULK_VEHICLES)

export const setUploadError = createAction(SET_UPLOAD_ERROR)

export const getVehicles = () => {
  return async (dispatch) => {
    try {
      const response = await Client.get(VEHICLES_ENDPOINT)
      return response.results && response.results.length > 0
        ? dispatch(setVehicles(response))
        : dispatch(setVehiclesError('NO RESULTS!'))
    } catch (error) {
      return dispatch(setVehiclesError(error.message))
    }
  }
}

export const createVehicle = (data) => {
  return async (dispatch) => {
    try {
      const response = await Client.post(VEHICLES_ENDPOINT, { body: data })
      dispatch(addVehicle(response))
      dispatch(setVehiclesError(null))
      return response
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    return null
  }
}

export const updateVehicle = ({ id, ...data }) => {
  return async (dispatch) => {
    try {
      const response = await Client.patch(`${VEHICLES_ENDPOINT}${id}/`, {
        body: data,
      })
      dispatch(setUpdatedVehicle(response))
      dispatch(setVehiclesError(null))
      return response
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    return null
  }
}

export const initFromVehicle = (vehicle) => (dispatch) => {
  if (!vehicle) return

  const vehiclesAttributes = [
    'id',
    'manufacturer',
    'model',
    'year',
    'plate',
    'color',
  ]

  for (let i = 0; i < vehiclesAttributes.length; i++) {
    const attr = vehiclesAttributes[i]
    dispatch(change('vehicles', attr, vehicle[attr]))
  }
}

export const deleteVehicle = (id) => {
  return async (dispatch) => {
    try {
      await Client.delete(`${VEHICLES_ENDPOINT}${id}/`)
      dispatch(removeVehicle(id))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}

export const getMoreVehicles = (nextUrl) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.get(
        nextUrl,
        {},
        { externallyBuiltUrl: true }
      )

      const currentVehicles = Object.values(getState().vehicles.results) || []
      return dispatch(
        setVehicles({
          ...response,
          results: currentVehicles.concat(response.results),
        })
      )
    } catch (error) {
      return dispatch(setVehiclesError(error.message))
    }
  }
}

export const uploadVehiclesCSV = (blobFile) => {
  return async (dispatch) => {
    if (!blobFile) return null
    const body = new FormData()
    body.append('csv_file', blobFile)
    const path = `${VEHICLES_ENDPOINT}upload/?process=1`

    const vehicles = await Client.post(path, { body })
    dispatch(setVehiclesError(null))
    return {
      newVehicles: dispatch(addBulkVehicles(vehicles.new)),
      updatedVehicles: dispatch(updateBulkVehicles(vehicles.updates)),
    }
  }
}
