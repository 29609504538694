import { styled } from 'mz-ui-kit'
import { Field } from 'redux-form'
import { INPUT_HEIGHT, BORDER_RADIUS } from 'app/constants/sizes'

export const Input = styled(Field, () => ({
  boxSizing: 'border-box',
  width: '100%',
  height: INPUT_HEIGHT,
  margin: '5px 0',
  padding: '10px',
  border: '0',
  borderRadius: BORDER_RADIUS,
}))

export const ButtonWrapper = styled('div', {
  display: 'flex',
  flexFlow: 'column',
  padding: '10px 0 0 0',
})

export const ErrorMessage = styled('span', ({ $palette }) => ({
  color: `${$palette('WHITE')}`,
}))
