import React, { Component } from 'react'
import { DRIVERS_ENDPOINT } from 'app/constants/endpoints'
import Client from 'mz-sdk/client'
import LoadingSpinner from 'mz-ui-kit/core/LoadingSpinner'
import { Link, Container, Message } from './elements'

/**
 * Component for showing SMS resend link
 */
class DriverLinkResend extends Component {
  state = { state: 'ready' }

  async resendLink() {
    const { id } = this.props
    return Client.post(`${DRIVERS_ENDPOINT}${id}/resend-sms/`)
  }

  handleClickResend = async () => {
    try {
      this.setState({ state: 'sending' })
      await this.resendLink()
      this.setState({ state: 'done' })
    } catch (e) {
      this.setState({ state: 'error' })
    }
  }

  render() {
    const { state } = this.state
    const isSending = state === 'sending'
    const isReady = state === 'ready'
    const isDone = state === 'done'
    const isError = state === 'error'

    return (
      <Container>
        {isReady && (
          <Link onClick={this.handleClickResend}>
            Didn&apos;t receive an SMS?
          </Link>
        )}
        {!isReady && (
          <Message>
            {isSending && 'Sending another SMS...'}
            {isDone && 'SMS sent!'}
            {isError && 'Ooops, something went wrong!'}
          </Message>
        )}
        {(isError || isDone) && (
          <Link onClick={this.handleClickResend}>Try to send again</Link>
        )}
        {isSending && <LoadingSpinner />}
      </Container>
    )
  }
}

export default DriverLinkResend
