import React from 'react'
import { styled } from 'mz-ui-kit'

const Container = styled('div', {})

const Title = styled('h1', {})

const NoResults = () => (
  <Container>
    <Title>No items found here</Title>
  </Container>
)

export default NoResults
