import { styled } from 'mz-ui-kit'

export const FormTitle = styled('h2', ({ $palette }) => ({
  fontWeight: 100,
  color: `${$palette('GREY')}`,
}))

export const FormSubTitle = styled('h3', ({ $palette }) => ({
  fontWeight: 100,
  color: `${$palette('GREY')}`,
  marginTop: '24px',
}))

export const Content = styled('div', {
  padding: '20px 40px',
  minWidth: '490px',
})

export const FlightHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const Label = styled('h4', ({ $palette }) => ({
  fontSize: '12px',
  fontWeight: 'normal',
  margin: '0 0 16px',
  color: `${$palette('TEXT')}`,
}))

export const FlightDirectionRadios = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  alignItems: 'center',
  margin: '0',
})
