import { styled } from 'mz-ui-kit'

export const Wrapper = styled('div', ({ $palette }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  background: `${$palette('PRIMARY')}`,
}))

export const Container = styled('div', ({ $palette }) => ({
  display: 'flex',
  alignSelf: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '40px',
  maxWidth: '240px',
  background: `${$palette('PRIMARY').darken(6)}`,
}))

export const Title = styled('h1', ({ $palette }) => ({
  fontSize: '16px',
  margin: '10px 0 10px 0',
  textAlign: 'center',
  color: `${$palette('YELLOW')}`,
}))

export const LogoWrapper = styled('div', () => ({
  color: 'white',
  textAlign: 'center',
  fontSize: '30px',
}))
