import React, { Component } from 'react'
import Dropdown from 'mz-ui-kit/core/Dropdown'
import Icon from 'app/components/commons/Icon'
import { Action, Pointer } from './elements'

const getOptions = (onUpdate, onDelete) => {
  const options = []
  if (onUpdate) options.push({ name: 'edit', icon: 'edit', action: onUpdate })
  if (onDelete)
    options.push({ name: 'delete', icon: 'delete', action: onDelete })
  return options
}

class Actions extends Component {
  handleSelect = (option) => {
    option.action()
  }

  render() {
    const { onUpdate, onDelete } = this.props
    return (
      <Dropdown
        placement="auto"
        onSelect={this.handleSelect}
        options={getOptions(onUpdate, onDelete)}
        renderOption={(option) => (
          <Action>
            <Icon $size="small"> {option.icon} </Icon>
            <span> {option.name} </span>
          </Action>
        )}
        target={(innerRef, targetProps) => (
          <div {...targetProps} ref={innerRef}>
            <Pointer>
              <Icon> more_vert </Icon>
            </Pointer>
          </div>
        )}
      />
    )
  }
}

export default Actions
