import React, { Fragment, Component } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import Client, { saveJWTToken } from 'mz-sdk/client'
import { reduxForm, Field } from 'redux-form'

import Alert from 'mz-ui-kit/core/Alert'
import Button from 'mz-ui-kit/core/Button'
import TextField from 'mz-ui-kit/form/TextField'
import { FormattedMessage } from 'mz-intl'
import messages from '../messages.intl'
import validate from './validate'
import { Spacer, FormWrapper } from './elements'
import {
  SIGNUP_FORM,
  EMAIL_FIELD,
  PASSWORD_FIELD,
  CONFIRM_PASSWORD_FIELD,
} from 'app/constants/names'

const SIGNUP_ENDPOINT = '/auth/drivers/create-admin/'

class SignupForm extends Component {
  state = {
    error: null,
  }

  onSubmit = async (values) => {
    // eslint-disable-next-line camelcase
    const { providerUid: provider_uid, token, history } = this.props
    const { email, password } = values
    const body = {
      email,
      password,
      provider_uid,
      token,
    }
    this.setState({ error: null })
    try {
      const response = await Client.post(SIGNUP_ENDPOINT, { body })
      if (response.redirect_to) {
        // 302 from server caused frontend to behave wrongfully
        this.setState({
          error: messages.EXISTING_ACCOUNT,
        })
        return
      }
      saveJWTToken(response)
      history.push('/trips')
    } catch (error) {
      this.setState({
        error:
          error?.message ??
          'Sorry, something went wrong. Please try again or contact our support.',
      })
    }
  }

  render() {
    const { handleSubmit, submitting } = this.props
    const { error } = this.state
    return (
      <FormWrapper onSubmit={handleSubmit(this.onSubmit)}>
        <Field
          name={EMAIL_FIELD}
          label={<FormattedMessage message={messages.EMAIL} />}
          component={TextField}
        />
        <Spacer />
        <Field
          type="password"
          name={PASSWORD_FIELD}
          label={<FormattedMessage message={messages.PASSWORD} />}
          component={TextField}
        />
        <Spacer />
        <Field
          type="password"
          name={CONFIRM_PASSWORD_FIELD}
          label={<FormattedMessage message={messages.CONFIRM_PASSWORD} />}
          component={TextField}
        />
        {error && (
          <Fragment>
            <Spacer />
            <Alert color="RED">{error}</Alert>
          </Fragment>
        )}
        <Spacer $big />
        <Button
          fullWidth
          type="submit"
          size="medium"
          loading={submitting}
          disabled={submitting}
        >
          <FormattedMessage message={messages.CREATE_AN_ACCOUNT} />
        </Button>
      </FormWrapper>
    )
  }
}

export default compose(
  withRouter,
  reduxForm({
    form: SIGNUP_FORM,
    validate,
  })
)(SignupForm)
