import * as React from 'react'
import PrivateRoute from './PrivateRoute'
import Dashboard from 'app/components/containers/Dashboard'

const DashboardRoute = ({ component: Component, ...rest }) => (
  <PrivateRoute
    {...rest}
    component={(props) => (
      <Dashboard>
        <Component {...props} />
      </Dashboard>
    )}
  />
)

export default DashboardRoute
