import { createReducer } from 'app/utils/redux'
import {
  SET_TRIPS,
  ADD_TRIPS,
  UPDATE_TRIP,
  SET_TRIPS_ERROR,
  SET_FILTERS,
  APPEND_TRIP,
  STATUS_START_LOADING,
  STATUS_STOP_LOADING,
} from 'app/constants/actions'

const initialState = {
  trips: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  pastTrips: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  tripStatuses: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  filters: {},
  error: null,
}

const actionHandlers = {
  [SET_TRIPS]: (state, action) => {
    return {
      ...state,
      trips: {
        ...action.payload.trips,
      },
      pastTrips: { ...action.payload.pastTrips },
      tripStatuses: { ...state.tripStatuses, ...action.payload.tripStatuses },
    }
  },
  [APPEND_TRIP]: (state, action) => {
    return {
      ...state,
      trips: {
        ...state.trips,
        count: state.trips.count + 1,
        results: [action.payload, ...state.trips.results],
      },
    }
  },
  [ADD_TRIPS]: (state, { payload: { name, page, tripStatuses } }) => {
    const stateResults = state[name].results

    return {
      ...state,
      [name]: {
        ...page,
        results: [
          ...stateResults,
          ...page.results.filter((trip) => {
            const stateHasTrip = stateResults.find((tripInResults) => {
              return tripInResults.user_external_id === trip.user_external_id
            })

            return !stateHasTrip
          }),
        ],
      },
      tripStatuses: {
        ...state.tripStatuses,
        results: [...state.tripStatuses.results, ...tripStatuses.results],
      },
    }
  },
  [UPDATE_TRIP]: (state, { payload }) => {
    return {
      ...state,
      trips: {
        ...state.trips,
        results: state.trips.results.map((trip) => {
          return trip.hashed_id === payload.hashed_id ? payload : trip
        }),
      },
    }
  },
  [STATUS_START_LOADING]: (state, { payload }) => {
    return {
      ...state,
      trips: {
        ...state.trips,
        results: state.trips.results.map((trip) => {
          return trip.hashed_id === payload.hashed_id
            ? {
                ...trip,
                isLoading: true,
              }
            : trip
        }),
      },
    }
  },
  [STATUS_STOP_LOADING]: (state, { payload }) => {
    return {
      ...state,
      trips: {
        ...state.trips,
        results: state.trips.results.map((trip) => {
          return trip.hashed_id === payload.hashed_id
            ? {
                ...trip,
                isLoading: false,
              }
            : trip
        }),
      },
    }
  },
  [SET_TRIPS_ERROR]: (state, action) => {
    return { ...state, error: action.payload }
  },
  [SET_FILTERS]: (state, { payload: filters }) => {
    return { ...state, filters }
  },
}

export default createReducer(initialState, actionHandlers)
