import React, { Component } from 'react'
import { Container } from './elements'

class AppPage extends Component {
  render() {
    return <Container>{this.props.children}</Container>
  }
}

export default AppPage
