import React from 'react'
import FileField from 'mz-ui-kit/form/FileField/field'
import { BulkWrapper, BulkTitle, BulkDescription } from './elements'

export default function BulkCreate({ nest, title, description }) {
  return (
    <BulkWrapper>
      <BulkTitle>{title}</BulkTitle>
      <BulkDescription>{description}</BulkDescription>
      <FileField
        {...nest('csv_file')}
        accept="text/csv"
        label="Select a CSV File"
      />
    </BulkWrapper>
  )
}
