// @flow
import React, { useState } from 'react'
import Lightbox from 'mz-ui-kit/core/Lightbox'
import Button from 'mz-ui-kit/core/Button'

import { Table, Body, Headers, Row, Cell } from 'app/components/table/elements'
import { TableWrapper, Actions, ActionButtons } from './elements'

const INITIAL_COUNT = 30

type Props = {
  onCSVClose: () => void,
  onSubmitCSV: () => void,
  isShowed: boolean,
  csvData: {
    headers: Array<{
      label: String,
      id: String,
    }>,
    entries: Array<Object>,
  },
}

export default function CSVTable({
  csvData,
  onCSVClose,
  onSubmitCSV,
  isShowed,
}: Props) {
  const [usePage, setPage] = useState(INITIAL_COUNT)

  const [useSubmit, setSubmit] = useState(false)

  const hasMore = csvData.entries.length >= usePage

  const render = () => {
    return (
      <div>
        <TableWrapper>
          <Table>
            <Body>
              <Headers>
                {csvData.headers.map((heading, index) => (
                  <Cell key={index}>{heading.label}</Cell>
                ))}
              </Headers>
              {csvData.entries.slice(0, usePage).map((rowProps, index) => {
                return (
                  <Row key={index}>
                    {csvData.headers.map((hd, entryIndex) => (
                      <Cell key={entryIndex}>{rowProps[hd.id]}</Cell>
                    ))}
                  </Row>
                )
              })}
            </Body>
          </Table>
        </TableWrapper>
        <Actions>
          <div>
            <Button
              disabled={!hasMore}
              onClick={() => setPage(usePage + INITIAL_COUNT)}
            >
              Load More
            </Button>
          </div>
          <ActionButtons>
            <>
              <Button
                color="gray"
                onClick={() => {
                  onCSVClose()
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setSubmit(true)
                  onSubmitCSV()
                  setSubmit(false)
                }}
                loading={useSubmit}
              >
                Upload File
              </Button>
            </>
          </ActionButtons>
        </Actions>
      </div>
    )
  }

  return <Lightbox onClose={onCSVClose} showed={isShowed} render={render} />
}
