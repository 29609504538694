import { createReducer } from 'app/utils/redux'
import {
  ADD_VEHICLES,
  ADD_VEHICLE,
  REMOVE_VEHICLE,
  SET_VEHICLES_ERROR,
  SET_UPDATED_VEHICLE,
  SET_BULK_CREATE_VEHICLES,
  REMOVE_TEMP_VEHICLES,
  ADD_BULK_VEHICLES,
  SET_UPLOAD_ERROR,
  UPDATE_BULK_VEHICLES,
} from 'app/constants/actions'

const initialState = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  error: null,
  bulkCreate: {
    vehicles: [],
    error: [],
  },
}

const actionHandlers = {
  [ADD_VEHICLES]: (state, action) => {
    return { ...state, ...action.payload }
  },
  [ADD_VEHICLE]: (state, action) => {
    return {
      ...state,
      results: [action.payload, ...state.results],
    }
  },
  [REMOVE_VEHICLE]: (state, action) => {
    const id = action.payload
    return {
      ...state,
      results: state.results.filter((result) => result.id !== id),
    }
  },
  [SET_UPDATED_VEHICLE]: (state, action) => {
    const vehicle = action.payload
    return {
      ...state,
      results: state.results.map((result) =>
        result.id !== vehicle.id ? result : vehicle
      ),
    }
  },
  [SET_VEHICLES_ERROR]: (state, action) => {
    return { ...state, error: action.payload }
  },
  [SET_BULK_CREATE_VEHICLES]: (state, action) => {
    return {
      ...state,
      bulkCreate: {
        ...state.bulkCreate,
        vehicles: action.payload.entries,
      },
    }
  },
  [REMOVE_TEMP_VEHICLES]: (state) => {
    return {
      ...state,
      bulkCreate: {
        ...state.bulkCreate,
        vehicles: [],
      },
    }
  },
  [ADD_BULK_VEHICLES]: (state, action) => {
    return {
      ...state,
      results: state.results.concat(action.payload),
    }
  },
  [SET_UPLOAD_ERROR]: (state, action) => {
    return {
      ...state,
      bulkCreate: {
        ...state.bulkCreate,
        error: action.payload,
      },
    }
  },
  [UPDATE_BULK_VEHICLES]: (state, action) => {
    return {
      ...state,
      results: [
        ...state.results.reduce((vehicleAcc, vehicleCurr) => {
          const updatedVehicle = action.payload.find(
            (v) => v.plate === vehicleCurr.plate
          )
          if (updatedVehicle) {
            return [
              ...vehicleAcc,
              {
                ...vehicleCurr,
                ...updatedVehicle,
              },
            ]
          }
          return [...vehicleAcc, vehicleCurr]
        }, []),
      ],
    }
  },
}

export default createReducer(initialState, actionHandlers)
