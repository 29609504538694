import * as React from 'react'
import { useDataLoader } from 'mz-ui-kit/logic/DataLoader'
import SelectField from 'mz-ui-kit/form/SelectField'
import EmptySearchResults from 'mz-ui-kit/form/SelectField/EmptySearchResults'
import { VEHICLES_ENDPOINT } from 'app/constants/endpoints'
import Client from 'mz-sdk/client'

function requestPlaces(query) {
  if (!query || !query.trim()) return []
  return Client.get(VEHICLES_ENDPOINT, { query: { search: query } }).then(
    (response) => response.results
  )
}

function renderVehicleToString({ manufacturer, model, year, plate }) {
  if (!manufacturer) return '...'
  return `${manufacturer} / ${model} / ${year} / ${plate || 'Unknow plate'}`
}

function renderEmpty(query) {
  if (!query || !query.trim()) return null
  return (
    <EmptySearchResults
      query={query}
      emptyResultsMessage={`No vehicles found by request "${query}"`}
    />
  )
}

function VehicleField(props) {
  // Resolve vehicle object by id if other fields not defined
  const currValue = props.input?.value
  const [{ data, loading }] = useDataLoader(async () => {
    if (!currValue || (currValue?.id && currValue.manufacturer)) return null

    return Client.get(`${VEHICLES_ENDPOINT}${currValue.id}/`)
  }, [currValue?.id, currValue?.manufacturer])

  // Set resolved vehicle object when it is ID changed
  React.useEffect(() => {
    if (data && props.input?.onChange) {
      props.input.onChange(data)
    }
  }, [data?.id])

  return (
    <SelectField
      emptyValue={null}
      label="Vehicle"
      placeholder="Type a part of the plate, model or manufacturer"
      loading={loading}
      {...props}
      searchableOptions={{
        ...props.searchableOptions,
        requestOptions: requestPlaces,
        renderEmpty,
      }}
      renderValue={renderVehicleToString}
      renderOption={renderVehicleToString}
    />
  )
}

export default VehicleField
