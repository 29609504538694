import React from 'react'
import Navbar from 'app/components/commons/Navbar'
import Sidebar from 'app/components/commons/Sidebar'
import { Container, Content } from './elements'

const Dashboard = ({ children }) => (
  <section>
    <Navbar />
    <Container>
      <Sidebar />
      <Content>{children}</Content>
    </Container>
  </section>
)

export default Dashboard
