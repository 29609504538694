// @flow
import React, { useReducer } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { LIGHTBOX_MODES, STATUS } from 'app/constants/values'
import * as ACTIONS from 'app/constants/actions'

import * as selectors from 'app/selectors'

import DriversForm from 'app/components/drivers/DriversForm'

import { CSVTable, Progress } from 'app/components/commons'
import { useImportCSV } from 'app/components/commons/Worker'

import { mapCSVDriversToTable, mapErrors } from 'app/utils/drivers'
import jsonToCSV from 'app/utils/jsonToCSV'

import * as driversActions from 'app/actions/drivers'
import { reducer, initialState } from './reducer'

const DriverModals = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const onSubmitCSV = async () => {
    dispatch({ type: ACTIONS.TOGGLE_CSVPREVIEW }) // close preview
    dispatch({ type: ACTIONS.TOGGLE_PROGRESS })
    dispatch({
      type: ACTIONS.PROGRESS_MESSAGE,
      payload: {
        message: ['Uploading CSV file'],
        status: STATUS.LOADING,
      },
    })

    const blob = new Blob([jsonToCSV(props.bulkCreateDrivers.drivers)], {
      type: 'text/csv',
    })

    try {
      await props.uploadDriversCSV(blob)
      dispatch({
        type: ACTIONS.PROGRESS_MESSAGE,
        payload: {
          message: ['Drivers created successfully'],
          status: STATUS.DONE,
        },
      })
    } catch (err) {
      const errors = mapErrors(err.response.errors, props.drivers)
      dispatch({
        type: ACTIONS.PROGRESS_MESSAGE,
        payload: {
          message: errors,
          status: STATUS.ERROR,
        },
      })
    }
  }

  const onImportCSV = async ({ csv_file }) => {
    dispatch({ type: ACTIONS.TOGGLE_SUBMITTING_BULK })
    const { data } = await useImportCSV(csv_file)
    dispatch({ type: ACTIONS.TOGGLE_SUBMITTING_BULK })

    if (data.error) {
      props.setDriversError(data.error)
      return
    }

    if (mapCSVDriversToTable(data.entries).entries.length) {
      props.addTempDrivers(data)
      props.closeLightbox()
      dispatch({ type: ACTIONS.TOGGLE_CSVPREVIEW }) // Open CSV preview
    } else {
      props.setDriversError(
        'Invalid CSV entries, please check template for more details'
      )
    }
  }

  /**
   * Generic function for driver create and update
   * @param {Function} action
   * @param {Object} values
   */
  const onSingleDriver =
    (action: (any) => Promise<Object>) => async (values) => {
      dispatch({ type: ACTIONS.TOGGLE_SUBMITTING_SINGLE })

      const result = await action(values)

      dispatch({ type: ACTIONS.TOGGLE_SUBMITTING_SINGLE })

      // is no result, keep lightbox open to show error
      if (result) props.closeLightbox()
    }

  const onSubmit = (values) => {
    const modes = {
      update: onSingleDriver(props.updateDriver),
      create: onSingleDriver(props.createDriver),
      bulk: onImportCSV,
    }
    return modes[props.mode](values)
  }

  /**
   * Track user intent when switching tabs
   * switching to SINGLE (create or update)
   * or Bulk for CSV upload
   * @param {String} mode
   */
  const onTabChange = (mode) => {
    if (props.driver && mode === LIGHTBOX_MODES.SINGLE) {
      props.setMode(LIGHTBOX_MODES.UPDATE)
    } else if (mode === LIGHTBOX_MODES.BULK) {
      props.setMode(mode)
    } else {
      props.setMode(LIGHTBOX_MODES.CREATE)
    }

    props.setDriversError(null) // remove error message when switching tabs
  }

  return (
    <>
      <DriversForm
        driver={props.driver}
        isShowed={props.isLightboxFormOpen}
        mode={props.mode}
        errorMessage={props.errorMessage}
        onCloseLightbox={props.onCloseLightbox}
        onTabChange={onTabChange}
        onSubmit={onSubmit}
      />

      <CSVTable
        isShowed={state.isPreviewOpen}
        csvData={mapCSVDriversToTable(props.bulkCreateDrivers.drivers)}
        onCSVClose={() => dispatch({ type: ACTIONS.TOGGLE_CSVPREVIEW })}
        onSubmitCSV={onSubmitCSV}
      />

      <Progress
        showed={state.isProgressOpen}
        onClose={() => {
          dispatch({ type: ACTIONS.TOGGLE_PROGRESS })
        }}
        message={state.progressMessage}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...driversActions,
    },
    dispatch
  )

const mapStateToProps = (state) => ({
  bulkCreateDrivers: selectors.getBulkDrivers(state),
  errorMessage: state.drivers.error,
  drivers: state.drivers,
})

export default connect(mapStateToProps, mapDispatchToProps)(DriverModals)
