// @flow
import React, { Component, Fragment } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import Row from 'mz-ui-kit/core/Row'
import Button from 'mz-ui-kit/core/Button'
import { FormattedMessage } from 'mz-intl'
import {
  Heading,
  Buttons,
  CustomLabelTag,
  ErrorMessageWrapper,
} from '../elements'
import messages from '../messages.intl'
import * as tripActions from 'app/actions/trips'
import { getVehicleType } from 'app/utils/trips'
import VehicleField from 'app/components/commons/VehicleField'
import DriverField from 'app/components/commons/DriverField'
import posthog from 'posthog-js'
import moment from 'moment'

type Props = {
  assignDriverAndVehicle: (trip: Object, values: any) => any,
  trip: Object,
  initFromTrip: (Object) => void,
  close: () => void,
  unassignDriverAndVehicle: (trip: Object) => any,
  handleSubmit: (() => any) => Function,
  submitting: boolean,
  setPreferredVehicle: (Object) => void,
  errorMessage: string,
}

class AssignForm extends Component<Props> {
  componentDidMount() {
    const { trip, initFromTrip } = this.props
    initFromTrip(trip)
  }

  onSubmit = (values) => {
    return this.props
      .assignDriverAndVehicle(this.props.trip, values)
      .then(() => {
        posthog.capture('Driver Assigned To A Trip', {
          ride_pickup_datetime_utc: this.props.trip.departure_datetime,
          time_before_pickup_min: moment(
            this.props.trip.departure_datetime
          ).diff(moment(), 'minutes'),
        })
        this.props.close()
      })
      .catch(() => {})
  }

  unassign = () => {
    return this.props
      .unassignDriverAndVehicle(this.props.trip)
      .then(() => this.props.close())
      .catch(() => {})
  }

  render() {
    const {
      trip,
      close,
      handleSubmit,
      submitting,
      setPreferredVehicle,
      errorMessage,
    } = this.props

    return (
      <Fragment>
        <Heading>
          <FormattedMessage message={messages.ASSIGN_A_DRIVER} />
        </Heading>
        <section>
          <Field
            name="driver"
            label={<FormattedMessage message={messages.DRIVER_LABEL} />}
            component={DriverField}
            onChange={(event, driver) => {
              if (!driver || !driver.preferred_vehicle_id) return
              setPreferredVehicle({ id: driver.preferred_vehicle_id })
            }}
          />
          <FormattedMessage
            tagName={CustomLabelTag}
            message={messages.REQUEST_VEHICLE_TYPE}
            values={{
              type: getVehicleType(trip),
            }}
          />
          <Field
            name="vehicle"
            label={
              <FormattedMessage message={messages.PREFRERRED_VEHICLE_LABEL} />
            }
            component={VehicleField}
          />
        </section>
        <Buttons>
          <Row flexible>
            <Button color="gray" onClick={close}>
              <FormattedMessage message={messages.CANCEL} />
            </Button>
            <Button
              color="red"
              loading={submitting}
              disabled={!trip.driver || submitting}
              onClick={handleSubmit(this.unassign)}
            >
              <FormattedMessage message={messages.UNASSIGN} />
            </Button>
            <Button
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit(this.onSubmit)}
            >
              <FormattedMessage message={messages.ASSIGN} />
            </Button>
          </Row>
          {errorMessage && (
            <FormattedMessage
              message={errorMessage}
              tagName={ErrorMessageWrapper}
            />
          )}
        </Buttons>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  initialValues: {},
  errorMessage: state.trips.error,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(tripActions, dispatch)

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'assign', enableReinitialize: true })
)(AssignForm)
