import createMessages from 'mz-intl/create'

export default createMessages('VERIFY_RESERVATION', {
  SUCCESSFULLY_VERIFIED: 'The reservation has been succesfully verified',
  CONTINUE_TO_CREATE_ACCOUNT: 'Please continue by creating an account.',
  TAKE_ME_TO: 'Take me to the',
  LOGIN_PAGE: 'login page',
  DASHBOARD: 'dashboard',
  CAN_VERIFY_UNTIL:
    'Note: You can continue verifying reservations without an account until Januray 1st.',
  ACCOUNT_WILL_BE_NEEDED_THEN:
    'After that date, an account will be required to verify any reservation.',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  LOGIN: 'Login',
  INVALID_URL: 'Sorry, the page you visited is invalid',
  DEFAULT_ERROR_MESSAGE: 'Sorry, something went wrong',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  CREATE_AN_ACCOUNT: 'Create an account',
  ENTER_AN_EMAIL: 'Please enter your email',
  ENTER_A_VALID_EMAIL: 'Please enter a valid email',
  ENTER_A_PASSWORD: 'Please enter your password',
  CONFIRM_PASSWORD_ERROR: 'Please confirm your password',
  PASSWORD_DONT_MATCH: "Passwords doesn't match",
  PASSWORD_FORMAT:
    'Password must contain: one letter, one number, one special character and be 8 characters or longer',
  NEED_ACCOUNT_TO_VERIFY: 'You need to create and accout to verify trips.',
  EXISTING_ACCOUNT:
    'An account is already registered with this email. <a href="https://www.mozio.com/en-us/user/forgot-password">Forgot your password?</a>',
})
