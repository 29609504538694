// @flow
import React, { useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import normalizeErrors from 'mz-sdk/utils/normalizeErrors'
import TripsForm from '../TripsForm'
import { reducer, initialState } from './reducer'
import { createTrip, appendTrip } from 'app/actions/trips'

type Props = {
  isLightboxFormOpen: boolean,
  onCloseLightbox: () => void,
}

const TripsModals = ({ isLightboxFormOpen, onCloseLightbox }: Props) => {
  const dispatch = useDispatch()
  const [state] = useReducer(reducer, initialState)
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmit = async (values) => {
    try {
      const trip = await dispatch(createTrip(values))
      dispatch(appendTrip(trip))
      if (trip) onCloseLightbox()
    } catch (err) {
      const errors = normalizeErrors(err).errors.join(' ')
      setErrorMessage(errors)
    }
  }

  return (
    <TripsForm
      isSubmitting={state.isSubmittingSingle}
      isShowed={isLightboxFormOpen}
      onCloseLightbox={() => {
        onCloseLightbox()
        setErrorMessage('')
      }}
      onSubmit={onSubmit}
      errorMessage={errorMessage}
    />
  )
}

export default TripsModals
