export default function joinToCSV(entries) {
  if (entries.length > 0) {
    const headers = Object.keys(entries[0]).join(',')
    const csv = entries.reduce(
      (acc, entry) => {
        const values = Object.values(entry).join(',')
        acc.push(values)
        return acc
      },
      [headers]
    )
    return csv.join('\n')
  }
  return null
}
