const API_KEY = 'c09cc3d01bbe22cb5d7afab4f82c8f3f'

export const ALLOWED_PARAMS = [
  'method',
  'body',
  'headers',
  'credentials',
  'query',
]

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Api-Key': API_KEY,
}

export const PAGE_SIZES = {
  FUTURE_TRIPS: 10,
  PAST_TRIPS: 20,
}

export const LIGHTBOX_MODES = {
  SINGLE: 'single',
  CREATE: 'create',
  UPDATE: 'update',
  BULK: 'bulk',
}

export const TAB_MODE = {
  SINGLE: 'single',
  BULK: 'bulk',
}

export const STATUS = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  DONE: 'DONE',
}
