import { styled } from 'mz-ui-kit'

const SIZES = {
  small: '20px',
}

export default styled('i', ({ $color, $size, $palette, $noMargin }) => ({
  color: $color ? `${$palette($color)}` : 'inherit',
  fontFamily: 'Material Icons',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: SIZES[$size] || '24px',
  display: 'inline-block',
  lineHeight: '1',
  textTransform: 'none',
  letterSpacing: 'normal',
  wordWrap: 'normal',
  whiteSpace: 'nowrap',
  direction: 'ltr',
  textRendering: 'optimizeLegibility',
  fontFeatureSettings: 'liga',
  marginRight: $noMargin ? 0 : '10px',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
}))
