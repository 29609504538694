import React from 'react'
import { FormattedMessage } from 'mz-intl'
import messages from '../messages.intl'
import omitEmptyFields from 'mz-utils/omitEmptyFields'
import {
  EMAIL_FIELD,
  PASSWORD_FIELD,
  CONFIRM_PASSWORD_FIELD,
} from 'app/constants/names'

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const PASSWORD_REGEX =
  /^((?=.*[a-z])|(?=.*[A-Z]))(?=.*[0-9])(?=.*[!@#$%^&*()\-+=<>,.?/[\]{}~_|:"';\\`])(?=.{8,})/

const validate = (values) => {
  const noEmailError = !values[EMAIL_FIELD] && (
    <FormattedMessage message={messages.ENTER_AN_EMAIL} />
  )

  const invalidEmailError = values[EMAIL_FIELD] &&
    !EMAIL_REGEX.test(values[EMAIL_FIELD]) && (
      <FormattedMessage message={messages.ENTER_A_VALID_EMAIL} />
    )

  const noPasswordError = !values[PASSWORD_FIELD] && (
    <FormattedMessage message={messages.ENTER_A_PASSWORD} />
  )

  const noPasswordConfirmationError = !values[CONFIRM_PASSWORD_FIELD] && (
    <FormattedMessage message={messages.CONFIRM_PASSWORD_ERROR} />
  )

  const unmatchingPasswordError = values[PASSWORD_FIELD] &&
    values[CONFIRM_PASSWORD_FIELD] &&
    values[PASSWORD_FIELD] !== values[CONFIRM_PASSWORD_FIELD] && (
      <FormattedMessage message={messages.PASSWORD_DONT_MATCH} />
    )

  const passwordFormatError = values[PASSWORD_FIELD] &&
    !PASSWORD_REGEX.test(values[PASSWORD_FIELD]) && (
      <FormattedMessage message={messages.PASSWORD_FORMAT} />
    )

  return omitEmptyFields(
    {
      [EMAIL_FIELD]: noEmailError || invalidEmailError,
      [PASSWORD_FIELD]: noPasswordError || passwordFormatError,
      [CONFIRM_PASSWORD_FIELD]:
        noPasswordConfirmationError || unmatchingPasswordError,
    },
    { object: true }
  )
}

export default validate
