export default [
  {
    name: 'Afghanistan',
    countryCodeName: 'AF',
    callingCode: '+93',
  },
  {
    name: 'Albania',
    countryCodeName: 'AL',
    callingCode: '+355',
  },
  {
    name: 'Algeria',
    countryCodeName: 'DZ',
    callingCode: '+213',
  },
  {
    name: 'AmericanSamoa',
    countryCodeName: 'AS',
    callingCode: '+1 684',
  },
  {
    name: 'Andorra',
    countryCodeName: 'AD',
    callingCode: '+376',
  },
  {
    name: 'Angola',
    countryCodeName: 'AO',
    callingCode: '+244',
  },
  {
    name: 'Anguilla',
    countryCodeName: 'AI',
    callingCode: '+1 264',
  },
  {
    name: 'Antigua and Barbuda',
    countryCodeName: 'AG',
    callingCode: '+1268',
  },
  {
    name: 'Argentina',
    countryCodeName: 'AR',
    callingCode: '+54',
  },
  {
    name: 'Armenia',
    countryCodeName: 'AM',
    callingCode: '+374',
  },
  {
    name: 'Aruba',
    countryCodeName: 'AW',
    callingCode: '+297',
  },
  {
    name: 'Australia',
    countryCodeName: 'AU',
    callingCode: '+61',
  },
  {
    name: 'Austria',
    countryCodeName: 'AT',
    callingCode: '+43',
  },
  {
    name: 'Azerbaijan',
    countryCodeName: 'AZ',
    callingCode: '+994',
  },
  {
    name: 'Bahamas',
    countryCodeName: 'BS',
    callingCode: '+1 242',
  },
  {
    name: 'Bahrain',
    countryCodeName: 'BH',
    callingCode: '+973',
  },
  {
    name: 'Bangladesh',
    countryCodeName: 'BD',
    callingCode: '+880',
  },
  {
    name: 'Barbados',
    countryCodeName: 'BB',
    callingCode: '+1 246',
  },
  {
    name: 'Belarus',
    countryCodeName: 'BY',
    callingCode: '+375',
  },
  {
    name: 'Belgium',
    countryCodeName: 'BE',
    callingCode: '+32',
  },
  {
    name: 'Belize',
    countryCodeName: 'BZ',
    callingCode: '+501',
  },
  {
    name: 'Benin',
    countryCodeName: 'BJ',
    callingCode: '+229',
  },
  {
    name: 'Bermuda',
    countryCodeName: 'BM',
    callingCode: '+1 441',
  },
  {
    name: 'Bhutan',
    countryCodeName: 'BT',
    callingCode: '+975',
  },
  {
    name: 'Bosnia and Herzegovina',
    countryCodeName: 'BA',
    callingCode: '+387',
  },
  {
    name: 'Botswana',
    countryCodeName: 'BW',
    callingCode: '+267',
  },
  {
    name: 'Brazil',
    countryCodeName: 'BR',
    callingCode: '+55',
  },
  {
    name: 'British Indian Ocean Territory',
    countryCodeName: 'IO',
    callingCode: '+246',
  },
  {
    name: 'Bulgaria',
    countryCodeName: 'BG',
    callingCode: '+359',
  },
  {
    name: 'Burkina Faso',
    countryCodeName: 'BF',
    callingCode: '+226',
  },
  {
    name: 'Burundi',
    countryCodeName: 'BI',
    callingCode: '+257',
  },
  {
    name: 'Cambodia',
    countryCodeName: 'KH',
    callingCode: '+855',
  },
  {
    name: 'Cameroon',
    countryCodeName: 'CM',
    callingCode: '+237',
  },
  {
    name: 'Canada',
    countryCodeName: 'CA',
    callingCode: '+1',
  },
  {
    name: 'Cape Verde',
    countryCodeName: 'CV',
    callingCode: '+238',
  },
  {
    name: 'Cayman Islands',
    countryCodeName: 'KY',
    callingCode: '+ 345',
  },
  {
    name: 'Central African Republic',
    countryCodeName: 'CF',
    callingCode: '+236',
  },
  {
    name: 'Chad',
    countryCodeName: 'TD',
    callingCode: '+235',
  },
  {
    name: 'Chile',
    countryCodeName: 'CL',
    callingCode: '+56',
  },
  {
    name: 'China',
    countryCodeName: 'CN',
    callingCode: '+86',
  },
  {
    name: 'Christmas Island',
    countryCodeName: 'CX',
    callingCode: '+61 8 9164',
  },
  {
    name: 'Colombia',
    countryCodeName: 'CO',
    callingCode: '+57',
  },
  {
    name: 'Comoros',
    countryCodeName: 'KM',
    callingCode: '+269',
  },
  {
    name: 'Congo',
    countryCodeName: 'CG',
    callingCode: '+242',
  },
  {
    name: 'Cook Islands',
    countryCodeName: 'CK',
    callingCode: '+682',
  },
  {
    name: 'Costa Rica',
    countryCodeName: 'CR',
    callingCode: '+506',
  },
  {
    name: 'Croatia',
    countryCodeName: 'HR',
    callingCode: '+385',
  },
  {
    name: 'Cuba',
    countryCodeName: 'CU',
    callingCode: '+53',
  },
  {
    name: 'Curacao',
    countryCodeName: 'CW',
    callingCode: '+599',
  },
  {
    name: 'Cyprus',
    countryCodeName: 'CY',
    callingCode: '+357',
  },
  {
    name: 'Czech Republic',
    countryCodeName: 'CZ',
    callingCode: '+420',
  },
  {
    name: 'Denmark',
    countryCodeName: 'DK',
    callingCode: '+45',
  },
  {
    name: 'Djibouti',
    countryCodeName: 'DJ',
    callingCode: '+253',
  },
  {
    name: 'Dominica',
    countryCodeName: 'DM',
    callingCode: '+1 767',
  },
  {
    name: 'Dominican Republic',
    countryCodeName: 'DO',
    callingCode: '+1',
  },
  {
    name: 'Ecuador',
    countryCodeName: 'EC',
    callingCode: '+593',
  },
  {
    name: 'Egypt',
    countryCodeName: 'EG',
    callingCode: '+20',
  },
  {
    name: 'El Salvador',
    countryCodeName: 'SV',
    callingCode: '+503',
  },
  {
    name: 'Equatorial Guinea',
    countryCodeName: 'GQ',
    callingCode: '+240',
  },
  {
    name: 'Eritrea',
    countryCodeName: 'ER',
    callingCode: '+291',
  },
  {
    name: 'Estonia',
    countryCodeName: 'EE',
    callingCode: '+372',
  },
  {
    name: 'Ethiopia',
    countryCodeName: 'ET',
    callingCode: '+251',
  },
  {
    name: 'Faroe Islands',
    countryCodeName: 'FO',
    callingCode: '+298',
  },
  {
    name: 'Fiji',
    countryCodeName: 'FJ',
    callingCode: '+679',
  },
  {
    name: 'Finland',
    countryCodeName: 'FI',
    callingCode: '+358',
  },
  {
    name: 'France',
    countryCodeName: 'FR',
    callingCode: '+33',
  },
  {
    name: 'French Guiana',
    countryCodeName: 'GF',
    callingCode: '+594',
  },
  {
    name: 'French Polynesia',
    countryCodeName: 'PF',
    callingCode: '+689',
  },
  {
    name: 'Gabon',
    countryCodeName: 'GA',
    callingCode: '+241',
  },
  {
    name: 'Gambia',
    countryCodeName: 'GM',
    callingCode: '+220',
  },
  {
    name: 'Georgia',
    countryCodeName: 'GE',
    callingCode: '+995',
  },
  {
    name: 'Germany',
    countryCodeName: 'DE',
    callingCode: '+49',
  },
  {
    name: 'Ghana',
    countryCodeName: 'GH',
    callingCode: '+233',
  },
  {
    name: 'Gibraltar',
    countryCodeName: 'GI',
    callingCode: '+350',
  },
  {
    name: 'Greece',
    countryCodeName: 'GR',
    callingCode: '+30',
  },
  {
    name: 'Greenland',
    countryCodeName: 'GL',
    callingCode: '+299',
  },
  {
    name: 'Grenada',
    countryCodeName: 'GD',
    callingCode: '+1 473',
  },
  {
    name: 'Guadeloupe',
    countryCodeName: 'GP',
    callingCode: '+590',
  },
  {
    name: 'Guam',
    countryCodeName: 'GU',
    callingCode: '+1 671',
  },
  {
    name: 'Guatemala',
    countryCodeName: 'GT',
    callingCode: '+502',
  },
  {
    name: 'Guinea',
    countryCodeName: 'GN',
    callingCode: '+224',
  },
  {
    name: 'Guinea-Bissau',
    countryCodeName: 'GW',
    callingCode: '+245',
  },
  {
    name: 'Guyana',
    countryCodeName: 'GY',
    callingCode: '+595',
  },
  {
    name: 'Haiti',
    countryCodeName: 'HT',
    callingCode: '+509',
  },
  {
    name: 'Honduras',
    countryCodeName: 'HN',
    callingCode: '+504',
  },
  {
    name: 'Hungary',
    countryCodeName: 'HU',
    callingCode: '+36',
  },
  {
    name: 'Iceland',
    countryCodeName: 'IS',
    callingCode: '+354',
  },
  {
    name: 'India',
    countryCodeName: 'IN',
    callingCode: '+91',
  },
  {
    name: 'Indonesia',
    countryCodeName: 'ID',
    callingCode: '+62',
  },
  {
    name: 'Iraq',
    countryCodeName: 'IQ',
    callingCode: '+964',
  },
  {
    name: 'Ireland',
    countryCodeName: 'IE',
    callingCode: '+353',
  },
  {
    name: 'Israel',
    countryCodeName: 'IL',
    callingCode: '+972',
  },
  {
    name: 'Italy',
    countryCodeName: 'IT',
    callingCode: '+39',
  },
  {
    name: 'Jamaica',
    countryCodeName: 'JM',
    callingCode: '+1 876',
  },
  {
    name: 'Japan',
    countryCodeName: 'JP',
    callingCode: '+81',
  },
  {
    name: 'Jordan',
    countryCodeName: 'JO',
    callingCode: '+962',
  },
  {
    name: 'Kazakhstan',
    countryCodeName: 'KZ',
    callingCode: '+7 7',
  },
  {
    name: 'Kenya',
    countryCodeName: 'KE',
    callingCode: '+254',
  },
  {
    name: 'Kiribati',
    countryCodeName: 'KI',
    callingCode: '+686',
  },
  {
    name: 'Kuwait',
    countryCodeName: 'KW',
    callingCode: '+965',
  },
  {
    name: 'Kyrgyzstan',
    countryCodeName: 'KG',
    callingCode: '+996',
  },
  {
    name: 'Latvia',
    countryCodeName: 'LV',
    callingCode: '+371',
  },
  {
    name: 'Lebanon',
    countryCodeName: 'LB',
    callingCode: '+961',
  },
  {
    name: 'Lesotho',
    countryCodeName: 'LS',
    callingCode: '+266',
  },
  {
    name: 'Liberia',
    countryCodeName: 'LR',
    callingCode: '+231',
  },
  {
    name: 'Liechtenstein',
    countryCodeName: 'LI',
    callingCode: '+423',
  },
  {
    name: 'Lithuania',
    countryCodeName: 'LT',
    callingCode: '+370',
  },
  {
    name: 'Luxembourg',
    countryCodeName: 'LU',
    callingCode: '+352',
  },
  {
    name: 'Madagascar',
    countryCodeName: 'MG',
    callingCode: '+261',
  },
  {
    name: 'Malawi',
    countryCodeName: 'MW',
    callingCode: '+265',
  },
  {
    name: 'Malaysia',
    countryCodeName: 'MY',
    callingCode: '+60',
  },
  {
    name: 'Maldives',
    countryCodeName: 'MV',
    callingCode: '+960',
  },
  {
    name: 'Mali',
    countryCodeName: 'ML',
    callingCode: '+223',
  },
  {
    name: 'Malta',
    countryCodeName: 'MT',
    callingCode: '+356',
  },
  {
    name: 'Marshall Islands',
    countryCodeName: 'MH',
    callingCode: '+692',
  },
  {
    name: 'Martinique',
    countryCodeName: 'MQ',
    callingCode: '+596',
  },
  {
    name: 'Mauritania',
    countryCodeName: 'MR',
    callingCode: '+222',
  },
  {
    name: 'Mauritius',
    countryCodeName: 'MU',
    callingCode: '+230',
  },
  {
    name: 'Mayotte',
    countryCodeName: 'YT',
    callingCode: '+262',
  },
  {
    name: 'Mexico',
    countryCodeName: 'MX',
    callingCode: '+52',
  },
  {
    name: 'Monaco',
    countryCodeName: 'MC',
    callingCode: '+377',
  },
  {
    name: 'Mongolia',
    countryCodeName: 'MN',
    callingCode: '+976',
  },
  {
    name: 'Montenegro',
    countryCodeName: 'ME',
    callingCode: '+382',
  },
  {
    name: 'Montserrat',
    countryCodeName: 'MS',
    callingCode: '+1664',
  },
  {
    name: 'Morocco',
    countryCodeName: 'MA',
    callingCode: '+212',
  },
  {
    name: 'Myanmar',
    countryCodeName: 'MM',
    callingCode: '+95',
  },
  {
    name: 'Namibia',
    countryCodeName: 'NA',
    callingCode: '+264',
  },
  {
    name: 'Nauru',
    countryCodeName: 'NR',
    callingCode: '+674',
  },
  {
    name: 'Nepal',
    countryCodeName: 'NP',
    callingCode: '+977',
  },
  {
    name: 'Netherlands',
    countryCodeName: 'NL',
    callingCode: '+31',
  },
  {
    name: 'New Caledonia',
    countryCodeName: 'NC',
    callingCode: '+687',
  },
  {
    name: 'New Zealand',
    countryCodeName: 'NZ',
    callingCode: '+64',
  },
  {
    name: 'Nicaragua',
    countryCodeName: 'NI',
    callingCode: '+505',
  },
  {
    name: 'Niger',
    countryCodeName: 'NE',
    callingCode: '+227',
  },
  {
    name: 'Nigeria',
    countryCodeName: 'NG',
    callingCode: '+234',
  },
  {
    name: 'Niue',
    countryCodeName: 'NU',
    callingCode: '+683',
  },
  {
    name: 'Norfolk Island',
    countryCodeName: 'NF',
    callingCode: '+672',
  },
  {
    name: 'Northern Mariana Islands',
    countryCodeName: 'MP',
    callingCode: '+1 670',
  },
  {
    name: 'Norway',
    countryCodeName: 'NO',
    callingCode: '+47',
  },
  {
    name: 'Oman',
    countryCodeName: 'OM',
    callingCode: '+968',
  },
  {
    name: 'Pakistan',
    countryCodeName: 'PK',
    callingCode: '+92',
  },
  {
    name: 'Palau',
    countryCodeName: 'PW',
    callingCode: '+680',
  },
  {
    name: 'Panama',
    countryCodeName: 'PA',
    callingCode: '+507',
  },
  {
    name: 'Papua New Guinea',
    countryCodeName: 'PG',
    callingCode: '+675',
  },
  {
    name: 'Paraguay',
    countryCodeName: 'PY',
    callingCode: '+595',
  },
  {
    name: 'Peru',
    countryCodeName: 'PE',
    callingCode: '+51',
  },
  {
    name: 'Philippines',
    countryCodeName: 'PH',
    callingCode: '+63',
  },
  {
    name: 'Poland',
    countryCodeName: 'PL',
    callingCode: '+48',
  },
  {
    name: 'Portugal',
    countryCodeName: 'PT',
    callingCode: '+351',
  },
  {
    name: 'Puerto Rico',
    countryCodeName: 'PR',
    callingCode: '+1',
  },
  {
    name: 'Qatar',
    countryCodeName: 'QA',
    callingCode: '+974',
  },
  {
    name: 'Romania',
    countryCodeName: 'RO',
    callingCode: '+40',
  },
  {
    name: 'Rwanda',
    countryCodeName: 'RW',
    callingCode: '+250',
  },
  {
    name: 'Samoa',
    countryCodeName: 'WS',
    callingCode: '+685',
  },
  {
    name: 'San Marino',
    countryCodeName: 'SM',
    callingCode: '+378',
  },
  {
    name: 'Saudi Arabia',
    countryCodeName: 'SA',
    callingCode: '+966',
  },
  {
    name: 'Senegal',
    countryCodeName: 'SN',
    callingCode: '+221',
  },
  {
    name: 'Serbia',
    countryCodeName: 'RS',
    callingCode: '+381',
  },
  {
    name: 'Seychelles',
    countryCodeName: 'SC',
    callingCode: '+248',
  },
  {
    name: 'Sierra Leone',
    countryCodeName: 'SL',
    callingCode: '+232',
  },
  {
    name: 'Singapore',
    countryCodeName: 'SG',
    callingCode: '+65',
  },
  {
    name: 'Slovakia',
    countryCodeName: 'SK',
    callingCode: '+421',
  },
  {
    name: 'Slovenia',
    countryCodeName: 'SI',
    callingCode: '+386',
  },
  {
    name: 'Solomon Islands',
    countryCodeName: 'SB',
    callingCode: '+677',
  },
  {
    name: 'South Africa',
    countryCodeName: 'ZA',
    callingCode: '+27',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    countryCodeName: 'GS',
    callingCode: '+500',
  },
  {
    name: 'Spain',
    countryCodeName: 'ES',
    callingCode: '+34',
  },
  {
    name: 'Sri Lanka',
    countryCodeName: 'LK',
    callingCode: '+94',
  },
  {
    name: 'Sudan',
    countryCodeName: 'SD',
    callingCode: '+249',
  },
  {
    name: 'Suriname',
    countryCodeName: 'SR',
    callingCode: '+597',
  },
  {
    name: 'Swaziland',
    countryCodeName: 'SZ',
    callingCode: '+268',
  },
  {
    name: 'Sweden',
    countryCodeName: 'SE',
    callingCode: '+46',
  },
  {
    name: 'Switzerland',
    countryCodeName: 'CH',
    callingCode: '+41',
  },
  {
    name: 'Tajikistan',
    countryCodeName: 'TJ',
    callingCode: '+992',
  },
  {
    name: 'Thailand',
    countryCodeName: 'TH',
    callingCode: '+66',
  },
  {
    name: 'Togo',
    countryCodeName: 'TG',
    callingCode: '+228',
  },
  {
    name: 'Tokelau',
    countryCodeName: 'TK',
    callingCode: '+690',
  },
  {
    name: 'Tonga',
    countryCodeName: 'TO',
    callingCode: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    countryCodeName: 'TT',
    callingCode: '+1 868',
  },
  {
    name: 'Tunisia',
    countryCodeName: 'TN',
    callingCode: '+216',
  },
  {
    name: 'Turkey',
    countryCodeName: 'TR',
    callingCode: '+90',
  },
  {
    name: 'Turkmenistan',
    countryCodeName: 'TM',
    callingCode: '+993',
  },
  {
    name: 'Turks and Caicos Islands',
    countryCodeName: 'TC',
    callingCode: '+1 649',
  },
  {
    name: 'Tuvalu',
    countryCodeName: 'TV',
    callingCode: '+688',
  },
  {
    name: 'Uganda',
    countryCodeName: 'UG',
    callingCode: '+256',
  },
  {
    name: 'Ukraine',
    countryCodeName: 'UA',
    callingCode: '+380',
  },
  {
    name: 'United Arab Emirates',
    countryCodeName: 'AE',
    callingCode: '+971',
  },
  {
    name: 'United Kingdom',
    countryCodeName: 'GB',
    callingCode: '+44',
  },
  {
    name: 'United States',
    countryCodeName: 'US',
    callingCode: '+1',
  },
  {
    name: 'Uruguay',
    countryCodeName: 'UY',
    callingCode: '+598',
  },
  {
    name: 'Uzbekistan',
    countryCodeName: 'UZ',
    callingCode: '+998',
  },
  {
    name: 'Vanuatu',
    countryCodeName: 'VU',
    callingCode: '+678',
  },
  {
    name: 'Wallis and Futuna',
    countryCodeName: 'WF',
    callingCode: '+681',
  },
  {
    name: 'Yemen',
    countryCodeName: 'YE',
    callingCode: '+967',
  },
  {
    name: 'Zambia',
    countryCodeName: 'ZM',
    callingCode: '+260',
  },
  {
    name: 'Zimbabwe',
    countryCodeName: 'ZW',
    callingCode: '+263',
  },
  {
    name: 'Åland Islands',
    countryCodeName: 'AX',
    callingCode: '+358',
  },
  {
    name: 'Bolivia, Plurinational State of',
    countryCodeName: 'BO',
    callingCode: '+591',
  },
  {
    name: 'Brunei Darussalam',
    countryCodeName: 'BN',
    callingCode: '+673',
  },
  {
    name: 'Cocos (Keeling) Islands',
    countryCodeName: 'CC',
    callingCode: '+61 8 9162',
  },
  {
    name: 'Congo, The Democratic Republic of the',
    countryCodeName: 'CD',
    callingCode: '+243',
  },
  {
    name: "Cote d'Ivoire",
    countryCodeName: 'CI',
    callingCode: '+225',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    countryCodeName: 'FK',
    callingCode: '+500',
  },
  {
    name: 'Guernsey',
    countryCodeName: 'GG',
    callingCode: '+44',
  },
  {
    name: 'Holy See (Vatican City State)',
    countryCodeName: 'VA',
    callingCode: '+379',
  },
  {
    name: 'Hong Kong',
    countryCodeName: 'HK',
    callingCode: '+852',
  },
  {
    name: 'Iran, Islamic Republic of',
    countryCodeName: 'IR',
    callingCode: '+98',
  },
  {
    name: 'Isle of Man',
    countryCodeName: 'IM',
    callingCode: '+44',
  },
  {
    name: 'Jersey',
    countryCodeName: 'JE',
    callingCode: '+44',
  },
  {
    name: "Korea, Democratic People's Republic of",
    countryCodeName: 'KP',
    callingCode: '+850',
  },
  {
    name: 'Korea, Republic of',
    countryCodeName: 'KR',
    callingCode: '+82',
  },
  {
    name: "Lao People's Democratic Republic",
    countryCodeName: 'LA',
    callingCode: '+856',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    countryCodeName: 'LY',
    callingCode: '+218',
  },
  {
    name: 'Macao',
    countryCodeName: 'MO',
    callingCode: '+853',
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    countryCodeName: 'MK',
    callingCode: '+389',
  },
  {
    name: 'Micronesia, Federated States of',
    countryCodeName: 'FM',
    callingCode: '+691',
  },
  {
    name: 'Moldova, Republic of',
    countryCodeName: 'MD',
    callingCode: '+373',
  },
  {
    name: 'Mozambique',
    countryCodeName: 'MZ',
    callingCode: '+258',
  },
  {
    name: 'Palestinian Territory, Occupied',
    countryCodeName: 'PS',
    callingCode: '+970',
  },
  {
    name: 'Pitcairn',
    countryCodeName: 'PN',
    callingCode: '+872',
  },
  {
    name: 'Réunion',
    countryCodeName: 'RE',
    callingCode: '+262',
  },
  {
    name: 'Russia',
    countryCodeName: 'RU',
    callingCode: '+7',
  },
  {
    name: 'Saint Barthélemy',
    countryCodeName: 'BL',
    callingCode: '+590',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    countryCodeName: 'SH',
    callingCode: '+290',
  },
  {
    name: 'Saint Kitts and Nevis',
    countryCodeName: 'KN',
    callingCode: '+1 869',
  },
  {
    name: 'Saint Lucia',
    countryCodeName: 'LC',
    callingCode: '+1 758',
  },
  {
    name: 'Saint Martin',
    countryCodeName: 'MF',
    callingCode: '+590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    countryCodeName: 'PM',
    callingCode: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    countryCodeName: 'VC',
    callingCode: '+1 784',
  },
  {
    name: 'Sao Tome and Principe',
    countryCodeName: 'ST',
    callingCode: '+239',
  },
  {
    name: 'Somalia',
    countryCodeName: 'SO',
    callingCode: '+252',
  },
  {
    name: 'Svalbard and Jan Mayen',
    countryCodeName: 'SJ',
    callingCode: '+47',
  },
  {
    name: 'Syrian Arab Republic',
    countryCodeName: 'SY',
    callingCode: '+963',
  },
  {
    name: 'Taiwan, Province of China',
    countryCodeName: 'TW',
    callingCode: '+886',
  },
  {
    name: 'Tanzania, United Republic of',
    countryCodeName: 'TZ',
    callingCode: '+255',
  },
  {
    name: 'Timor-Leste',
    countryCodeName: 'TL',
    callingCode: '+670',
  },
  {
    name: 'Venezuela, Bolivarian Republic of',
    countryCodeName: 'VE',
    callingCode: '+58',
  },
  {
    name: 'Viet Nam',
    countryCodeName: 'VN',
    callingCode: '+84',
  },
  {
    name: 'Virgin Islands, British',
    countryCodeName: 'VG',
    callingCode: '+1 284',
  },
  {
    name: 'Virgin Islands, U.S.',
    countryCodeName: 'VI',
    callingCode: '+1 340',
  },
]
