import * as React from 'react'
import SelectField from 'mz-ui-kit/form/SelectField'
import EmptySearchResults from 'mz-ui-kit/form/SelectField/EmptySearchResults'
import { DRIVERS_ENDPOINT } from 'app/constants/endpoints'
import Client from 'mz-sdk/client'

function renderDriverToString({ first_name, last_name }) {
  return `${first_name || ''} ${last_name || ''}` // eslint-disable-line
}

function renderEmpty(query) {
  if (!query || !query.trim()) return null
  return (
    <EmptySearchResults
      query={query}
      emptyResultsMessage={`No drivers found by request "${query}"`}
    />
  )
}

function requestPlaces(query) {
  if (!query || !query.trim()) return []
  return Client.get(DRIVERS_ENDPOINT, { query: { search: query } }).then(
    (response) => response.results
  )
}

function DriverField(props) {
  return (
    <SelectField
      emptyValue={null}
      label="Driver"
      placeholder="Type to search drivers"
      {...props}
      searchableOptions={{
        ...props.searchableOptions,
        requestOptions: requestPlaces,
        renderEmpty,
      }}
      renderValue={renderDriverToString}
      renderOption={renderDriverToString}
    />
  )
}

export default DriverField
