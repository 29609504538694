import React, { useState, useEffect, useReducer } from 'react'
import { compose } from 'redux'
import { useSelector, useDispatch } from 'react-redux'
import { reduxForm, reset } from 'redux-form'
import * as selectors from 'app/selectors'
import { TripsList } from './TripsList'
import PastTripsList from './PastTripsList'
import Header from 'app/components/commons/Header'
import ShowMoreButton from 'app/components/commons/ShowMoreButton'
import { Tabs, Tab, TabContent, LoadingWrapper } from './elements'
import LoadingSpinner from 'mz-ui-kit/core/LoadingSpinner'
import Filters from '../Filters'
import TripsModals from '../TripsModals'
import * as ACTIONS from 'app/constants/actions'
import { getTrips, getMoreTrips } from 'app/actions/trips'
import { getDrivers } from 'app/actions/drivers'
import { getVehicles } from 'app/actions/vehicles'
import { reducer, initialState } from 'app/components/commons/Page/reducer'

const FORM_NAME = 'trip'

const Trips = () => {
  const dispatch = useDispatch()
  const [useActiveTab, setActiveTab] = useState(0)

  const [useReducerState, dispatchReducer] = useReducer(reducer, initialState)
  const { isLoading, isGettingMore, isLightboxFormOpen } = useReducerState

  const trips = useSelector(selectors.getTrips)
  const nextTripsUrl = useSelector(selectors.getNextTrips)
  const nextPastTripsUrl = useSelector(selectors.getNextPastTrips)
  const pastTrips = useSelector(selectors.getPastTrips)
  const tripStatuses = useSelector(selectors.getTripStatuses)

  const toggleLoading = () => dispatchReducer({ type: ACTIONS.TOGGLE_LOADING })

  useEffect(() => {
    toggleLoading()

    Promise.all([
      dispatch(getTrips()),
      dispatch(getDrivers()),
      dispatch(getVehicles()),
    ]).then(() => toggleLoading())
  }, [])

  const toggleTab = (index) => () => setActiveTab(index)

  const loadMoreTrips = (next, name) => async () => {
    dispatchReducer({ type: ACTIONS.TOGGLE_GETTING_MORE })
    try {
      await dispatch(getMoreTrips(next, name))
    } finally {
      dispatchReducer({ type: ACTIONS.TOGGLE_GETTING_MORE })
    }
  }

  const onToggleLightBox = () => {
    dispatchReducer({ type: ACTIONS.TOGGLE_LIGHTBOX })
    dispatch(reset(FORM_NAME))
  }

  return (
    <article>
      <Header title="Trips" loading={isLoading} />
      <Filters />
      <Tabs>
        <Tab $active={useActiveTab === 0} onClick={toggleTab(0)}>
          Upcoming Trips
          <LoadingWrapper>{isLoading && <LoadingSpinner />}</LoadingWrapper>
        </Tab>
        <Tab $active={useActiveTab === 1} onClick={toggleTab(1)}>
          Past Trips
          <LoadingWrapper>{isLoading && <LoadingSpinner />}</LoadingWrapper>
        </Tab>
      </Tabs>
      <TabContent $active={useActiveTab === 0}>
        <TripsList trips={trips} tripStatuses={tripStatuses} />
        {nextTripsUrl && (
          <ShowMoreButton
            name="trips"
            nextUrl={nextTripsUrl}
            loadFn={loadMoreTrips}
            loading={isGettingMore}
          />
        )}
      </TabContent>
      <TabContent $active={useActiveTab === 1}>
        <PastTripsList pastTrips={pastTrips} tripStatuses={tripStatuses} />
        {nextPastTripsUrl && (
          <ShowMoreButton
            name="pastTrips"
            nextUrl={nextPastTripsUrl}
            loadFn={loadMoreTrips}
            loading={isGettingMore}
          />
        )}
      </TabContent>
      <TripsModals
        isLightboxFormOpen={isLightboxFormOpen}
        onCloseLightbox={onToggleLightBox}
      />
    </article>
  )
}

export default compose(
  reduxForm({ form: FORM_NAME, enableReinitialize: true })
)(Trips)
