import { styled } from 'mz-ui-kit'
import { Link } from 'react-router-dom'

export const Container = styled('nav', ({ $palette }) => ({
  width: '200px',
  display: 'flex',
  padding: '20px 0',
  flexDirection: 'column',
  background: `${$palette('GRAY').lighten(5)}`,
}))

export const Item = styled(Link, ({ $active, $palette }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  padding: '10px 80px 10px 40px',
  margin: '10px 0',
  borderLeft: $active
    ? `6px solid ${$palette('PRIMARY')}`
    : '8px solid transparent',
  color: $active ? `${$palette('PRIMARY')}` : `${$palette('TEXT')}`,
}))
