import normalizeErrors from 'mz-sdk/utils/normalizeErrors'

export function errorObjectToString(msg) {
  const normError = normalizeErrors(msg)
  return normError.errors[0] || ''
}

export function normalizeNames(value) {
  if (!value) return value

  const sanitized = value.replace(/[^a-zA-Z\s]+$/gi, '')
  return sanitized.trimStart()
}

export function normalizeReservation(value) {
  if (!value) return value

  const sanitized = value.replace(/[^a-zA-Z0-9]+$/gi, '')
  return sanitized.trimStart()
}

export const normalizeChars = (maxLength) => (value) => {
  if (!value) return value
  if (value.length > maxLength) {
    return value.substr(0, maxLength)
  }
  return value.replace(/[\d]/g, '').toUpperCase()
}

export const normalizeFlightNumber = (value) => {
  if (!value) return value
  if (value.length > 4) {
    return value.substr(0, 4)
  }
  return value.replace(/[^\d]/g, '').toUpperCase()
}
