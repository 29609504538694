/* eslint-disable camelcase */
import React from 'react'
import moment from 'moment'
import Lightbox from 'mz-ui-kit/core/Lightbox'
import LoadingSpinner from 'mz-ui-kit/core/LoadingSpinner'
import AssignForm from './AssignForm'
import { isExpired, isTripCancelled, isHourlyTrip } from 'app/utils/trips'
import VerificationSection from './VerificationSection'
import { FormattedMessage } from 'mz-intl'
import messages from './messages.intl'
import { useDataLoader } from 'mz-ui-kit/logic/DataLoader'
import { getTrip } from 'app/actions/trips'

import {
  Container,
  Heading,
  Section,
  Bolder,
  ConfirmationNumber,
  MinFrame,
  ErrorMessage,
} from './elements'

const TripDetails = ({ hash, close, onChangeTrip, showed }) => {
  const [state] = useDataLoader(() => getTrip(hash), [hash])
  const { data: trip, loading, error } = state

  const isHourly = isHourlyTrip(trip?.service_type)

  const render = () => (
    <MinFrame>
      {loading && <LoadingSpinner />}

      {!loading && !error && trip && renderDetails()}

      {error && <FormattedMessage message={error} tagName={ErrorMessage} />}
    </MinFrame>
  )

  const renderDetails = () => {
    const {
      num_passengers,
      departure_datetime,
      passenger_information: { first_name, last_name, email, phone },
      start_location,
      end_location,
      flight_info,
      airport_terminal,
      user_external_id,
      customer_notes,
      cruise_lane,
      ship_name,
      meet_and_greet_chosen,
      rebooked_reservation_user_id: rebooked,
      original_reservation_user_id: original,
      rebooked_reservation_hashed_id: rebooked_hash,
      original_reservation_hashed_id: original_hash,
    } = trip

    // const totalPrice = trip.total_price_provider_currency || trip.total_price
    // const stripeCut = trip.stripe_cut_provider_currency || trip.stripe_cut
    // const mozioCut = trip.mozio_cut_provider_currency || trip.mozio_cut
    const providerCut = trip.provider_cut_provider_currency || trip.provider_cut
    const isReservationCancelled = isTripCancelled(trip)

    return (
      <Container>
        {!isExpired(trip) && !isReservationCancelled && !trip.completed && (
          <AssignForm close={close} trip={trip} />
        )}
        <Heading>
          <FormattedMessage message={messages.TRIP_SYNOPSIS} />
        </Heading>
        <Section>
          <p>
            {moment
              .parseZone(departure_datetime)
              .format('dddd MMMM D YYYY HH:mm')}
          </p>
          <p>
            <Bolder>
              <FormattedMessage message={messages.PICKUP_LABEL} />
            </Bolder>
            {start_location.address}
          </p>
          {meet_and_greet_chosen && (
            <p>
              <FormattedMessage
                message={messages.MEET_AND_GREET_SERVICE_REQUESTED}
              />
            </p>
          )}
          <VerificationSection trip={trip} />
        </Section>
        <Heading>
          <FormattedMessage message={messages.PASSENGER_DETAILS} />
        </Heading>
        <Section>
          <p>
            <Bolder>
              <FormattedMessage message={messages.NAME_LABEL} />
            </Bolder>
            {`${first_name} ${last_name}`}
          </p>
          <p>
            <Bolder>
              <FormattedMessage message={messages.NUMBER_OF_PASSENGERS_LABEL} />
            </Bolder>
            {num_passengers}
          </p>
          <p>
            <Bolder>
              <FormattedMessage message={messages.PHONE_NUMBER_LABEL} />
            </Bolder>
            {phone}
          </p>
          <p>
            <Bolder>
              <FormattedMessage message={messages.EMAIL_LABEL} />
            </Bolder>
            {email}
          </p>
        </Section>
        {flight_info && (
          <Section>
            <p>
              <Bolder>
                <FormattedMessage message={messages.AIRPORT_LABEL} />
              </Bolder>
              {flight_info.iata_code}
            </p>
            <p>
              <Bolder>
                <FormattedMessage message={messages.FLIGHT_NUMBER_LABEL} />
              </Bolder>
              {flight_info.flight_code}
            </p>
            {airport_terminal && (
              <p>
                <Bolder>
                  <FormattedMessage message={messages.AIRPORT_TERMINAL_LABEL} />
                </Bolder>
                {airport_terminal}
              </p>
            )}
          </Section>
        )}
        {(cruise_lane || ship_name) && (
          <Section>
            {cruise_lane && (
              <p>
                <Bolder>
                  <FormattedMessage message={messages.CRUISE_LANE_LABEL} />
                </Bolder>
                {cruise_lane}
              </p>
            )}
            {ship_name && (
              <p>
                <Bolder>
                  <FormattedMessage message={messages.SHIP_NAME_LABEL} />
                </Bolder>
                {ship_name}
              </p>
            )}
          </Section>
        )}
        <Section>
          <Section>
            <p>
              <Bolder>
                <FormattedMessage
                  message={messages.CONFIRMATION_NUMBER_LABEL}
                />
              </Bolder>
              {user_external_id}
              {original && (
                <ConfirmationNumber onClick={onChangeTrip(original_hash)}>
                  <FormattedMessage
                    message={messages.CANCELED_CONFIRAMTION_NUMBER}
                    values={{
                      number: original,
                    }}
                  />
                </ConfirmationNumber>
              )}
              {rebooked && (
                <ConfirmationNumber onClick={onChangeTrip(rebooked_hash)}>
                  <FormattedMessage
                    message={messages.NEW_CONFIRMATION_NUMBER}
                    values={{
                      number: rebooked,
                    }}
                  />
                </ConfirmationNumber>
              )}
            </p>
            <p>
              <Bolder>
                <FormattedMessage
                  message={messages.SPECIAL_INSTRUCTIONS_LABEL}
                />
              </Bolder>
              {customer_notes}
            </p>
          </Section>
        </Section>
        <Heading>
          <FormattedMessage message={messages.TRIP_DETAILS} />
        </Heading>
        <Section>
          <p>
            <Bolder>
              <FormattedMessage message={messages.PICKUP_LABEL} />
            </Bolder>
            {start_location.address}
          </p>
          <p>
            <Bolder>
              <FormattedMessage message={messages.ON_LABEL} />
            </Bolder>
            {moment.parseZone(departure_datetime).format('dddd MMMM D YYYY')}
          </p>
          <p>
            <Bolder>
              <FormattedMessage message={messages.AT_LABEL} />
            </Bolder>
            {moment.parseZone(departure_datetime).format('HH:mm')}
          </p>
          {end_location.address && (
            <p>
              <Bolder>
                <FormattedMessage message={messages.DROPOFF_AT} />
              </Bolder>
              {end_location.address}
            </p>
          )}
          {isHourly && (
            <>
              <p>
                <Bolder>
                  <FormattedMessage message={messages.HOURLY_DURATION} />:
                </Bolder>
                {trip.hourly_details?.hours_requested}
              </p>
              <p>
                <Bolder>
                  <FormattedMessage message={messages.TOTAL_KM_INCLUDED} />:
                </Bolder>
                {trip.hourly_details?.total_included_kilometers}
              </p>
            </>
          )}
        </Section>
        {!trip.external && (
          <>
            <Heading>
              <FormattedMessage message={messages.PAYMENT_DETAILS} />
            </Heading>
            <Section>
              <p>
                <Bolder>
                  <FormattedMessage message={messages.YOU_WILL_BE_PAID} />
                </Bolder>
                {providerCut.display}
              </p>
            </Section>
          </>
        )}
      </Container>
    )
  }

  return <Lightbox showed={showed} onClose={close} render={render} />
}

export default TripDetails
