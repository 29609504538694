/**
 * @param {File} csv_file
 */
const useImportCSV = (csvFile) => {
  return new Promise((resolve, reject) => {
    const worker = new Worker('/worker.js')

    worker.postMessage(csvFile)

    worker.onmessage = (response) => {
      resolve(response)
      worker.terminate()
    }

    worker.onerror = (err) => {
      reject(err)
    }
  })
}

export { useImportCSV }
