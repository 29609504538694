import { styled } from 'mz-ui-kit'

export const Container = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
  marginTop: '20px',
  alignItems: 'center',
  minHeight: '30px',
})

export const Link = styled('a', ({ $palette, $disabled }) => ({
  pointerEvents: $disabled ? 'none' : 'inherit',
  color: `${$palette('primary')}`,
  cursor: $disabled ? 'default' : 'pointer',
  opacity: $disabled ? 0.6 : 1,
  textDecoration: 'none',
  marginRight: '5px',
  ':hover': $disabled
    ? {}
    : {
        color: `${$palette('primary').darken(10)}`,
      },
}))

export const Message = styled('span', ({ $palette }) => ({
  color: `${$palette('text')}`,
  marginRight: '5px',
}))
