/* eslint-disable global-require */
import baseConfig from './base'

let overrideConfig = {}

if (typeof window !== 'undefined' && window.APP_CONFIG) {
  overrideConfig = window.APP_CONFIG || {}
} else if (process.env.CONFIG_ENV === 'production') {
  overrideConfig = require('./prod').default
} else if (process.env.CONFIG_ENV === 'development') {
  overrideConfig = require('./dev').default
} else if (process.env.CONFIG_ENV === 'staging') {
  overrideConfig = require('./staging').default
} else if (process.env.CONFIG_ENV === 'testing') {
  overrideConfig = require('./testing').default
} else if (process.env.CONFIG_ENV === 'test') {
  overrideConfig = require('./test').default
} else {
  throw new Error(`Unknown environment "${process.env.CONFIG_ENV || ''}"`)
}

export default {
  ...baseConfig,
  ...overrideConfig,
}
