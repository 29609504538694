export const statusMap = {
  not_started: {
    name: 'Not Started',
    color: '#999',
  },
  driver_departed_to_pickup: {
    name: 'Driving to pickup',
    color: '#63A3EF',
  },
  driver_arrived_at_pickup: {
    name: 'Arrived at pickup',
    color: '#6057C8',
  },
  driver_departed_to_dropoff: {
    name: 'Ride in progress',
    color: '#AE7AC2',
  },
  driver_arrived_at_dropoff: {
    name: 'Completed',
    color: '#4EB261',
  },
  no_show_reported_by_driver: {
    name: 'No-Show',
    color: '#EBB500',
  },
}
