import * as React from 'react'
import { FormattedMessage } from 'mz-intl'
import { Input, ErrorMessage, ButtonWrapper } from './elements'
import Button from 'mz-ui-kit/core/Button'

class LoginForm extends React.Component {
  onSubmit = (values) => {
    return this.props.login(values)
  }

  render() {
    const { submitting, handleSubmit, errorMessage } = this.props

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Input
          name="email"
          component="input"
          type="text"
          placeholder="Email"
          required
        />
        <Input
          name="password"
          component="input"
          type="password"
          placeholder="Password"
          required
        />
        <ButtonWrapper>
          <Button
            type="submit"
            color="yellow"
            size="medium"
            loading={submitting ? 'true' : undefined}
            disabled={submitting}
          >
            {submitting ? 'Submitting...' : 'Login'}
          </Button>
        </ButtonWrapper>
        {errorMessage && (
          <FormattedMessage message={errorMessage} tagName={ErrorMessage} />
        )}
        <ButtonWrapper>
          <Button
            $as="a"
            color="white"
            appearance="link"
            href="https://www.mozio.com/en-us/user/forgot-password"
            target="__blank"
          >
            Forgot password?
          </Button>
        </ButtonWrapper>
      </form>
    )
  }
}

export default LoginForm
