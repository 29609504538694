import React from 'react'
import Button from 'mz-ui-kit/core/Button'
import { LoadMoreWrapper } from './elements'

const ShowMore = ({ nextUrl, loadFn, name, loading }) => (
  <LoadMoreWrapper>
    <Button loading={loading} onClick={loadFn(nextUrl, name)}>
      Show More
    </Button>
  </LoadMoreWrapper>
)

export default ShowMore
