import { styled } from 'mz-ui-kit'

export const Form = styled('form', () => ({
  fontWeight: 'normal',
  width: '400px',
  maxWidth: '100%',
}))

export const ButtonsWrapper = styled('div', ({ $palette }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'flex-end',
  padding: '20px 40px',
  borderTop: `1px solid ${$palette('GRAY')}`,
}))

export const ErrorMessage = styled('div', ({ $palette }) => ({
  padding: '40px',
  color: `${$palette('RED')}`,
}))

export const CloseButton = styled('button', ({ $small, $palette }) => ({
  cursor: 'pointer',
  border: 'none',
  padding: 'none',
  margin: $small ? '0' : '20px 20px 0 0',
  float: 'right',
  color: `${$palette('TEXT')}`,
  background: 'none',
}))
