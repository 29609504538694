import { styled } from 'mz-ui-kit'

export const Spacer = styled('div', ({ $big }) => ({
  width: '100%',
  height: $big ? '40px' : '20px',
}))

export const FormWrapper = styled('form', {
  display: 'flex',
  flexFlow: 'column nowrap',
  width: '100%',
  margin: 0,
  padding: 0,
})
