import React from 'react'
import LightboxForm from 'app/components/commons/LightboxForm'
import withUploadTabs from 'app/components/commons/LightboxForm/withUploadTabs'
import SingleCreate from './SingleCreate'
import BulkCreate from 'app/components/commons/Page/Form/BulkCreate'
import { TAB_MODE } from 'app/constants/values'
import LightboxFooter from 'app/components/commons/LightboxForm/LightboxFooter'
import Form from 'mz-ui-kit/form/Form'

const LightboxWithTabs = withUploadTabs(LightboxForm)

export default function VehiclesForm(props) {
  const {
    onSubmit,
    onCloseLightbox,
    onTabChange,
    vehicle,
    manufacturers,
    manufacturer,
    models,
    colors,
    years,
    setModel,
    isShowed,
    mode,
    ...rest
  } = props
  const renderTabs = (tabMode) => {
    const label =
      mode === 'bulk' ? 'Load File' : vehicle ? 'Update Vehicle' : 'Add Vehicle'

    return (
      <Form
        onSubmit={onSubmit}
        initValue={vehicle}
        render={({ submit, meta, value }, nest) => (
          <>
            {tabMode === TAB_MODE.BULK ? (
              <BulkCreate
                nest={nest}
                title={'Upload a .cvs file with multiple vehicles.'}
                description={
                  <span>
                    Please use{' '}
                    <a href="/vehicles.template.csv" download>
                      this template
                    </a>{' '}
                    so the correct information is being displayed.
                  </span>
                }
              />
            ) : (
              <SingleCreate nest={nest} value={value} />
            )}
            <LightboxFooter
              {...rest}
              meta={meta}
              onSubmit={submit}
              submissionLabel={label}
              onCloseLightbox={onCloseLightbox}
            />
          </>
        )}
      />
    )
  }

  const Lightbox = vehicle ? LightboxForm : LightboxWithTabs

  return <Lightbox {...props} render={renderTabs} />
}
