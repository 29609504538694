import React, { Fragment, Component } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import is from 'mz-utils/is'
import verify from 'mz-sdk/services/reservations/verify'
import SignupForm from 'app/components/verification/SignupForm'
import Navbar from 'app/components/commons/Navbar'
import CheckIcon from 'mz-icons/check'
import Alert from 'mz-ui-kit/core/Alert'
import Button from 'mz-ui-kit/core/Button'
import LoadingSpinner from 'mz-ui-kit/core/LoadingSpinner'
import { FormattedMessage } from 'mz-intl'
import messages from '../messages.intl'
import queryStringToObject from 'mz-utils/queryStringToObject'
import { getAuthorizationToken } from 'mz-sdk/client'
import {
  Container,
  SuccessWrapper,
  Section,
  Heading,
  Text,
  FormWrapper,
  DecoratedLink,
} from './elements'

// Constants
/*
 * User will be able to verify without and account
 * until this date
 */
const VERIFY_UNTIL_DATE = '2030-01-01'

class VerificationPage extends Component {
  state = {
    loading: true,
    error: false,
    errorMessage: null,
  }

  constructor(props) {
    super(props)
    this.canVerify =
      getAuthorizationToken() || moment().isBefore(VERIFY_UNTIL_DATE)
  }

  async componentDidMount() {
    const { hashedId, returnHashedId, valid } = this.readQueryParams()

    if (valid && this.canVerify) {
      try {
        await verify(hashedId)
        if (returnHashedId) await verify(returnHashedId)
        this.setState({ loading: false })
      } catch (error) {
        if (error.response.code === 'res_already_verified') {
          this.props.history.push('/login')
        }

        this.setState({
          loading: false,
          error: true,
          errorMessage: is.string(error.message) && error.message,
        })
      }
    } else {
      const errorMessage = this.canVerify
        ? messages.INVALID_URL
        : messages.NEED_ACCOUNT_TO_VERIFY

      this.setState({
        error: true,
        loading: false,
        errorMessage,
      })
    }
  }

  readQueryParams = () => {
    const {
      location: { search },
    } = this.props
    const queryObject = queryStringToObject(search)
    const {
      provider_uid,
      token,
      hashed_id: hashedId,
      return_hashed_id: returnHashedId,
    } = queryObject

    return {
      provider_uid,
      token,
      hashedId,
      returnHashedId,
      // eslint-disable-next-line camelcase
      valid: provider_uid && token && hashedId,
    }
  }

  renderSignUpForm = () => {
    const { token, provider_uid: providerUid } = this.readQueryParams()

    return (
      <Fragment>
        <Section>
          <FormWrapper>
            <SignupForm
              token={token}
              providerUid={providerUid}
              onSuccess={this.onSignup}
            />
          </FormWrapper>
        </Section>
        <Section>
          <Section $small>
            <Text $color="TEXT">
              <FormattedMessage message={messages.ALREADY_HAVE_ACCOUNT} />
            </Text>
          </Section>
          <DecoratedLink to="/login">
            <Text $bolder>
              <FormattedMessage message={messages.LOGIN} />
            </Text>
          </DecoratedLink>
        </Section>
      </Fragment>
    )
  }

  render() {
    const isAuthenticated = getAuthorizationToken()
    const { valid } = this.readQueryParams()
    const { loading, error, errorMessage } = this.state

    if (loading)
      return (
        <Fragment>
          <Navbar />
          <Container>
            <LoadingSpinner />
          </Container>
        </Fragment>
      )

    if (error)
      return (
        <Fragment>
          <Navbar />
          <Container>
            <Section>
              <Alert color="RED">
                <FormattedMessage
                  message={errorMessage || messages.DEFAULT_ERROR_MESSAGE}
                />
              </Alert>
            </Section>
            {!isAuthenticated && valid && this.renderSignUpForm()}
            {(isAuthenticated || !valid) && (
              <Button $as="a" href="/trips">
                <FormattedMessage message={messages.TAKE_ME_TO} />
                &nbsp;
                {isAuthenticated ? (
                  <FormattedMessage message={messages.DASHBOARD} />
                ) : (
                  !valid && <FormattedMessage message={messages.LOGIN_PAGE} />
                )}
              </Button>
            )}
          </Container>
        </Fragment>
      )

    return (
      <Fragment>
        <Navbar />
        <Container>
          <SuccessWrapper>
            <CheckIcon />
          </SuccessWrapper>
          <Section>
            <Heading $bolder>
              {!isAuthenticated && (
                <FormattedMessage
                  message={messages.CONTINUE_TO_CREATE_ACCOUNT}
                />
              )}
            </Heading>
          </Section>
          {isAuthenticated && (
            <Section>
              <Button $as="a" href="/trips">
                <FormattedMessage message={messages.TAKE_ME_TO} />
                &nbsp;
                <FormattedMessage message={messages.DASHBOARD} />
              </Button>
            </Section>
          )}
          {!isAuthenticated && this.renderSignUpForm()}
        </Container>
      </Fragment>
    )
  }
}

export default withRouter(VerificationPage)
