// @flow
import React from 'react'
import TextField from 'mz-ui-kit/form/TextField/field'
import SelectField from 'mz-ui-kit/form/SelectField/field'
import Row from 'mz-ui-kit/core/Row'
import { Content } from './elements'
import { makesAndModels, years, colors } from 'app/constants/vehicles'

type Props = {
  nest: Function,
  value: Object,
}

export default function SingleCreate({ nest, value }: Props) {
  const manufacturers = Object.keys(makesAndModels)
  return (
    <Content>
      <SelectField
        {...nest('manufacturer')}
        searchable
        searchableOptions={{ allowSelectUnknown: true }}
        label="Make"
        placeholder="Make"
        options={{ options: manufacturers }}
        required
      />
      <Row>
        <SelectField
          {...nest('model')}
          key={value.manufacturer}
          searchable
          searchableOptions={{
            allowSelectUnknown: true,
          }}
          label="Model"
          placeholder="Model"
          options={{ options: makesAndModels[value.manufacturer] }}
          required
        />
        <SelectField
          searchable
          {...nest('year')}
          label="Year"
          placeholder="Year"
          options={{ options: years }}
          required
        />
      </Row>
      <TextField
        {...nest('plate')}
        label="License Plate"
        placeholder="License Plate"
        required
      />
      <SelectField
        {...nest('color')}
        searchable
        searchableOptions={{ allowSelectUnknown: true }}
        label="Color"
        placeholder="color"
        options={{ options: colors }}
      />
    </Content>
  )
}
