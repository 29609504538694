import React, { Component } from 'react'
import Passenger from 'mz-icons/passenger'
import NoResults from 'app/components/commons/NoResults'
import TripDetails from 'app/components/trips/TripDetails'
import Check from 'mz-icons/check'
import {
  getPickupTime,
  getTripDate,
  getVehicleType,
  getTripType,
} from 'app/utils/trips'
import { Table, Body, Headers, Row, Cell } from 'app/components/table/elements'
import { Status } from './elements'
import { TrackingLink } from './TrackingLink'

class PastTripsList extends Component {
  state = {
    showDetails: false,
    hash: null,
  }

  openDetails = (hash) => () => {
    this.setState({ showDetails: true, hash })
  }

  closeDetails = () => this.setState({ showDetails: false })

  render() {
    const { pastTrips } = this.props
    const { showDetails, hash } = this.state

    const getTripStatus = (hashedId) => {
      const status = this.props?.tripStatuses?.find(
        (tripStatus) => tripStatus.tracking_id === hashedId
      )

      return (
        status || {
          formattedStatus: { name: 'Not Started', color: 'DARKGRAY' },
        }
      )
    }

    if (!pastTrips.length) return <NoResults />
    return (
      <article>
        {showDetails && (
          <TripDetails
            hash={hash}
            showed={showDetails}
            close={this.closeDetails}
            onChangeTrip={this.openDetails}
          />
        )}
        <Table>
          <Body>
            <Headers>
              <Cell> Pickup Time </Cell>
              <Cell> Date </Cell>
              <Cell> Confirmation No </Cell>
              <Cell>
                <Passenger />
              </Cell>
              <Cell> Type </Cell>
              <Cell> Vehicle Type </Cell>
              <Cell> Start Address </Cell>
              <Cell> End Address </Cell>
              <Cell> Status </Cell>
              <Cell> Driver </Cell>
              <Cell> Vehicle </Cell>
              <Cell> Tracking </Cell>
              <Cell> External </Cell>
            </Headers>
            {pastTrips.map((trip, index) => {
              const { formattedStatus } = getTripStatus(trip.hashed_id) || {
                formattedStatus: { status: '', color: '' },
              }

              return (
                <Row
                  key={index}
                  $clickable
                  onClick={this.openDetails(trip.hashed_id)}
                >
                  <Cell> {`${getPickupTime(trip)}`}</Cell>
                  <Cell> {`${getTripDate(trip)}`} </Cell>
                  <Cell> {trip.user_external_id} </Cell>
                  <Cell> {trip.num_passengers} </Cell>
                  <Cell> {getTripType(trip)} </Cell>
                  <Cell> {`${getVehicleType(trip)}`} </Cell>
                  <Cell>
                    {' '}
                    {trip.start_location && trip.start_location.address}{' '}
                  </Cell>
                  <Cell>
                    {' '}
                    {trip.end_location && trip.end_location.address}{' '}
                  </Cell>
                  <Cell>
                    <Status $background={`${formattedStatus?.color}`}>
                      {`${formattedStatus?.name}`}
                    </Status>
                  </Cell>
                  <Cell>
                    {' '}
                    {trip.driver
                      ? `${trip.driver.first_name} ${trip.driver.last_name}`
                      : ''}{' '}
                  </Cell>
                  <Cell>
                    {trip.vehicle
                      ? `${trip.vehicle.model} - ${
                          trip.vehicle.plate || 'Unknow plate'
                        }`
                      : ''}
                  </Cell>
                  {trip.driver_tracking_link && (
                    <TrackingLink
                      trackingLink={trip.driver_tracking_link}
                      driverEmail={trip.driver?.email}
                      driverPhone={trip.driver?.phone_number}
                    />
                  )}
                  <Cell> {trip.external && <Check />} </Cell>
                </Row>
              )
            })}
          </Body>
        </Table>
      </article>
    )
  }
}

export default PastTripsList
