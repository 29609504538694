import { styled } from 'mz-ui-kit'

export const Button = styled('button', ({ $palette }) => ({
  padding: '0',
  border: '0',
  background: 'none',
  fontSize: '14px',
  cursor: 'pointer',
  outline: 'none',
  color: `${$palette('PRIMARY')}`,
}))
