import { styled } from 'mz-ui-kit'

export const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
})

export const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column nowrap',
  padding: '40px',
})

export const Title = styled('h1', ({ $palette }) => ({
  fontSize: '16px',
  margin: '10px 0 10px 0',
  textAlign: 'center',
  color: `${$palette('YELLOW')}`,
}))

export const LogoWrapper = styled('div', ({ $palette }) => ({
  color: `${$palette('primary')}`,
  textAlign: 'center',
  fontSize: '30px',
}))

export const FormWrapper = styled('div', {
  minWidth: '335px',
  maxWidth: '80%',
})
