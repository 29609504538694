// @flow
import React from 'react'
import type { Node } from 'react'
import Lightbox from 'mz-ui-kit/core/Lightbox'

type Props = {
  isShowed: boolean,
  render: () => Node,
  onCloseLightbox: () => void,
}

const LightboxForm = ({ isShowed, render, onCloseLightbox }: Props) => {
  return (
    <Lightbox showed={isShowed} onClose={onCloseLightbox} render={render} />
  )
}

export default LightboxForm
