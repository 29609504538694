import React, { useEffect, useRef, useState } from 'react'

import { Cell } from '../../table/elements'
import { Link, Status, StatusDropdown, StatusDropdownItem } from './elements'

export const TrackingLink = ({
  disableChange,
  trackingLink,
  driverPhone,
  driverEmail,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  const timeoutRef = useRef(null)

  const handleCopyTrackingLink = () => {
    navigator.clipboard.writeText(trackingLink)

    setCopied(true)

    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <Cell
      onMouseEnter={() => !disableChange && setDropdownOpen(true)}
      onMouseLeave={() => !disableChange && setDropdownOpen(false)}
      style={{ minWidth: '165px' }}
    >
      <Status>
        {dropdownOpen && (
          <StatusDropdown onClick={(e) => e.stopPropagation()}>
            <StatusDropdownItem>
              <Link onClick={handleCopyTrackingLink}>
                {copied ? `Copied!` : `Copy Link`}
              </Link>
            </StatusDropdownItem>
            {driverPhone && (
              <StatusDropdownItem>
                {' '}
                <Link
                  href={`https://wa.me/${driverPhone}?text=${encodeURIComponent(
                    `Here is your tracking link: ${trackingLink}`
                  )}`}
                  target="_blank"
                >
                  {`Send WhatsApp`}
                </Link>
              </StatusDropdownItem>
            )}
            {driverEmail && (
              <StatusDropdownItem>
                <Link
                  href={`mailto:${driverEmail}?Subject=Tracking link&Body=Here is the tracking link: ${trackingLink}`}
                >
                  {`Send via email`}
                </Link>
              </StatusDropdownItem>
            )}
          </StatusDropdown>
        )}
        <Link $link>{`Send tracking link`}</Link>
      </Status>
    </Cell>
  )
}
