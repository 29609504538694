// @flow
import React, { useEffect, useReducer, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as driversActions from 'app/actions/drivers'

import * as ACTIONS from 'app/constants/actions'
import { LIGHTBOX_MODES } from 'app/constants/values'
import countries from 'app/constants/countries'

import * as selectors from 'app/selectors'

import { mapDriversToTable } from 'app/utils/drivers'

import { reducer, initialState } from 'app/components/commons/Page/reducer'
import { Header, Table } from 'app/components/commons'
import DriverModals from 'app/components/drivers/DriverModals'

import type { Props } from './Types'

function Drivers(props: Props) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [driver, setDriver] = useState(null)

  useEffect(() => {
    dispatch({ type: ACTIONS.TOGGLE_LOADING })
    Promise.all([props.getDrivers()]).then(() =>
      dispatch({ type: ACTIONS.TOGGLE_LOADING })
    )
  }, [])

  const onOpenLightbox = () => {
    dispatch({ type: ACTIONS.TOGGLE_LIGHTBOX })
  }

  const onDeleteRow = (id) => () => props.deleteDriver(id)

  const onUpdateRow = (id) => () => {
    const updateDriver = props.drivers.find((drv) => drv.id === id)
    setDriver(updateDriver)
    dispatch({ type: ACTIONS.SET_MODE, payload: LIGHTBOX_MODES.UPDATE })
    dispatch({ type: ACTIONS.TOGGLE_LIGHTBOX })
  }

  const onLoadMore = (next) => async () => {
    dispatch({ type: ACTIONS.TOGGLE_GETTING_MORE })
    try {
      await props.getMoreDrivers(next)
    } finally {
      dispatch({ type: ACTIONS.TOGGLE_GETTING_MORE })
    }
  }

  const onCloseLightbox = () => {
    props.setDriversError(null)
    setDriver(null)
    dispatch({ type: ACTIONS.RESET })
  }

  const closeLightbox = () => {
    dispatch({ type: ACTIONS.TOGGLE_LIGHTBOX })
  }

  const { drivers, nextDriversUrl } = props

  return (
    <>
      <Header
        title="Drivers"
        label="New Driver"
        action={onOpenLightbox}
        loading={state.isLoading}
      />

      <Table
        tableData={mapDriversToTable(drivers)}
        isLoading={state.isGettingMore}
        onDeleteRow={onDeleteRow}
        onUpdateRow={onUpdateRow}
        onLoadMore={onLoadMore}
        nextPage={nextDriversUrl}
      />

      <DriverModals
        driver={driver}
        mode={state.mode} // create/update/bulk
        isLightboxFormOpen={state.isLightboxFormOpen}
        onCloseLightbox={onCloseLightbox}
        closeLightbox={closeLightbox}
        setMode={(mode) => {
          dispatch({ type: ACTIONS.SET_MODE, payload: mode })
        }}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const vehicles = selectors.getVehicles(state)

  return {
    vehicles,
    countries,
    nextDriversUrl: state.drivers.next,
    drivers: selectors.getDrivers(state),
    uploadError: state.drivers.bulkCreate.error,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...driversActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Drivers)
