import { styled } from 'mz-ui-kit'

export const InputGroup = styled('div', {
  display: 'flex',
  alignItems: 'flex-end',
})

export const BulkWrapper = styled('div', {
  minHeight: '370px',
  margin: '20px 40px',
})

export const BulkTitle = styled('p', {
  fontSize: '18px',
  textAlign: 'center',
})

export const BulkDescription = styled('p', {
  fontSize: '15px',
  textAlign: 'center',
})
