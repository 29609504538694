export const createAction = (type) => (args) => {
  const error = args instanceof Error

  return {
    type,
    payload: args,
    error,
  }
}

export const createReducer = (initialState, actionHandlers) => {
  return (state = initialState, action) => {
    const reduceFn = actionHandlers[action.type]
    return reduceFn ? { ...state, ...reduceFn(state, action) } : state
  }
}
