import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, getFormValues, change, Field, reset } from 'redux-form'
import omitEmptyFields from 'mz-utils/omitEmptyFields'
import Client from 'mz-sdk/client'
import { CITIES_ENDPOINT } from 'app/constants/endpoints'
import { getTrips, setFilters } from 'app/actions/trips'
import countries from 'app/constants/countries'
import { Form, ButtonWrapper, Buttons } from './elements'
import Button from 'mz-ui-kit/core/Button'
import DateField from 'mz-ui-kit/form/DateField'
import SelectField from 'mz-ui-kit/form/SelectField'
import EmptySearchResults from 'mz-ui-kit/form/SelectField/EmptySearchResults'
import Row from 'mz-ui-kit/core/Row'
import PlaceIcon from 'mz-icons/place'
import GlobeIcon from 'mz-icons/globe'

// Constants
const DATE_FORMAT = 'YYYY-MM-DD'
const tripTypes = [
  { name: 'All', value: '' },
  { name: 'Hourly', value: 'hourly' },
  { name: 'Point to Point', value: 'p2p' },
]

/**
 * Component for showing trips filter form
 */
class Filters extends Component {
  static defaultProps = {
    formValues: {},
  }

  handleCountryChange = () => {
    this.props.resetField('city')
  }

  onSubmit = (values) => {
    const { country, city, fromDate, toDate, tripType } = values

    const normFilters = omitEmptyFields(
      {
        country: country && country.countryCodeName,
        city: city && city.name,
        from_date: fromDate && fromDate.format(DATE_FORMAT),
        to_date: toDate && toDate.format(DATE_FORMAT),
        ride_type: tripType && tripType.value,
      },
      { string: true }
    )

    this.props.setFilters(normFilters)

    return this.props.getTrips()
  }

  getCities = async () => {
    const { country } = this.props.formValues
    if (!country || !country.countryCodeName) return []

    const response = await Client.get(CITIES_ENDPOINT, {
      query: { country: country.countryCodeName },
    })

    return (response && response.results) || []
  }

  renderEmpty = (query) => {
    if (!query) return null
    return <EmptySearchResults query={query} />
  }

  render() {
    const { formValues, submitting, handleSubmit } = this.props

    const countryCode = formValues.country && formValues.country.countryCodeName

    return (
      <Form>
        <Row>
          <Field
            searchable
            name="country"
            placeholder="Country"
            component={SelectField}
            onChange={this.handleCountryChange}
            options={countries}
            renderOption={(itm) => `${itm.name}`}
            appearance="rect"
            renderPrefix={() => <GlobeIcon />}
          />
          <Field
            searchable
            key={countryCode}
            name="city"
            placeholder="City"
            component={SelectField}
            appearance="rect"
            emptyValue={null}
            searchableOptions={{
              requestOptionsOnMount: true,
              rememberInitialOptions: true,
              requestOptions: this.getCities,
              renderEmpty: this.renderEmpty,
              query: '',
            }}
            renderOption={(city) => `${city.name}`}
            renderPrefix={() => <PlaceIcon />}
          />
          <Field
            name="fromDate"
            placeholder="From date"
            component={DateField}
            appearance="rect"
          />
          <Field
            name="toDate"
            placeholder="to date"
            component={DateField}
            minDate={formValues.fromDate}
            appearance="rect"
          />
          <Field
            name="tripType"
            placeholder="Trip Type"
            component={SelectField}
            appearance="rect"
            options={tripTypes}
            renderOption={(itm) => `${itm.name}`}
          />
        </Row>
        <Buttons>
          <ButtonWrapper>
            <Button
              loading={submitting}
              disabled={submitting}
              onClick={handleSubmit(this.onSubmit)}
            >
              Apply filters
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button disabled={submitting} onClick={this.props.clear}>
              Clear
            </Button>
          </ButtonWrapper>
        </Buttons>
      </Form>
    )
  }
}

const selector = getFormValues('filters')
const mapStateToProps = (state) => ({
  formValues: selector(state),
})
const mapDispatchToProps = {
  resetField: (field) => change('filters', field, null),
  getTrips,
  setFilters,
  clear: () => reset('filters'),
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'filters',
    enableReinitialize: true,
    initialValues: { tripType: tripTypes[0] },
  })
)(Filters)
