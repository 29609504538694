/* eslint-disable camelcase */
import { createAction } from 'app/utils/redux'
import Client from 'mz-sdk/client'
import { change } from 'redux-form'
import { getFiltersQuery, formatDateAndTime } from 'app/utils/trips'
import { PAGE_SIZES } from 'app/constants/values'
import {
  TRIPS_ENDPOINT,
  FUTURE_TRIPS_ENDPOINT,
  PAST_TRIPS_ENDPOINT,
  TRIP_ENDPOINT,
} from 'app/constants/endpoints'
import {
  SET_TRIPS,
  ADD_TRIPS,
  UPDATE_TRIP,
  SET_TRIPS_ERROR,
  SET_FILTERS,
  APPEND_TRIP,
  STATUS_START_LOADING,
  STATUS_STOP_LOADING,
} from 'app/constants/actions'
import config from 'config'
import posthog from 'posthog-js'

export const setTrips = createAction(SET_TRIPS)

export const addTrips = createAction(ADD_TRIPS)

export const updateTrip = createAction(UPDATE_TRIP)

export const statusStartLoading = createAction(STATUS_START_LOADING)

export const statusStopLoading = createAction(STATUS_STOP_LOADING)

export const setTripStatus = (
  hashedId,
  currentStatus,
  selectedStatus,
  providerName
) => {
  return async (dispatch) => {
    dispatch(statusStartLoading({ hashed_id: hashedId }))

    const endpoint = `/trackings/${hashedId}/events`
    const baseUrl = config.TRACKING_SERVICE_BASE_URL_V1

    try {
      const body = {
        status: selectedStatus,
      }

      const response = await Client.post(endpoint, {
        baseUrl,
        body,
      })

      posthog.capture('New Status Selected (FE)', {
        current_status: currentStatus,
        selected_status: selectedStatus,
      })

      return response
    } catch (error) {
      posthog.capture('Trip Status Change Error (FE)', {
        current_status: currentStatus,
        selected_status: selectedStatus,
        error_message: error.response?.status?.[0]?.message,
        error_code: error.response?.status?.[0]?.code,
        provider_name: providerName,
        trip_id: hashedId,
      })
      return dispatch(setTripsError(error.message))
    } finally {
      dispatch(statusStopLoading({ hashed_id: hashedId }))
    }
  }
}

export const setTripsError = createAction(SET_TRIPS_ERROR)

export const setFilters = createAction(SET_FILTERS)

export const appendTrip = createAction(APPEND_TRIP)

export const getTrips = () => {
  return async (dispatch, getState) => {
    try {
      const [trips, pastTrips] = await Promise.all([
        Client.get(FUTURE_TRIPS_ENDPOINT, {
          query: {
            ...getState().trips.filters,
            page_size: PAGE_SIZES.FUTURE_TRIPS,
          },
        }),
        Client.get(PAST_TRIPS_ENDPOINT, {
          query: {
            ...getState().trips.filters,
            page_size: PAGE_SIZES.PAST_TRIPS,
          },
        }),
      ])

      const tripIds = [...trips.results, ...pastTrips.results].map(
        (trip) => trip.hashed_id
      )

      const queryParams = tripIds
        .map((tripId) => `tracking_id=${encodeURIComponent(tripId)}`)
        .join('&')

      const baseUrl = config.TRACKING_SERVICE_BASE_URL_V1
      const endpoint = `/events?${queryParams}`

      const tripStatuses = await Client.get(endpoint, {
        baseUrl,
      })

      dispatch(setTrips({ trips, pastTrips, tripStatuses }))
    } catch (error) {
      dispatch(setTripsError(error.message))
    }
  }
}

export const getMoreTrips = (nextUrl, name = 'trips') => {
  return async (dispatch, getState) => {
    try {
      const path = `${nextUrl}${getFiltersQuery(getState().trips.filters)}`
      const page = await Client.get(path, {}, { externallyBuiltUrl: true })

      const tripIds = [...page.results].map((trip) => trip.hashed_id)

      const queryParams = tripIds
        .map((tripId) => `tracking_id=${encodeURIComponent(tripId)}`)
        .join('&')

      const baseUrl = config.TRACKING_SERVICE_BASE_URL_V1
      const endpoint = `/events?${queryParams}`

      const tripStatuses = await Client.get(endpoint, {
        baseUrl,
      })

      return dispatch(addTrips({ page, name, tripStatuses }))
    } catch (error) {
      return dispatch(setTripsError(error.message))
    }
  }
}

export const assignDriverAndVehicle = (trip, { driver, vehicle }) => {
  return async (dispatch) => {
    try {
      if (!driver) {
        throw new Error('Please select a driver.')
      }

      const body = {
        driver_id: driver?.user_external_id,
        vehicle_id: vehicle?.id,
      }
      await Client.post(`${TRIPS_ENDPOINT}${trip.hashed_id}/assign/`, { body })
      dispatch(updateTrip({ ...trip, driver, vehicle }))
    } catch (error) {
      dispatch(setTripsError(error.message))
      throw error
    }
  }
}

export const getTrip = (hash) => Client.get(`${TRIP_ENDPOINT}${hash}/`)

export const unassignDriverAndVehicle = (trip) => {
  return async (dispatch) => {
    try {
      await Client.post(`${TRIPS_ENDPOINT}${trip.hashed_id}/unassign/`)
      dispatch(updateTrip({ ...trip, driver: null, vehicle: null }))
    } catch (error) {
      dispatch(setTripsError(error.message))
      throw error
    }
  }
}

export const initFromTrip = (trip) => (dispatch) => {
  if (trip.driver) dispatch(change('assign', 'driver', trip.driver))
  if (trip.vehicle) dispatch(change('assign', 'vehicle', trip.vehicle))
}

export const setPreferredVehicle = (vehicleObject) => (dispatch) => {
  return dispatch(change('assign', 'vehicle', vehicleObject || null))
}

const getLatLng = ({ lat, lng }) => ({
  lat: String(lat).length ? lat : undefined,
  lng: String(lng).length ? lng : undefined,
})

export const createTrip = ({
  start_location,
  end_location,
  first_name,
  last_name,
  email,
  phone_number,
  external_reservation_id,
  departure_date,
  departure_time,
  num_passengers,
  airline_code,
  flight_number,
  iata_code,
  flight_date,
  flight_time,
  flight_direction,
  has_flight_info,
}) => {
  const date = formatDateAndTime(departure_date, departure_time)

  return async () => {
    const body = {
      passenger_information: {
        first_name,
        last_name,
        email,
        phone: `${phone_number.country.callingCode}${phone_number.number}`,
      },
      departure_datetime: date,
      external_reservation_id,
      start_location: {
        ...start_location,
        ...getLatLng(start_location),
        address: start_location.display,
      },
      end_location: {
        ...end_location,
        ...getLatLng(end_location),
        address: end_location.display,
      },
      num_passengers,
      ...(has_flight_info && {
        flight_info: {
          airline_code,
          flight_number,
          ...(flight_direction === 'arrival'
            ? { arrival_datetime: formatDateAndTime(flight_date, flight_time) }
            : {
                departure_datetime: formatDateAndTime(flight_date, flight_time),
              }),
          iata_code: iata_code?.value,
        },
      }), // Send flight info only if date and time is not null
    }
    return Client.post(TRIPS_ENDPOINT, { body })
  }
}
