import React from 'react'
import { Provider } from 'react-redux'
import { Provider as StyletronProvider } from 'styletron-react'
import { Client as StyletronClient } from 'styletron-engine-atomic'
import Routes from 'app/routes'
import store from 'app/store'
import config from 'config'
import setConfig from 'mz-sdk/config'
import CompatibilityNotice from 'app/components/commons/CompatibilityNotice'
import runIntegrations from 'app/integrations'

window.APP_VERSION = process.env.APP_VERSION
window.GIT_COMMIT_SHA = process.env.GIT_COMMIT_SHA

runIntegrations()
setConfig({ ...config })

require('webfontloader').load({
  google: {
    families: ['Material Icons:400', 'Roboto:100,400,500,700'],
  },
})

const App = () => (
  <Provider store={store}>
    <StyletronProvider value={new StyletronClient()}>
      <Routes />
      <CompatibilityNotice />
    </StyletronProvider>
  </Provider>
)

export default App
