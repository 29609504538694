import { styled } from 'mz-ui-kit'

export const InputGroup = styled('div', {
  display: 'flex',
  alignItems: 'flex-end',
})

export const Content = styled('div', {
  minWidth: '490px',
  padding: '20px 40px',
})
