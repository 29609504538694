import React from 'react'
import { Button } from './elements'
import { withRouter } from 'react-router-dom'
import { deleteJWTToken, getAuthorizationToken } from 'mz-sdk/client'

const Logout = ({ history }) => {
  const doLogout = () => {
    deleteJWTToken()
    history.push('/login')
  }

  if (!getAuthorizationToken()) return null

  return <Button onClick={doLogout}>Logout</Button>
}

export default withRouter(Logout)
