// @flow
import React, { useEffect, useReducer, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  getVehicles as loadVehicles,
  getMoreVehicles,
  deleteVehicle,
} from 'app/actions/vehicles'
import * as ACTIONS from 'app/constants/actions'

import { LIGHTBOX_MODES } from 'app/constants/values'

import { getVehicles } from 'app/selectors'

import { mapVehiclesToTable } from 'app/utils/vehicles'

import { reducer, initialState } from 'app/components/commons/Page/reducer'
import { Header, Table } from 'app/components/commons'
import VehicleModals from 'app/components/vehicles/VehicleModals'

const Vehicles = () => {
  const [reducerState, dispatch] = useReducer(reducer, initialState)
  const [vehicle, setVehicle] = useState(null)
  const vehicles = useSelector(getVehicles)
  const nextVehiclesUrl = useSelector((state) => state.vehicles.next)
  const dispatcher = useDispatch()

  useEffect(() => {
    dispatch({ type: ACTIONS.TOGGLE_LOADING })
    dispatcher(loadVehicles()).then(() =>
      dispatch({ type: ACTIONS.TOGGLE_LOADING })
    )
  }, [])

  const onOpenLightbox = () => {
    dispatch({ type: ACTIONS.TOGGLE_LIGHTBOX })
  }

  const closeLightbox = () => {
    dispatch({ type: ACTIONS.TOGGLE_LIGHTBOX })
  }

  const onCloseLightbox = () => {
    setVehicle(null)
    dispatch({ type: ACTIONS.RESET })
  }

  const onDeleteRow = (id) => () => dispatcher(deleteVehicle(id))

  const onUpdateRow = (id) => () => {
    const foundVehicle = vehicles.find((vh) => vh.id === id)
    setVehicle(foundVehicle)
    dispatch({ type: ACTIONS.TOGGLE_LIGHTBOX })
    dispatch({ type: ACTIONS.SET_MODE, payload: LIGHTBOX_MODES.UPDATE })
  }

  const onLoadMore = (next) => async () => {
    dispatch({ type: ACTIONS.TOGGLE_GETTING_MORE })
    try {
      await dispatcher(getMoreVehicles(next))
    } finally {
      dispatch({ type: ACTIONS.TOGGLE_GETTING_MORE })
    }
  }

  return (
    <>
      <Header
        title="Vehicles"
        label="New Vehicle"
        action={onOpenLightbox}
        loading={reducerState.isLoading}
      />

      <Table
        tableData={mapVehiclesToTable(vehicles)}
        onDeleteRow={onDeleteRow}
        onUpdateRow={onUpdateRow}
        nextPage={nextVehiclesUrl}
        onLoadMore={onLoadMore}
        isLoading={reducerState.isGettingMore}
      />

      <VehicleModals
        vehicle={vehicle}
        mode={reducerState.mode}
        isLightboxFormOpen={reducerState.isLightboxFormOpen}
        onCloseLightbox={onCloseLightbox}
        closeLightbox={closeLightbox}
        setMode={(mode) => {
          dispatch({ type: ACTIONS.SET_MODE, payload: mode })
        }}
      />
    </>
  )
}

export default Vehicles
