export const LOGIN_USER = 'LOGIN_USER'
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'

export const SET_TRIPS = 'SET_TRIPS'
export const ADD_TRIPS = 'ADD_TRIPS'
export const UPDATE_TRIP = 'UPDATE_TRIP'
export const SET_TRIPS_ERROR = 'SET_TRIPS_ERROR'
export const SET_FILTERS = 'SET_FILTERS'
export const APPEND_TRIP = 'APPEND_TRIP'
export const STATUS_START_LOADING = 'STATUS_START_LOADING'
export const STATUS_STOP_LOADING = 'STATUS_STOP_LOADING'

export const ADD_VEHICLES = 'SET_VEHICLES'
export const ADD_VEHICLE = 'ADD_VEHICLE'
export const ADD_BULK_VEHICLES = 'ADD_BULK_VEHICLES'
export const REMOVE_VEHICLE = 'REMOVE_VEHICLE'
export const SET_VEHICLES_ERROR = 'SET_VEHICLES_ERROR'
export const SET_UPDATED_VEHICLE = 'SET_UPDATED_VEHICLE'

export const ADD_DRIVERS = 'ADD_DRIVERS'
export const ADD_DRIVER = 'ADD_DRIVER'
export const ADD_BULK_DRIVERS = 'ADD_BULK_DRIVERS'
export const REMOVE_DRIVER = 'REMOVE_DRIVER'
export const SET_DRIVERS_ERROR = 'SET_DRIVERS_ERROR'
export const SET_UPDATED_DRIVER = 'SET_UPDATED_DRIVER'
export const SET_BULK_CREATE_VEHICLES = 'SET_BULK_CREATE_VEHICLES'
export const REMOVE_TEMP_VEHICLES = 'REMOVE_TEMP_VEHICLES'
export const REMOVE_TEMP_DRIVERS = 'REMOVE_TEMP_DRIVERS'
export const ADD_TEMP_DRIVERS = 'ADD_TEMP_DRIVERS'

export const TOGGLE_LOADING = 'TOGGLE_LOADING'
export const TOGGLE_GETTING_MORE = 'TOGGLE_GETTING_MORE'
export const TOGGLE_LIGHTBOX = 'TOGGLE_LIGHTBOX'
export const SET_MODE = 'SET_MODE'
export const TOGGLE_SUBMITTING_SINGLE = 'TOGGLE_SUBMITTING_SINGLE'
export const TOGGLE_SUBMITTING_BULK = 'TOGGLE_SUBMITTING_BULK'
export const RESET = 'RESET'
export const SET_DRIVER_ID = 'SET_DRIVER_ID'
export const SET_UPLOAD_ERROR = 'SET_UPLOAD_ERROR'
export const TOGGLE_PROGRESS = 'TOGGLE_PROGRESS'
export const TOGGLE_CSVPREVIEW = 'TOGGLE_CSVPREVIEW'
export const PROGRESS_MESSAGE = 'PROGRESS_MESSAGE'
export const UPDATE_BULK_VEHICLES = 'UPDATE_BULK_VEHICLES'
