/* eslint-disable camelcase */

const headers = [
  {
    label: 'First Name',
    id: 'first_name',
  },
  {
    label: 'Last Name',
    id: 'last_name',
  },
  {
    label: 'Mobile Phone',
    id: 'phone',
  },
]

export const mapErrors = (errors, drivers) => {
  const getFirstValue = (value) => {
    return value[0]
  }

  const fields = {
    last_name: () => "Last name can't be blank",
    first_name: () => "Fist name can't be blank",
    non_field_errors: getFirstValue,
    phone_number: () => 'Invalid Phone number',
    country_code_name: getFirstValue,
  }

  const errorMessages = errors.reduce((acc, err) => {
    const reduced = Object.entries(err).reduce((eachAcc, [key, value]) => {
      if (key !== 'row') {
        // find the index of entry
        const line = drivers.bulkCreate.drivers.findIndex(
          (driver) => driver.phone_number === err.row.phone_number
        )
        return eachAcc.concat([`${fields[key](value)} (line: ${line + 2})`])
      }
      return eachAcc
    }, [])
    return acc.concat(reduced)
  }, [])
  if (errorMessages.length) {
    errorMessages.push('Please fix the issues above and re-send CSV file.')
  }
  return errorMessages
}

export const mapDriversToTable = (drivers) => {
  const reduced = drivers.reduce((reducedDrivers, driver) => {
    return reducedDrivers.concat({
      id: driver.id,
      first_name: driver.first_name,
      last_name: driver.last_name,
      phone: ` (${driver.country_code || ''}) ${
        driver.phone_number_national || ''
      } `,
    })
  }, [])

  return {
    headers,
    entries: reduced,
  }
}

export const mapCSVDriversToTable = (drivers = []) => {
  return {
    headers: [
      {
        label: 'First Name',
        id: 'first_name',
      },
      {
        label: 'Last Name',
        id: 'last_name',
      },
      {
        label: 'Country Code',
        id: 'country_code_name',
      },
      {
        label: 'Phone Number',
        id: 'phone_number',
      },
    ],
    entries: drivers.filter((driver) => {
      const { first_name, last_name, country_code_name, phone_number } = driver

      // eslint-disable-next-line prettier/prettier
      return first_name && last_name && country_code_name && phone_number
    }),
  }
}
