import { styled } from 'mz-ui-kit'
import { NAVBAR_HEIGHT } from 'app/constants/sizes'

export const Container = styled('section', {
  position: 'relative',
  display: 'flex',
  height: '100%',
  minHeight: `calc(100vh - ${NAVBAR_HEIGHT})`,
})

export const Content = styled('article', {
  width: '100%',
  padding: '40px',
})
