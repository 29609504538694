import moment from 'moment'
import { statusMap } from 'app/constants/status'

const getDepartureTime = (trip) => moment.parseZone(trip.departure_datetime)
export const isExpired = (trip) => getDepartureTime(trip).isBefore(moment())

export const getPickupTime = (trip) => getDepartureTime(trip).format('hh:mmA')
export const getTripDate = (trip) => getDepartureTime(trip).format('DD/MM/YYYY')

export const hasMissingAssingment = (trip) =>
  !isExpired(trip) && (!trip.driver || !trip.vehicle)

export const isTripCancelled = (trip) =>
  trip.status &&
  (trip.status === 'cancelled-by-user' ||
    trip.status === 'cancelled-by-driver' ||
    trip.status === 'cancelled' ||
    trip.status === 'cancelled-by-system')

export const getTripStatus = (trip) => {
  return statusMap[trip.status] || getFallbackStatus(trip.status)
}

export const getFallbackStatus = (status) => {
  if (!status) {
    return {
      name: '',
      color: 'DARKGRAY',
    }
  }

  return {
    name: status.replace('_', ''),
    color: 'DARKGRAY',
  }
}

export const isUrgent = (trip) => {
  const departureTime = getDepartureTime(trip)
  return (
    !isTripCancelled(trip) &&
    departureTime.diff(moment(), 'hours') <= 24 &&
    hasMissingAssingment(trip)
  )
}

export const hasUrgentTrip = (trips) => {
  const urgentTrips = trips.filter((trip) => isUrgent(trip))
  return !!urgentTrips.length
}

export const getFiltersQuery = (filters) => {
  if (Object.keys(filters).length <= 0) return ''
  const query = Object.keys(filters)
    .map((filter) => {
      return `${encodeURIComponent(filter)}=${encodeURIComponent(
        filters[filter]
      )}`
    })
    .join('&')
  return `&${query}`
}

export const getVehicleType = ({ reservation_vehicle }) =>
  reservation_vehicle.type || 'N/A'

export function formatDateAndTime(dateMoment, timeMoment) {
  if (!dateMoment || !timeMoment) return null
  return dateMoment
    .clone()
    .hours(timeMoment.hours())
    .minutes(timeMoment.minutes())
    .format()
}

export const isHourlyTrip = (serviceType) =>
  serviceType === 5 || serviceType === 6

export const getTripType = ({ service_type }) => {
  return isHourlyTrip(service_type) ? 'Hourly' : 'Point to Point'
}
