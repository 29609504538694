// @flow
import React from 'react'
import LightboxForm from 'app/components/commons/LightboxForm'
import SingleCreate from './SingleCreate'
import countries from 'app/constants/countries'
import Form from 'mz-ui-kit/form/Form'
import LightboxFooter from 'app/components/commons/LightboxForm/LightboxFooter'

type Props = {
  isShowed: boolean,
  errorMessage?: string,
  onCloseLightbox: () => void,
  onSubmit: Function,
  onTabChange?: () => void,
}

const TripsForm = (props: Props) => {
  const { isShowed, onSubmit, onCloseLightbox, ...rest } = props
  const renderForm = () => (
    <Form
      onSubmit={onSubmit}
      initValue={{
        num_passengers: 1,
        flight_direction: 'arrival',
      }}
      render={({ submit, meta, value }, nest) => (
        <>
          <SingleCreate countries={countries} nest={nest} value={value} />
          <LightboxFooter
            {...rest}
            meta={meta}
            onSubmit={submit}
            submissionLabel="Add Trip"
            onCloseLightbox={onCloseLightbox}
          />
        </>
      )}
    />
  )
  return (
    <LightboxForm
      isShowed={isShowed}
      onCloseLightbox={onCloseLightbox}
      render={renderForm}
    />
  )
}

export default TripsForm
