import { Link } from 'react-router-dom'
import { styled } from 'mz-ui-kit'

export const Container = styled('div', {
  display: 'flex',
  padding: '40px 20px',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

export const SuccessWrapper = styled('div', ({ $palette }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '15px',
  fontSize: '40px',
  color: `${$palette('PRIMARY')}`,
  border: `1px solid ${$palette('PRIMARY')}`,
  borderRadius: '50%',
  marginBottom: '20px',
}))

export const Section = styled('div', ({ $small }) => ({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: $small ? '20px' : '40px',
}))

export const Heading = styled('h1', ({ $palette, $bolder }) => ({
  color: `${$palette('PRIMARY')}`,
  fontSize: '24px',
  textAlign: 'center',
  margin: '0',
  fontWeight: $bolder ? 'bold' : '300',
}))

export const Text = styled('p', ({ $palette, $bolder, $color }) => ({
  fontSize: '14px',
  textAlign: 'center',
  lineHeight: '18px',
  margin: '0',
  color: `${$palette($color || 'PRIMARY')}`,
  fontWeight: $bolder ? 'bold' : '300',
}))

export const DecoratedLink = styled(Link, ({ $color, $palette }) => ({
  textDecorationColor: `${$palette($color || 'PRIMARY')}`,
}))

export const FormWrapper = styled('div', {
  width: '335px',
  maxWidth: '80%',
})
