// @flow
import React from 'react'
import Lightbox from 'mz-ui-kit/core/Lightbox'
import { Message, ProgressContent, Loading } from './elements'
import { STATUS } from 'app/constants/values'

type Props = {
  showed: boolean,
  onClose: () => void,
  message: {
    message: Array<String>,
    status: $Keys<STATUS>,
  },
}

const Progress = ({ showed, onClose, message }: Props) => {
  return (
    <Lightbox
      showed={showed}
      onClose={onClose}
      render={() => {
        return (
          <ProgressContent>
            {message.status === STATUS.LOADING && <Loading />}
            {message.message.map((msg, index) => (
              <Message key={index}>{msg}</Message>
            ))}
          </ProgressContent>
        )
      }}
    />
  )
}

export default Progress
