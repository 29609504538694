import React from 'react'
import { Alert, Bolder } from './elements'

const UrgentTripsAlert = () => (
  <Alert>
    <Bolder>Urgent:</Bolder>
    Trips in the next 24 hours with no driver or vehicle assigned
  </Alert>
)

export default UrgentTripsAlert
