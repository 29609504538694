import { styled } from 'mz-ui-kit'

export const Message = styled('p', {})

export const ProgressContent = styled('div', {
  minHeight: '400px',
  minWidth: '400px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '16px',
})

export const Loading = styled('div', ({ $palette }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '100%',
  border: `4px solid ${$palette('PRIMARY')}`,
  borderBottom: '4px solid transparent',
  animationDuration: '0.8s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
  animationName: {
    from: {
      transform: 'rotate(0)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}))
