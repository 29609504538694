import React, { useState } from 'react'

import { Cell } from '../../table/elements'
import { Status, StatusDropdown, StatusDropdownItem } from './elements'
import { statusMap } from '../../../constants/status'
import Icon from 'app/components/commons/Icon'

export const TripStatus = ({ status, disableChange, onChange, isLoading }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const statusItemOnClick = (newStatus) => {
    setDropdownOpen(false)
    onChange(newStatus)
  }

  return (
    <Cell
      onMouseEnter={() => !disableChange && setDropdownOpen(true)}
      onMouseLeave={() => !disableChange && setDropdownOpen(false)}
      style={{ minWidth: '165px' }}
    >
      <Status $background={`${status?.color}`}>
        {isLoading ? 'Loading...' : status?.name}
        {dropdownOpen && (
          <StatusDropdown onClick={(e) => e.stopPropagation()}>
            {Object.entries(statusMap).map((el) => (
              <StatusDropdownItem
                onClick={() => statusItemOnClick(el[0])}
                $active={el[1].name === status?.name}
                key={el}
              >
                {el[1].name}
              </StatusDropdownItem>
            ))}
          </StatusDropdown>
        )}
        <Icon $noMargin>{dropdownOpen ? 'expand_less' : 'expand_more'}</Icon>
      </Status>
    </Cell>
  )
}
