// @flow
import React from 'react'
import { Container, Title } from './elements'
import Button from 'mz-ui-kit/core/Button'
import LoadingSpinner from 'mz-ui-kit/core/LoadingSpinner'

type Props = {
  title: String,
  label: String,
  action: () => void,
  loading: Boolean,
}

const Header = ({ title, label, action, loading }: Props) => (
  <Container>
    <div>
      <Title> {title} </Title>
      {loading && <LoadingSpinner />}
    </div>
    {label && action && (
      <Button size="small" onClick={action}>
        {label}
      </Button>
    )}
  </Container>
)

export default Header
