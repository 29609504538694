import { createAction } from 'app/utils/redux'
import Client, { saveJWTToken } from 'mz-sdk/client'
import { LOGIN_ENDPOINT } from 'app/constants/endpoints'
import { LOGIN_USER, SET_AUTH_ERROR } from 'app/constants/actions'
import posthog from 'posthog-js'

export const login = createAction(LOGIN_USER)

export const setAuthError = createAction(SET_AUTH_ERROR)

export const doLogin = (userData) => {
  return async (dispatch) => {
    try {
      const response = await Client.post(LOGIN_ENDPOINT, { body: userData })
      return saveJWTToken(response)
        ? dispatch(setAuthError(null))
        : dispatch(setAuthError('Unable to authenticed'))
    } catch (error) {
      // TODO: use error message from the backend when we will decide
      //       how to handle error messages in general
      posthog.capture('Provider Dashboard Sign-in Error', {
        provider_email: userData.email,
        error_code: error.response?.code,
        error_message: error.response?.message,
      })
      return dispatch(
        setAuthError('Unable to login with given email and password.')
      )
    }
  }
}
