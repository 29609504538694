import createMessages from 'mz-intl/create'

export default createMessages('TRIP_DETAILS_LIGHTBOX', {
  TRIP_SYNOPSIS: 'Trip Synopsis',
  PICKUP_LABEL: 'Pickup:',
  MEET_AND_GREET_SERVICE_REQUESTED: 'Meet and Greet Service Requested',
  PASSENGER_DETAILS: 'Passenger Details',
  NAME_LABEL: 'Name:',
  NUMBER_OF_PASSENGERS_LABEL: 'Number of Passengers:',
  PHONE_NUMBER_LABEL: 'Phone number:',
  EMAIL_LABEL: 'Email:',
  AIRPORT_LABEL: 'Airport:',
  FLIGHT_NUMBER_LABEL: 'Flight Number:',
  AIRPORT_TERMINAL_LABEL: 'Airport Terminal:',
  CRUISE_LANE_LABEL: 'Cruise Lane:',
  SHIP_NAME_LABEL: 'Ship Name:',
  CONFIRMATION_NUMBER_LABEL: 'Confirmation Number:',
  SPECIAL_INSTRUCTIONS_LABEL: 'Special Instructions:',
  TRIP_DETAILS: 'Trip Details',
  ON_LABEL: 'On:',
  AT_LABEL: 'At:',
  DROPOFF_AT: 'Dropoff at:',
  HOURLY_DURATION: 'Hourly Duration',
  TOTAL_KM_INCLUDED: 'Total kilometers included',
  PAYMENT_DETAILS: 'Payment Details',
  AMOUNT_CHARGED: 'We charged the customer:',
  CREDIT_CARD_FEE: 'The Credit Card Feed was:',
  MOZIOS_CUT: "Mozio's cut is:",
  YOU_WILL_BE_PAID: 'You will be paid:',
  ASSIGN_A_DRIVER: 'Assign a Driver',
  DRIVER_LABEL: 'Driver',
  PREFRERRED_VEHICLE_LABEL:
    'Vehicle (Drivers preferred vehicle set by default)',
  CANCEL: 'Cancel',
  UNASSIGN: 'Unassign',
  ASSIGN: 'Assign',
  VERIFIED: 'Verified',
  VERIFY_RESERVATION: 'Verify Resevation',
  RESERVATION_CANCELLED: 'Cancelled',
  REQUEST_VEHICLE_TYPE: 'Requested vehicle type: {type}',
  CANCELED_CONFIRAMTION_NUMBER: 'Canceled Confirmation Number: {number}',
  NEW_CONFIRMATION_NUMBER: 'New Confiramtion number: {number}',
})
