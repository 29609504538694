import React, { Component } from 'react'
import { styled } from 'mz-ui-kit'
import Transition from 'react-transition-group/Transition'
import { isChrome } from 'app/utils/browser'
import Button from 'mz-ui-kit/core/Button'

const duration = 1000
const opacityMap = {
  entering: 0,
  entered: 0.9,
  exiting: 0,
}

const Notice = styled('div', ({ $state, $palette }) => ({
  position: 'fixed',
  top: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  fontWeight: 'normal',
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: `${opacityMap[$state]}`,
  color: `${$palette('text')}`,
  background: `${$palette('gray')}`,
  boxShadow: '0px 3px 6px 0px rgba(0,0,0,0.45)',
  borderBottom: `2px solid ${$palette('primary')}`,
  'z-index': 1000000,
}))

const Content = styled('p', {
  maxWidth: '80%',
  marginBottom: '20px',
  textAlign: 'center',
})

class CompatibilityNotice extends Component {
  state = { closed: isChrome }

  close = () => {
    this.setState({ closed: true })
  }

  render() {
    return (
      <Transition
        mountOnEnter
        unmountOnExit
        in={!this.state.closed}
        timeout={duration}
      >
        {(state) => (
          <Notice $state={state}>
            <Content>
              {`This tool has been optimized to work with Google's
              Chrome Browser. To ensure a smooth browsing experience,
              we suggest you switch browsers.`}
            </Content>
            <Button onClick={this.close}> Hide Me </Button>
          </Notice>
        )}
      </Transition>
    )
  }
}

export default CompatibilityNotice
