import React from 'react'
import { FormattedMessage } from 'mz-intl'
import Button from 'mz-ui-kit/core/Button'
import Row from 'mz-ui-kit/core/Row'
import { ErrorMessage, ButtonsWrapper } from './elements'

export default function LightboxFooter({
  errorMessage,
  onCloseLightbox,
  onSubmit,
  customActions,
  meta,
  submissionLabel,
}) {
  return (
    <>
      {errorMessage && (
        <FormattedMessage message={errorMessage} tagName={ErrorMessage} />
      )}
      <ButtonsWrapper>
        <Row flexible>
          <Button color="gray" onClick={onCloseLightbox}>
            Cancel
          </Button>
          {customActions && React.createElement(customActions)}
          <Button type="submit" loading={meta.loading} onClick={onSubmit}>
            {submissionLabel}
          </Button>
        </Row>
      </ButtonsWrapper>
    </>
  )
}
