import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import App from 'app/components/containers/App'
import LoginPage from 'app/components/login/LoginPage'
import SignupPage from 'app/components/signup/SignupPage'
import VerificationPage from 'app/components/verification/VerificationPage'
import DashboardRoutes from './DashboardRoutes'
import { getAuthorizationToken } from 'mz-sdk/client'
import CookieNotice from 'app/components/commons/CookieNotice'

const routes = [
  {
    path: '/login',
    public: true,
    component: LoginPage,
  },
  {
    path: '/signup',
    public: true,
    component: SignupPage,
  },
  {
    path: '/verification',
    public: true,
    component: VerificationPage,
  },
]

const Routes = () => (
  <Router>
    <App>
      <Route
        exact
        path="/"
        render={() =>
          getAuthorizationToken() ? (
            <Redirect to="/trips" />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
      {routes.map((route, index) => {
        return route.public ? (
          <Route key={index} exact {...route} />
        ) : (
          <PrivateRoute key={index} exact {...route} />
        )
      })}
      <DashboardRoutes />
      <CookieNotice />
    </App>
  </Router>
)

export default Routes
