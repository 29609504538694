import {
  TOGGLE_LOADING,
  TOGGLE_GETTING_MORE,
  TOGGLE_LIGHTBOX,
  SET_MODE,
  RESET,
  SET_DRIVER_ID,
} from 'app/constants/actions'

import { LIGHTBOX_MODES } from 'app/constants/values'

export const initialState = {
  isLightboxFormOpen: false,
  isLoading: false,
  mode: LIGHTBOX_MODES.BULK,
  driverId: null,
  isGettingMore: false,
}

const reduce = {
  [TOGGLE_LOADING]: (state) => ({ ...state, isLoading: !state.isLoading }),
  [TOGGLE_GETTING_MORE]: (state) => ({
    ...state,
    isGettingMore: !state.isGettingMore,
  }),
  [TOGGLE_LIGHTBOX]: (state) => ({
    ...state,
    isLightboxFormOpen: !state.isLightboxFormOpen,
  }),
  [SET_MODE]: (state, action) => ({ ...state, mode: action.payload }),
  [SET_DRIVER_ID]: (state, action) => ({ ...state, driverId: action.payload }),
  [RESET]: () => initialState,
}
export const reducer = (state, action) =>
  reduce[action.type] ? reduce[action.type](state, action) : state
