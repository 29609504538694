import React from 'react'
import { Tab, Tabs } from 'mz-ui-kit/core/Tabs'
import { TAB_MODE } from 'app/constants/values'

const withUploadTabs = (Lightbox) => (props) => {
  const { onTabChange, render, closeLightbox, mode, ...lightboxProps } = props
  const onChange = (selectedTab) => onTabChange(selectedTab)
  const tabMode = mode === TAB_MODE.BULK ? TAB_MODE.BULK : TAB_MODE.SINGLE
  return (
    <Lightbox
      {...lightboxProps}
      closeLightbox={closeLightbox}
      render={(_, nest) => {
        return (
          <>
            <Tabs
              onChange={onChange}
              indicatorColor="transparent"
              tabColor="gray"
              activeTabColor="white"
              initialActiveTab={tabMode}
              activeTab={tabMode}
            >
              <Tab value={TAB_MODE.SINGLE}>Single Upload</Tab>
              <Tab value={TAB_MODE.BULK}>Bulk Upload</Tab>
            </Tabs>
            {render(tabMode, nest)}
          </>
        )
      }}
    />
  )
}

export default withUploadTabs
