import { POSTHOG_ENABLED } from 'app/components/commons/CookieNotice'
import posthog from 'posthog-js'

function initPosthog() {
  const {
    REACT_APP_MOZIO_PUBLIC_POSTHOG_KEY,
    REACT_APP_MOZIO_PUBLIC_POSTHOG_HOST,
    APP_VERSION,
    CONFIG_ENV,
  } = process.env

  if (
    !REACT_APP_MOZIO_PUBLIC_POSTHOG_KEY ||
    !REACT_APP_MOZIO_PUBLIC_POSTHOG_HOST
  ) {
    // eslint-disable-next-line no-console
    console.error(
      'Posthog integration is not configured properly so it will not be loaded.'
    )
    return
  }

  posthog.init(REACT_APP_MOZIO_PUBLIC_POSTHOG_KEY, {
    api_host: REACT_APP_MOZIO_PUBLIC_POSTHOG_HOST,
    opt_out_capturing_by_default: localStorage.getItem(POSTHOG_ENABLED) !== '1',
    debug: CONFIG_ENV === 'development',
    disable_session_recording: true,
    loaded: () => {
      posthog.register({
        product: 'Providers Dashboard',
        app_version: APP_VERSION,
      })
    },
  })
}

export default initPosthog
