import {
  SET_MODE,
  TOGGLE_SUBMITTING_SINGLE,
  TOGGLE_SUBMITTING_BULK,
  TOGGLE_PROGRESS,
  TOGGLE_CSVPREVIEW,
  PROGRESS_MESSAGE,
} from 'app/constants/actions'

export const initialState = {
  isSubmittingSingle: false,
  isSubmittingBulk: false,
  isProgressOpen: false,
  isPreviewOpen: false,
  progressMessage: {},
}

const reduce = {
  [SET_MODE]: (state, action) => ({ ...state, mode: action.payload }),
  [TOGGLE_SUBMITTING_SINGLE]: (state) => ({
    ...state,
    isSubmittingSingle: !state.isSubmittingSingle,
  }),
  [TOGGLE_SUBMITTING_BULK]: (state) => ({
    ...state,
    isSubmittingBulk: !state.isSubmittingBulk,
  }),
  [TOGGLE_PROGRESS]: (state) => ({
    ...state,
    isProgressOpen: !state.isProgressOpen,
  }),
  [TOGGLE_CSVPREVIEW]: (state) => ({
    ...state,
    isPreviewOpen: !state.isPreviewOpen,
  }),
  [PROGRESS_MESSAGE]: (state, action) => ({
    ...state,
    progressMessage: action.payload,
  }),
}
export const reducer = (state, action) =>
  reduce[action.type] ? reduce[action.type](state, action) : state
