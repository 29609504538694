import posthog from 'posthog-js'
import React, { useState } from 'react'
import Button from 'mz-ui-kit/core/Button'
import { styled } from 'mz-ui-kit'
import { getAuthorizationToken } from 'mz-sdk/client'
import { connect } from 'react-redux'

const CookieDisclaimerContainer = styled('div', {
  position: 'fixed',
  bottom: '1rem',
  left: '0.5rem',
  right: '0.5rem',
  zIndex: 50,
  gap: '1rem',
  backgroundColor: 'white',
  padding: '0.5rem',
  borderRadius: '0.5rem',
  fontSize: '0.875rem',
  boxShadow: '0 0 0.5rem #ccc',
  '@media (min-width: 640px)': {
    left: '1rem',
    right: '1rem',
    flexDirection: 'row',
    alignItems: 'center',
  },
  '@media (min-width: 1024px)': {
    left: '50%',
    width: '44rem',
    transform: 'translateX(-50%)',
  },
})

const Title = styled('p', {
  marginTop: '0.25rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  fontSize: '16px',
  textAlign: 'center',
  '@media (min-width: 640px)': {
    marginTop: '0',
  },
  '@media (min-width: 1024px)': {
    paddingLeft: '0',
    paddingRight: '0',
  },
})

const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gap: '0.5rem',
})

export const POSTHOG_ENABLED = 'ph_enabled'

const CookieNotice = () => {
  const shouldShowBanner = !localStorage.getItem(POSTHOG_ENABLED)
  const [showBanner, setShowBanner] = useState(shouldShowBanner)

  const onAcceptCookies = () => {
    posthog.opt_in_capturing()
    localStorage.setItem(POSTHOG_ENABLED, '1')
    setShowBanner(false)
  }

  const onDeclineCookies = () => {
    posthog.opt_out_capturing()
    localStorage.setItem(POSTHOG_ENABLED, '0')
    setShowBanner(false)
  }

  if (!getAuthorizationToken() || !showBanner) {
    return null
  }

  return (
    <CookieDisclaimerContainer>
      <Title>{'We are using cookies to track and measure analytics.'}</Title>
      <ButtonContainer>
        <Button onClick={onDeclineCookies} variant="ghost" size="lg">
          {'Continue without cookies'}
        </Button>
        <Button size="lg" onClick={onAcceptCookies}>
          {'Accept'}
        </Button>
      </ButtonContainer>
    </CookieDisclaimerContainer>
  )
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(CookieNotice)
