import { styled } from 'mz-ui-kit'

export const TableWrapper = styled('div', {
  width: '900px',
  maxHeight: '80vh',
  overflow: 'scroll',
})

export const Actions = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
})

export const ActionButtons = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
})
