/* eslint-disable camelcase */
// @flow
import React, { useMemo } from 'react'
import LightboxForm from 'app/components/commons/LightboxForm'
import LightboxFooter from 'app/components/commons/LightboxForm/LightboxFooter'
import withUploadTabs from 'app/components/commons/LightboxForm/withUploadTabs'
import SingleCreate from './SingleCreate'
import BulkCreate from 'app/components/commons/Page/Form/BulkCreate'
import { TAB_MODE } from 'app/constants/values'
import Form from 'mz-ui-kit/form/Form'

type Props = {
  mode: $Keys<TAB_MODE>,
  isShowed: boolean,
  errorMessage: String,
  onCloseLightbox: () => void,
  onTabChange: (String) => void,
  onSubmit: () => void,
  driver: Object,
}

const getDriverInitValue = (driver) => {
  if (!driver) return null
  const {
    id,
    image,
    first_name,
    last_name,
    email,
    phone_number_national,
    country_code: callingCode,
    country_code_name: code,
    preferred_vehicle_id: vehicleId,
  } = driver

  return {
    id,
    first_name,
    last_name,
    email,
    phone_number: {
      country: {
        callingCode,
        code,
      },
      number: phone_number_national,
    },
    image,
    vehicle: { id: vehicleId },
  }
}

const LightboxWithTabs = withUploadTabs(LightboxForm)

const DriversForm = (props: Props) => {
  const {
    onSubmit,
    driver,
    onTabChange,
    onCloseLightbox,
    isShowed,
    mode,
    ...rest
  } = props

  const initialValue = useMemo(() => getDriverInitValue(driver), [driver])

  const label =
    mode === 'bulk' ? 'Load File' : driver ? 'Update Driver' : 'Add Driver'

  const renderTabs = (tabMode) => (
    <Form
      onSubmit={onSubmit}
      initValue={initialValue}
      render={({ submit, meta }, nest) => (
        <>
          {tabMode === TAB_MODE.BULK ? (
            <BulkCreate
              nest={nest}
              title={'Upload a .cvs file with multiple drivers.'}
              description={
                <span>
                  Please use{' '}
                  <a href="/drivers.template.csv" download>
                    this template
                  </a>{' '}
                  so the correct information is being displayed.
                </span>
              }
            />
          ) : (
            <SingleCreate driver={driver} nest={nest} />
          )}
          <LightboxFooter
            {...rest}
            meta={meta}
            onSubmit={submit}
            submissionLabel={label}
            onCloseLightbox={onCloseLightbox}
          />
        </>
      )}
    />
  )

  const Lightbox = driver ? LightboxForm : LightboxWithTabs

  return <Lightbox {...props} render={renderTabs} />
}

export default DriversForm
