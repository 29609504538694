export const mapVehiclesToTable = (vehicles) => {
  return {
    headers: [
      {
        label: 'Manufacturer',
        id: 'manufacturer',
      },
      {
        label: 'Model',
        id: 'model',
      },
      {
        label: 'Year',
        id: 'year',
      },
      {
        label: 'License',
        id: 'plate',
      },
      {
        label: 'Color',
        id: 'color',
      },
    ],
    entries: vehicles,
  }
}

export const mapCSVVehiclesToTable = (vehicles = []) => {
  return {
    headers: [
      {
        label: 'Manufacturer',
        id: 'manufacturer',
      },
      {
        label: 'Model',
        id: 'model',
      },
      {
        label: 'Year',
        id: 'year',
      },
      {
        label: 'Plate',
        id: 'plate',
      },
      {
        label: 'Color',
        id: 'color',
      },
    ],
    entries: vehicles.filter((vehicle) => {
      const { manufacturer, model, year, plate, color } = vehicle
      return manufacturer && model && year && plate && color
    }),
  }
}
