import { styled } from 'mz-ui-kit'
import { BIG_SEPARATOR } from 'app/constants/sizes'

export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: BIG_SEPARATOR,
})

export const Title = styled('span', ({ $palette }) => ({
  margin: '0 10px 0 0',
  fontSize: '30px',
  fontWeight: '100',
  color: `${$palette('TEXT')}`,
}))
