// @flow
import React from 'react'
import { Table, Body, Headers, Row, Cell } from 'app/components/table/elements'
import Actions from 'app/components/table/Actions'
import ShowMoreButton from 'app/components/commons/ShowMoreButton'

type Props = {
  tableData: {
    headers: Array<Object>,
    entries: Array<Object>,
  },
  onDeleteRow: (id: String) => void,
  onUpdateRow: (id: String) => void,
  isLoading: Boolean,
  onLoadMore: () => void,
  nextPage: String,
}

/**
 * Datatable for Drivers and Vehicles
 */
const TableComponent = ({
  tableData: { headers = [], entries = [] },
  onDeleteRow,
  onUpdateRow,
  nextPage,
  onLoadMore,
  isLoading,
}: Props) => (
  <>
    <Table>
      <Body>
        <Headers>
          {headers.map((heading, index) => (
            <Cell key={index}>{heading.label}</Cell>
          ))}
          <Cell />
        </Headers>
        {entries.map((rowProps, index) => {
          return (
            <Row key={index}>
              {headers.map((hd, entryIndex) => (
                <Cell key={entryIndex}>{rowProps[hd.id]}</Cell>
              ))}
              <Cell>
                <Actions
                  onDelete={onDeleteRow(rowProps.id)}
                  onUpdate={onUpdateRow(rowProps.id)}
                />
              </Cell>
            </Row>
          )
        })}
      </Body>
    </Table>
    {nextPage && (
      <ShowMoreButton
        nextUrl={nextPage}
        loadFn={onLoadMore}
        loading={isLoading}
      />
    )}
  </>
)

export default TableComponent
