import React from 'react'
import { Container, Item } from './elements'
import { withRouter } from 'react-router-dom'
import { routes } from 'app/routes/DashboardRoutes'
import Icon from 'app/components/commons/Icon'

const Sidebar = ({ location }) => (
  <Container>
    {routes.map((route, index) => (
      <Item
        key={index}
        to={route.pathTo}
        $active={location.pathname.includes(route.pathTo)}
      >
        <Icon> {route.icon} </Icon>
        {route.name}
      </Item>
    ))}
  </Container>
)

export default withRouter(Sidebar)
