import { styled } from 'mz-ui-kit'
import { BIG_SEPARATOR } from 'app/constants/sizes'

export const Tabs = styled('div', ({ $palette }) => ({
  display: 'flex',
  height: '46px',
  padding: `0 ${BIG_SEPARATOR}`,
  borderBottom: `1px solid ${$palette('GRAY')}`,
}))

export const Tab = styled('div', ({ $active, $palette }) => ({
  display: 'flex',
  cursor: 'pointer',
  padding: '10px 60px',
  marginRight: '40px',
  color: $active ? `${$palette('PRIMARY')}` : `${$palette('DARKGRAY')}`,
  borderBottom: $active ? `4px solid ${$palette('PRIMARY')}` : 'none',
}))

export const TabContent = styled('div', ({ $active }) => ({
  display: $active ? 'inherit' : 'none',
  padding: `${BIG_SEPARATOR} 0`,
}))

export const Status = styled('div', ({ $background, $loading, $palette }) => ({
  width: 'max-content',
  padding: '6px',
  textAlign: 'center',
  borderRadius: '3px',
  textTransform: 'capitalize',
  color: `${$palette($loading ? 'BLACK' : 'WHITE')}`,
  background: `${$palette($loading ? 'WHITE' : $background)}`,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const StatusLoader = styled('div', ({ $background, $palette }) => ({
  width: 'max-content',
  height: '25.8px',
  textAlign: 'center',
  background: `${$palette($background)}`,
  alignItems: 'center',
}))

export const StatusDropdown = styled('div', () => ({
  position: 'absolute',
  top: '30px',
  left: '0px',
  width: '200px',
  backgroundColor: '#fff',
  borderRadius: '4px',
  boxShadow: '2px 2px 8px 0px rgba(34, 34, 34, .2)',
  padding: '10px',
  zIndex: 100,
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
}))

export const StatusDropdownItem = styled('div', ({ $active }) => ({
  fontSize: '16px',
  color: $active ? '#222' : '#666',
  fontWeight: $active ? 500 : 400,
  textAlign: 'left',
  transition: 'color .2s',
  textTransform: 'capitalize',

  ':hover': {
    color: '#222',
  },
}))

export const Alert = styled('section', ({ $palette }) => ({
  padding: '15px 20px',
  marginBottom: '20px',
  fontSize: '12px',
  color: `${$palette('RED')}`,
  background: `${$palette('RED').lighten(40)}`,
}))

export const Bolder = styled('span', {
  margin: '0 5px',
  fontWeight: 'bold',
})

export const LoadingWrapper = styled('div', {
  minWidth: '18px',
  paddingLeft: '10px',
})

export const ConfirmationNumber = styled('div', ({ $alert, $palette }) => ({
  color: `${$alert ? $palette('WHITE') : $palette('GREY')}`,
  fontSize: '11px',
  lineHeight: '19px',
}))

export const Link = styled('a', ({ $link }) => ({
  textDecoration: 'none',
  color: $link ? 'blue' : '#000000',
  marginBottom: '8px',
  display: 'block',
}))
