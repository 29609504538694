import { createAction } from 'app/utils/redux'
import Client from 'mz-sdk/client'
import { errorObjectToString } from 'app/utils/normalize'
import { DRIVERS_ENDPOINT } from 'app/constants/endpoints'
import {
  ADD_DRIVERS,
  ADD_DRIVER,
  REMOVE_DRIVER,
  SET_DRIVERS_ERROR,
  SET_UPDATED_DRIVER,
  ADD_BULK_DRIVERS,
  ADD_TEMP_DRIVERS,
  REMOVE_TEMP_DRIVERS,
  SET_UPLOAD_ERROR,
} from 'app/constants/actions'

// populate initial drivers
export const addDrivers = createAction(ADD_DRIVERS)

// append newly created driver
export const addDriver = createAction(ADD_DRIVER)

export const removeDriver = createAction(REMOVE_DRIVER)

export const setDriversError = createAction(SET_DRIVERS_ERROR)

export const setUpdatedDriver = createAction(SET_UPDATED_DRIVER)

// append newly uploaded drivers from CSV to Redux
export const addBulkDrivers = createAction(ADD_BULK_DRIVERS)

// CSV entries in REDUX until upload
export const addTempDrivers = createAction(ADD_TEMP_DRIVERS)

export const removeTempDrivers = createAction(REMOVE_TEMP_DRIVERS)

export const setUploadError = createAction(SET_UPLOAD_ERROR)

export const getDrivers = () => {
  return async (dispatch) => {
    try {
      const response = await Client.get(DRIVERS_ENDPOINT)
      return response.results && response.results.length > 0
        ? dispatch(addDrivers(response))
        : dispatch(setDriversError('NO RESULTS'))
    } catch (error) {
      return dispatch(setDriversError(error.message))
    }
  }
}

const uploadDriverImage = async (driver, image) => {
  if (!driver.id || (!image && !driver.image) || typeof image === 'string') {
    return null
  }
  const body = new FormData()
  body.append('image', image)
  const path = `${DRIVERS_ENDPOINT}${driver.id}/upload_image/`
  return image
    ? Client.post(path, { body })
    : Client.delete(`${DRIVERS_ENDPOINT}${driver.id}/image/`).then(() => {
        return {
          ...driver,
          image: null,
        }
      })
}

export const createDriver = ({
  email,
  vehicle = {},
  image,
  first_name,
  last_name,
  phone_number,
}) => {
  return async (dispatch) => {
    try {
      const body = {
        email,
        first_name,
        last_name,
        phone_number: phone_number.number,
        country_code: phone_number.country.callingCode,
        country_code_name: phone_number.country.code,
        preferred_vehicle_id: vehicle.id,
      }
      const driver = await Client.post(DRIVERS_ENDPOINT, { body })
      const driverWithImage = await uploadDriverImage(driver, image)
      const result = driverWithImage || driver
      dispatch(addDriver({ ...result }))
      dispatch(setDriversError(null))
      return result
    } catch (error) {
      dispatch(setDriversError(errorObjectToString(error)))
    }
    return null
  }
}

export const updateDriver = ({ id, phone_number, vehicle, image, ...data }) => {
  return async (dispatch) => {
    try {
      const body = {
        ...data,
        phone_number: phone_number.number,
        country_code: phone_number.country.callingCode,
        country_code_name: phone_number.country.code,
        preferred_vehicle_id: vehicle ? vehicle.id : null,
      }
      const driver = await Client.patch(`${DRIVERS_ENDPOINT}${id}/`, { body })
      const driverWithImage = await uploadDriverImage(driver, image)
      const result = driverWithImage || driver
      dispatch(setUpdatedDriver({ ...result }))
      dispatch(setDriversError(null))
      return result
    } catch (error) {
      dispatch(setDriversError(errorObjectToString(error)))
      throw error
    }
  }
}

export const deleteDriver = (id) => {
  return async (dispatch) => {
    try {
      await Client.delete(`${DRIVERS_ENDPOINT}${id}/`)
      dispatch(removeDriver(id))
    } catch (error) {
      dispatch(setDriversError(error))
    }
  }
}

export const getMoreDrivers = (nextUrl) => {
  return async (dispatch, getState) => {
    try {
      const response = await Client.get(
        nextUrl,
        {},
        { externallyBuiltUrl: true }
      )
      const currentDrivers = getState().drivers.results || []
      return dispatch(
        addDrivers({
          ...response,
          results: [...currentDrivers, ...response.results],
        })
      )
    } catch (error) {
      return dispatch(setDriversError(error.message))
    }
  }
}

export const uploadDriversCSV = (blobFile) => {
  return async (dispatch) => {
    if (!blobFile) return null

    const body = new FormData()
    body.append('csv_file', blobFile)
    const path = `${DRIVERS_ENDPOINT}upload/?process=1`

    const drivers = await Client.post(path, { body })
    dispatch(setDriversError(null))

    if (drivers.new && drivers.new.length) {
      return dispatch(addBulkDrivers(drivers.new))
    }

    return null
  }
}
