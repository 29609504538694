import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import verify from 'mz-sdk/services/reservations/verifyFromDE'
import is from 'mz-utils/is'
import Button from 'mz-ui-kit/core/Button'
import Alert from 'mz-ui-kit/core/Alert'
import CheckIcon from 'mz-icons/check'
import { FormattedMessage } from 'mz-intl'
import { updateTrip } from 'app/actions/trips'
import messages from '../messages.intl'
import verificationMessages from 'app/components/verification/messages.intl'
import { Spacer, Verified } from '../elements'
import { isTripCancelled, isExpired } from 'app/utils/trips'

class VerificationSection extends Component {
  state = {
    loading: false,
    error: false,
    errorMessage: null,
    submitted: false,
  }

  verifyReservation = () => {
    const { trip, update } = this.props
    const { hashed_id: hashedId } = trip
    this.setState({ loading: true })
    verify(hashedId)
      .then(() => {
        update({ ...trip, verified: true })
        this.setState({ loading: false, submitted: true })
      })
      .catch((error) =>
        this.setState({
          loading: false,
          error: true,
          errorMessage: (is.string(error) && error) || error.message,
        })
      )
  }

  render() {
    const { trip } = this.props
    const { loading, error, errorMessage, submitted } = this.state
    const { verified: isVerified } = trip
    const isReservationCancelled = isTripCancelled(trip)
    const isPastTrip = isExpired(trip)

    return (
      <div>
        {error && (
          <Fragment>
            <Spacer />
            <Alert color="RED">
              <FormattedMessage
                message={
                  errorMessage || verificationMessages.DEFAULT_ERROR_MESSAGE
                }
              />
            </Alert>
          </Fragment>
        )}
        <Spacer />
        {(submitted || isVerified) && !isReservationCancelled && (
          <Verified>
            <FormattedMessage message={messages.VERIFIED} />
            <Spacer $horizontal />
            <CheckIcon />
          </Verified>
        )}
        {!submitted &&
          !isVerified &&
          !isReservationCancelled &&
          !isPastTrip && (
            <Button loading={loading} onClick={this.verifyReservation}>
              <FormattedMessage message={messages.VERIFY_RESERVATION} />
            </Button>
          )}
        {isReservationCancelled && (
          <Alert color="RED">
            <FormattedMessage message={messages.RESERVATION_CANCELLED} />
          </Alert>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  update: (trip) => dispatch(updateTrip(trip)),
})

export default connect(null, mapDispatchToProps)(VerificationSection)
