import React from 'react'
import ImageField from 'mz-ui-kit/form/ImageField/field'
import TextField from 'mz-ui-kit/form/TextField/field'
import Row from 'mz-ui-kit/core/Row'
import VehicleField from 'app/components/commons/VehicleField/field'
import DriverLinkResend from '../DriverLinkResend'
import { Content } from './elements'
import PhoneField from 'mz-ui-kit/form/PhoneField/field'
import EmailField from 'mz-ui-kit/form/EmailField/field'

export default function SingleCreate({ driver, nest }) {
  return (
    <Content>
      <ImageField {...nest('image')} />
      <Row>
        <TextField
          {...nest('first_name')}
          label="First Name"
          placeholder="First Name"
          required
        />

        <TextField
          {...nest('last_name')}
          label="Last Name"
          placeholder="Last Name"
          required
        />
      </Row>

      <PhoneField required {...nest('phone_number')} />
      <EmailField
        {...nest('email')}
        label="Email"
        placeholder="Email"
        disabled={driver}
      />

      <VehicleField
        {...nest('vehicle')}
        label="Primary Vehicle"
        required={driver}
      />

      {!!driver && <DriverLinkResend id={driver.id} />}
    </Content>
  )
}
